import React from "react";
import RadioBox from "./RadioBox";
import FormLabel from "./FormLabel";

const CheckList = ({
  dataStr,
  data,
  dependentOn,
  error,
  label,
  name,
  onChange,
}) => {
  if (dataStr && dataStr !== "") {
    const dataArr = dataStr.split(",");
    data = [];
    dataArr.forEach((dt) => {
      let tempArray = dt.split("|");
      let dataObj = {};
      dataObj.idField = tempArray[0];
      dataObj.valueField = tempArray[1];
      data.push(dataObj);
    });
  }

  const handleRadioChange = (evt, rData) => {
    rData.optionYesNo = evt.target.value;
    rData["answer_" + rData["checkListId"] + "_" + rData["checkListItemId"]] =
      evt.target.value;
    onChange(rData);
  };

  const containerStyles = {
    gridColumn: "span 12",
    display: dependentOn ? "block" : "none",
    border: error ? "1px solid red" : "none",
  };

  return (
    <div style={containerStyles}>
      <div className="flex gap-[0.75rem] mt-[1.25rem] w-fit items-center">
        {data &&
          data?.map((rData) => {
            return (
              <div className="grid grid-cols-2">
                <div span={10} className="font-medium text-gray-700">
                  {rData.question}
                </div>
                <div>
                  <RadioBox
                    dependentOn={true}
                    value={rData["optionYesNo"]}
                    name={
                      "answer_" +
                      rData["checkListId"] +
                      "_" +
                      rData["checkListItemId"]
                    }
                    onChange={(e) => handleRadioChange(e, rData)}
                    align="justify-center"
                    dataStr="1|Yes,2|No"
                  />
                </div>
              </div>
            );
          })}
      </div>
    </div>
    // <div style={containerStyles}>
    //   <Row className="checklist_group">
    //     {data &&
    //       data?.map((rData) => {
    //         return (
    //           <Row className="flex_w_100">
    //             <Col span={10} className="checklist_label">
    //               {rData.question}
    //             </Col>
    //             <Col>
    //               <RadioBox
    //                 dependentOn={true}
    //                 value={rData["optionYesNo"]}
    //                 name={
    //                   "answer_" +
    //                   rData["checkListId"] +
    //                   "_" +
    //                   rData["checkListItemId"]
    //                 }
    //                 onChange={(e) => handleRadioChange(e, rData)}
    //                 align="justify-center"
    //                 dataStr="1|Yes,2|No"
    //               />
    //             </Col>
    //           </Row>
    //         );
    //       })}
    //   </Row>
    // </div>
  );
};

export default CheckList;
