// export const BASEURL = "https://api-test.rtosoftware.com.au/api/";
// "http://localhost/reactapi/api/";
// = "https://api-" + window.configs.collegeName + ".rtosoftware.com.au/api/";
// export const BASEURL = "https://api-" + process.env.REACT_APP_COLLEGE_NAME + ".rtosoftware.com.au/api/";
export const BASEURL = window.configs.collegeName == "localhost" ? "https://localhost:5003/api/" : "https://api-" + window.configs.collegeName + "." + (window.configs.domainName || "rtosoftware.com.au") + "/api/";
export const COLLEGEID = 301;

// Program Types
export const CURRENTPROGRAM = 1;
export const PASTPROGRAM = 2;

// export const defaultWebFormCampus =
//   window.configs.webformconfigs.defaultWebFormCampus || 0;
// export const defaultRollingIntake =
//   window.configs.webformconfigs.defaultRollingIntake || 0;
// export const makeCurrentDateStartDate =
// window.configs.webformconfigs.makeCurrentDateStartDate;
// export const hideCourseSelectionDatesAndWeeks =
//   window.configs.webformconfigs.hideCourseSelectionDatesAndWeeks;
// export const showCourseasdropdown =
//   window.configs.webformconfigs.showCourseasdropdown;
// export const courseLabelToShow = window.configs.webformconfigs.courseLabelToShow;
// export const showStateAbbr = window.configs.webformconfigs.showStateAbbr;
// export const citizenshipStatusSelectionCount =
//   window.configs.webformconfigs.citizenshipStatusSelectionCount;
// export const showOldUSISection = window.configs.webformconfigs.showOldUSISection;
// export const showTrainingLocations =
//   window.configs.webformconfigs.showTrainingLocations;
// export const submitAssessments = window?.configs?.assessmentsConfig;
