import React, { useEffect, useState } from "react";
import Tabs from "./common/Tabs";
import { PROGRAMICON, PROGRAMOUTLINEICON } from "../IconConstants";
import queryString from "query-string";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { SubjectDetailRoutes } from "../routes";

import Axios from "axios";
import { BASEURL } from "../constants";
import Spinner from "./common/Spinner";

const Menu = (props) => {
  const location = useLocation();
  return <Tabs {...props} location={location} />;
};

const SubjectDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { programId } = queryString.parse(location?.search);
  const [tabSelect, setTabSelect] = useState(`summary?programId=${programId}`);
  const [tabsCollection, setTabsCollection] = useState([]);
  const { id } = useParams();
  useEffect(() => {
    const { uos, subjects } = queryString.parse(location.search);
    var updatedTabsCollection = [
      {
        title: "Summary",
        icon: PROGRAMICON,
        linkTarget: "summary?programId=" + programId,
        selectedOption: true,
        matchedText: "summary",
        isShown: true,
      },
      {
        title: "Subject Outline",
        icon: PROGRAMOUTLINEICON,
        linkTarget: "outline?programId=" + programId + "&objectType=22",
        selectedOption: false,
        matchedText: "outline",
        isShown: true,
      },
    ];

    if (uos > 0) {
      updatedTabsCollection[2] = {
        title: "Units Of Study",
        linkTarget: "uos",
      };
    }

    if (uos == 0 && subjects == 0) {
      updatedTabsCollection = updatedTabsCollection.filter(
        (tabs) => tabs.title !== "Subjects"
      );
    }
    setLoading(true);
    effect();
    async function effect() {
      try {
        if (!uos > 0) {
          const respSummary = await Axios.get(
            BASEURL +
              "Student/SubjectUOSSummary/" +
              id +
              "?objectType=subject&programId=" +
              programId
          );

          if (!respSummary?.data?.hasOwnProperty("enrolmentId")) {
            updatedTabsCollection[0]["isShown"] = false;
          }
        } else if (uos > 0) {
          const respSummary = await Axios.get(
            BASEURL +
              "Student/SubjectUOSSummary/" +
              id +
              "?objectType=uos&programId=" +
              programId
          );

          if (!respSummary?.data) {
            updatedTabsCollection[0]["isShown"] = false;
          }
        }
      } catch (error) {
        console.log("Error in Summary:", error);
        updatedTabsCollection[0]["isShown"] = false;
      }
      try {
        const respCourse = await Axios.get(
          BASEURL + "LmsPage/0?objectId=" + id + "&objectType=" + 22
        );

        if (!respCourse?.data?.hasOwnProperty("lmspageId")) {
          updatedTabsCollection[1]["isShown"] = false;
        }
      } catch (error) {
        console.log("Error in outline:", error);
        updatedTabsCollection[1]["isShown"] = false;
      }
      const arrayTabUpdate = [];
      updatedTabsCollection.forEach((i) =>
        location.pathname.includes(i.matchedText)
          ? arrayTabUpdate.push({ ...i, selectedOption: true })
          : arrayTabUpdate.push({ ...i, selectedOption: false })
      );
      setTabsCollection(arrayTabUpdate);
      setLoading(false);
    }
  }, [location.search]);
  const handleOptionChoose = (e) => {
    const updatedTabsCollection = [];
    tabsCollection.forEach((i) =>
      i.linkTarget === e
        ? updatedTabsCollection.push({ ...i, selectedOption: true })
        : updatedTabsCollection.push({ ...i, selectedOption: false })
    );
    setTabsCollection(updatedTabsCollection);
    navigate(e);
  };
  return (
    <React.Fragment>
      {!loading && tabsCollection?.length > 0 && (
        <div className="sm:px-4">
          <Menu
            tabsCollection={tabsCollection}
            pathname={tabSelect}
            handleOptionChoose={handleOptionChoose}
          />
          <main style={{ backgroundColor: "#edf2f7cc" }}>
            <SubjectDetailRoutes />
          </main>
        </div>
      )}
      {loading && <Spinner status={loading} />}
    </React.Fragment>
  );
};

export default SubjectDetail;
