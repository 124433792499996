import React from "react";

export default function Alert(props) {
  const { alertStatus, alertMessage, alertIcon, closeFunction, iconColor } =
    props;
  if (alertStatus) {
    setTimeout(() => {
      closeFunction();
    }, 5000);
  }
  return (
    <div
      style={{
        zIndex: 50,
        position: "fixed",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "center",
        padding: "4px",
        paddingBottom: "6px",
        pointerEvents: "none",
        "@media (min-width: 640px)": {
          padding: "6px",
          paddingTop: "4px",
          alignItems: "flex-start",
          justifyContent: "flex-end",
        },
        transform: `translateY(${alertStatus ? "20px" : "0"})`,
        display: alertStatus ? "block" : "none",
        "@media (min-width: 640px)": {
          transform: "translateY(0)",
          display: alertStatus ? "block" : "none",
        },
      }}
    >
      {/* <!--
    Notification panel, show/hide based on alert state.

    Entering: "transform ease-out duration-300 transition"
      From: "translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      To: "translate-y-0 opacity-100 sm:translate-x-0"
    Leaving: "transition ease-in duration-100"
      From: "opacity-100"
      To: "opacity-0"
  --> */}
      <div
        style={{
          maxWidth: "24rem",
          width: "100%",
          backgroundColor: "white",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          borderRadius: "0.375rem",
          pointerEvents: "auto",
        }}
      >
        <div
          style={{
            borderRadius: "0.375rem",
            boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
            overflow: "hidden",
          }}
        >
          <div style={{ padding: "0.75rem" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ flexShrink: 0 }}>
                {/* Heroicon name: check-circle */}
                <svg
                  style={{
                    width: "1.5rem",
                    color: `var(--text-${iconColor}-400)`,
                  }}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                >
                  <path d={alertIcon} />
                </svg>

                {/* <svg class="h-6 w-6 text-red-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg> */}
                {/* <svg class="h-6 w-6 text-red-400" viewBox="0 0 20 20" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" />
                                    </svg> */}
              </div>
              <div
                style={{
                  marginLeft: "0.75rem",
                  flex: 1,
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    fontSize: "0.875rem",
                    lineHeight: "1.25rem",
                    fontWeight: 500,
                    color: "rgba(0, 0, 0, 0.9)",
                  }}
                >
                  {alertMessage}
                </p>
              </div>
              <div
                style={{ marginLeft: "1rem", flexShrink: 0, display: "flex" }}
              >
                <button
                  type="primary"
                  onClick={() => closeFunction()}
                  style={{
                    display: "inline-flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    color: "#fff",
                    outline: "none",
                    focus: "text-gray-500",
                    borderRadius: "50%",
                    width: 30,
                    height: 30,
                    padding: 0,
                    transition: "color 150ms ease-in-out",
                  }}
                >
                  {/* Heroicon name: x */}
                  <svg viewBox="0 0 20 20" fill="currentColor">
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
