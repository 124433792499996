import React, { useMemo } from "react";
import Checkbox from "../controls/CheckBox"
import FormLabel from "./FormLabel";

const CheckBoxGroup = ({
  dataStr,
  dependentOn,
  disabled,
  error,
  label,
  name,
  onChange,
}) => {
  const data = useMemo(() => {
    if (dataStr && dataStr !== "") {
      return dataStr.split(",").map((dt) => {
        const [idField, valueField] = dt.split("|");
        return { idField, valueField };
      });
    }
    return [];
  }, [dataStr]);

  return (
    <div className={`col-span-3 ${dependentOn ? "" : "hidden"}`}>
      <FormLabel
        key={label + 1}
        text={label}
        color={error ? "red" : "black"}
        error={error}
      >
        <div className="mt-[1.25rem] grid grid-cols-2">
          {data.map((rData, index) => (
            <div key={index} className="col-span-2">
              <Checkbox
                id={name}
                name={name}
                disabled={disabled ? true : false}
                value={rData.idField}
                onChange={onChange}
                checked={rData.checked}
              >
                <span
                  style={{
                    display: "block",
                    fontSize: "0.875rem",
                    fontWeight: "500",
                    color: "#4b5563",
                  }}
                >
                  {rData.valueField}
                </span>
              </Checkbox>
            </div>
          ))}
        </div>
      </FormLabel>
    </div>

    // <Col span={24} sm={12} style={{ display: dependentOn ? "block" : "none" }}>
    //   <FormLabel
    //     key={label + 1}
    //     text={label}
    //     color={error ? "red" : "black"}
    //     error={error}
    //   >
    //     <Row gutter={16} style={{ marginTop: "1.25rem" }}>
    //       {data.map((rData, index) => (
    //         <Col key={index} span={12}>
    //           <Checkbox
    //             id={name}
    //             name={name}
    //             disabled={disabled ? true : false}
    //             value={rData.idField}
    //             onChange={onChange}
    //             checked={rData.checked}
    //           >
    //             <span
    //               style={{
    //                 display: "block",
    //                 fontSize: "0.875rem",
    //                 fontWeight: "500",
    //                 color: "#4b5563",
    //               }}
    //             >
    //               {rData.valueField}
    //             </span>
    //           </Checkbox>
    //         </Col>
    //       ))}
    //     </Row>
    //   </FormLabel>
    // </Col>
  );
};

export default React.memo(CheckBoxGroup);
