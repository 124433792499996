import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import clientlogo from "../assets/images/clientlogo.png";
import { PostData } from "../services/PostData";
import * as constants from "../constants";
import { Cookies } from "react-cookie";
import ErrorSound from "../assets/sounds/error.mp3";
import { toast } from "react-toastify";
import { FaEyeSlash } from "react-icons/fa";
import { FaEye } from "react-icons/fa";

import { AiOutlineLoading3Quarters } from "react-icons/ai"; 

const cookies = new Cookies();

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passType, setPassType] = useState("password");
  const [errorAudio] = useState(new Audio(ErrorSound));
  const [loading, setLoading] = useState(false);
  const [showpass,setshowpass]=useState(false);

  const navigate = useNavigate();

  const playSound = (audioFile) => {
    audioFile.play();
  };

  const togglepassw=()=>{
    setshowpass(prevState=>!prevState)
  }
  const login = (e) => {
    e.preventDefault();
    if (username && password) {
      setLoading(true);

      PostData("Login", {
        username,
        password,
        CollegeID: constants.COLLEGEID,
      })
        .then((result) => {
          let responseJSON = result;
          if (responseJSON.token) {
            localStorage.setItem("studentId", responseJSON.user.studentId);
            localStorage.setItem("studentName", responseJSON.user.firstName);
            localStorage.setItem("avatarurl", responseJSON.user.imageUrl);
            cookies.set("token", responseJSON.token);
            navigate("/dashboard");
           
          } else {
            playSound(errorAudio);
           
          }
        })
        .catch((error) => {
          console.log(error, "catch error");
          toast.error("Invalid Credentials",{toastId:"loginerror"})
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    if (name === "username") {
      setUsername(value);
    } else if (name === "password") {
      setPassword(value);
    }
  };


  return (
    <>
        <div className="min-h-screen bg-white flex">
          <div className="flex-1 flex flex-col justify-center py-12 px-6 sm:px-6 lg:block lg:px-20 xl:px-24" >
            <div className="mx-auto w-full max-w-sm">
              <div>
                {window.configs.showClientLogoLoginPage && <img className="h-18 w-auto" src={clientlogo} alt="Logo" />}
              </div>
              <div>
                <h2 className="mt-6 text-3xl leading-9 font-extrabold">
                  Student Login
                </h2>
              </div>
              <div className="mt-8" >
                <form >
                  <div>
                    <label
                      htmlFor="username"
                      className="block font-semibold text-[#374151] text-[0.875rem]"
                     
                    >
                      User name
                    </label>
                    <div className="mt-[0.25rem] relaive rounded-md shadow-[0 0 0.125rem 0 rgba(0, 0, 0, 0.1)]">
                      <input
                        name="username"
                        id="username"
                        value={username}
                        onChange={onChange}
                        className="
                        w-full font-normal px-3 py-1.5 rounded-lg focus:outline-none text-black border focus:border-blue-800"
                        placeholder="you@example.com"
                      />
                    </div>
                  </div>

                  <div className="mt-[1rem]">
                    <label
                      htmlFor="password"
                      className="block font-semibold text-[#374151] text-[0.875rem]"
                    >
                      Password
                    </label>
                    <div className=" flex mt-[0.25rem] relative rounded-md shadow-[0 0 0.125rem 0 rgba(0, 0, 0, 0.1)]">
                      <input
                        name="password"
                        id="password"
                        value={password}
                        onChange={onChange}
                        type={!showpass?passType:"text"}
                        required
                        className="
                        w-full font-normal px-3 py-1.5 rounded-lg focus:outline-none text-black  border focus:border-blue-800 "
                        placeholder="Password"
                        
                        />
                        <button onClick={togglepassw} className="absolute right-2 top-3" type="button">
                          {
                            showpass?<FaEye/>:<FaEyeSlash></FaEyeSlash>
                          }
                        </button>
                    </div>
                  </div>

                  <div style={{ marginTop: "1.5rem" }}>
                    <span className="block w-full rounded-md shadow-[0 0 0.125rem 0 rgba(0, 0, 0, 0.1)]"
                    >
                      <button type="submit" onClick={login} loading={loading} className="w-full flex gap-x-2 justify-center items-center  text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                        {loading && <AiOutlineLoading3Quarters className="w-4 h-4 fill-white animate-spin"/>}
                        Sign In
                      </button>
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="hidden lg:block relative w-0 flex-1">
              <img className="absolute inset-0 h-full w-full object-cover"
                src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
                alt="Login"
              />
            
          </div>
        </div>
     
    </>
  );
};

export default Login;