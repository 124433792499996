import React from "react";

function Icon(props) {
  const { viewBox = "0 0 20 20", className } = props;
  return (
    <svg
      className={`h-[1.25rem] w-[1.25rem] text-gray-400 ${className}`}
      fill="currentColor"
      viewBox={viewBox}
    >
      <path fillRule="evenodd" d={props.src} clipRule="evenodd" />
    </svg>
  );
}

export default Icon;
