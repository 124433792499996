import React from "react";
import FormLabel from "./FormLabel";

export default function CheckBox({
  hintText,
  name,
  label,
  onChange,
  error,
  dependentOn,
  align = {},
}) {
  const handleChange = (event) => {
    onChange(event);
  };

  return (
    // Need to add grid column wise classes
    <div style={{ display: !dependentOn ? "none" : "inherit", ...align }}>
      <div style={{ marginTop: "2rem", width: "100%" }}>
      <FormLabel
        color={error ? "red" : "black"}
        style={{ display: "flex", alignItems: "center", ...align }}
      >
        <label className="flex items-center gap-2 cursor-pointer font-semibold text-[0.85rem] text-black">
          <input type="checkbox" className="w-3 h-3 mt-0.5 text-blue-600 bg-gray-100 border-gray-300 rounded" onChange={handleChange} name={name} id={name} value={label} />
          {label}
        </label>
      </FormLabel>
        <p className="text-gray-500">{hintText}</p>
      </div>
    </div>
    // <Col
    //   xs={24}
    //   sm={24}
    //   md={8}
    //   lg={8}
    //   xl={8}
    //   style={{ display: !dependentOn ? "none" : "inherit", ...align }}
    // >
    //   <div style={{ marginTop: "2rem", width: "100%" }}>
    //     <FormLabel
    //       color={error ? "red" : "black"}
    //       style={{ display: "flex", alignItems: "center", ...align }}
    //     >
    //       <Checkbox
    //         onChange={handleChange}
    //         name={name}
    //         id={name}
    //         // checked={value}
    //         value={label}
    //         style={{
    //           transitionProperty: "all",
    //           transitionDuration: "150ms",
    //           transitionTimingFunction: "ease-in-out",
    //         }}
    //       >
    //         <div style={{ display: "flex" }}>
    //           <span style={{ fontSize: ".85rem", fontWeight: 500 }}>
    //             {label}
    //           </span>
    //           <p className="text-gray-500">{hintText}</p>
    //         </div>
    //       </Checkbox>
    //     </FormLabel>
    //   </div>
    // </Col>
  );
}
