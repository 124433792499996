import React, { useRef } from "react";
import FormLabel from "./FormLabel";
import { AiOutlineCloudUpload } from "react-icons/ai";

const UploadBox = ({
  label,
  name,
  value,
  onChange,
  onClick,
  multi,
  size = "default",
}) => {
  const fileInputRef = useRef(null);

  const handleButtonClick = (e) => {
    // clearing the previously select file
    fileInputRef.current.value = null;

    e.preventDefault();
    fileInputRef.current.click();
  };

  return (
    <form>
      <div className="col-span-6 sm:col-span-2">
        <FormLabel text={label} name={name}>
          <input
            type="file"
            onChange={(e)=>onChange(e)}
            multiple="multiple"
            ref={fileInputRef}
            style={{ display: "none" }}
          />
          <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
            <button 
              type="button"
              className="w-full flex items-center justify-center gap-1.5 px-4 py-1.5 font-medium bg-white border border-blue-600 text-blue-600 cursor-pointer shadow rounded-md"
              onClick={handleButtonClick}
            >
              <AiOutlineCloudUpload className="w-5 h-5 text-blue-600" />
              {name}
            </button>
            {/* <Button
              size={size}
              type="primary"
              className="my-button"
              onClick={handleButtonClick}
            >
              {name}
            </Button> */}
          </div>
        </FormLabel>
      </div>
    </form>
  );
};

export default UploadBox;
