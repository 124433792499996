import React, { useCallback, useEffect, useState } from "react";
import WideList from "./common/WideList";
import * as constants from "../constants";
import { ERRORICON, UPLOADICON } from "../IconConstants";
import parser from "html-react-parser";
import Axios from "axios";
import Modal from "./common/Modal";
import TextBox from "./controls/TextBox";
import Alert from "./common/Alert";
import UploadBox from "./controls/UploadBox";
import { RiDeleteBin6Fill } from "react-icons/ri";

const Assessments = (props) => {
  const [state, setState] = useState({
    modalState: false,
    alertState: false,
    alertMessage: "",
    alertIcon: "",
    iconColor: "",
    fields: undefined,
    listItems: [],
  });

  const [tempFiles, setTempFiles] = useState([]);
  // console.log(tempFiles);
  const [loading, setLoading] = useState(false);
  const { objectId, objectType, programId, cardHeaderSettings } = props;
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    fetchData();
  }, [programId, refresh]);

  const closeModal = () => {
    setState((prevState) => ({
      ...prevState,
      modalState: false,
      fields: undefined,
      formServiceResponse: "",
    }));

    setTempFiles([]);
  };

  const submitAssessment = (e) => {
    submitEffect();
    async function submitEffect() {
      if (!Array.isArray(tempFiles) || tempFiles?.length < 1) {
        setState((pre) => ({
          ...pre,
          formServiceResponse: "First select a file!",
        }));
        return;
      }

      let userConfirmation = window.confirm("Please review your assessments carefully before submission. Once uploaded, there is no option to delete or resubmit any documents found to be incorrect.");

      if(!userConfirmation){
        return
      }

      setLoading(true);

      const form = new FormData();
      tempFiles?.map((element) => {
        form.append("file", element);
      });

      for (const key in state?.fields) {
        if (state?.fields.hasOwnProperty(key)) {
          const element = state?.fields[key];
          form.append(key, element);
        }
      }

      try {
        const result = await Axios.post(
          constants.BASEURL + "EnrolmentAssessment/upload",
          form
        );

        let message = "Success!";
        if (!result.data.success) {
          message = result.data.message;
        }
        setState((prevState) => ({
          ...prevState,
          alertState: true,
          alertMessage: "Assignment submitted successfully.",
          alertIcon: "SUCCESSICON",
          iconColor: "green",
          formServiceResponse: message,
          fields: undefined,
        }));
      } catch (error) {
        setState((prevState) => ({
          ...prevState,
          alertState: true,
          alertMessage:
            "An error occured while submitting assignment. Please try after some time.",
          alertIcon: ERRORICON,
          iconColor: "red",
          fields: undefined,
        }));
        console.error(error);
      } finally {
        setTempFiles([]);
        setLoading(false);
      }
      closeModal();
    }
  };

  useEffect(() => {
    if (state.alertState && state.iconColor === "green") {
      setRefresh(!refresh);
    }
  }, [state.alertState]);
  const fetchData = async () => {
    try {
      const response = await Axios.get(
        constants.BASEURL + "EnrolmentAssessment/" + programId,
        {
          params: {
            objectId: objectId,
            objectType: objectType,
          },
        }
      );
      let listData = [...response.data];
      transposeAssessmentData(listData);
    } catch (error) {
      console.error(error);
    }
  };

  const filesOnChange = (sender) => {
    setState((prevState) => ({
      ...prevState,
      formServiceResponse: null,
    }));

    let files = sender?.target?.files;

    let isFileExist = false;

    for (let el of Object.keys(files)) {
      let checkExit = tempFiles.find((item) => item.name === files[el].name);
      if (checkExit) {
        isFileExist = true;
      }
    }

    if (isFileExist) {
      setState((prevState) => ({
        ...prevState,
        formServiceResponse: "Cannot upload duplicate files!",
      }));
    } else {
      let FileArray = [];

      for (let el of Object.keys(files)) {
        FileArray.push(files[el]);
      }

      setTempFiles((pre) => [...pre, ...FileArray]);
    }
  };

  const fieldOnChange = (sender) => {
    let fieldName = sender.target.name;
    let value = sender.target.value;
    setState((prevState) => ({
      ...prevState,
      fields: { ...prevState.fields, [fieldName]: value },
    }));
  };

  const removeFile = (file) => {
    let newFileList = tempFiles.filter((item) => item.name !== file.name);
    setTempFiles(newFileList);
  };

  const renderUploader = () => {
    const { fields } = state;
    return (
      <div>
        {fields && (
          <div>
            <p style={{ color: "#ef4444" }}>{state.formServiceResponse}</p>
            <div className="mb-10">
              <input
                type="hidden"
                name="programId"
                value={fields["programId"]}
              />
              <input
                type="hidden"
                name="submissionId"
                value={fields?.submissionId}
              />
              <input
                type="hidden"
                name="assessmentId"
                value={fields["assessmentId"]}
              />
              <input
                type="hidden"
                name="subjectId"
                value={fields["subjectId"]}
              />
              <TextBox
                name="SubmissionNotes"
                dependentOn={true}
                label="Submission Notes"
                value={fields["SubmissionNotes"]}
                onChange={fieldOnChange}
                fullwidth={true}
              />
            </div>

            <div>
              {tempFiles && tempFiles.length > 0 ? (
                <div className="max-h-[250px] overflow-hidden overflow-y-auto mb-4">
                  {tempFiles.map((file, idx) => (
                    // console.log(file)
                    <div key={idx} className="flex justify-between mb-1">
                      <p className="w-[85%] text-sm">{file.name}</p>
                      <RiDeleteBin6Fill className="cursor-pointer w-5 h-5 text-red-500" onClick={() => removeFile(file)} />
                    </div>
                  ))}
                </div>
              ) : (
                ""
              )}
            </div>

            <div>
              <UploadBox
                type="file"
                id="case-one"
                multi={true}
                size={"large"}
                name={"Upload Documents"}
                onChange={filesOnChange}
              />
            </div>
          </div>
        )}
      </div>
    );
  };

  const transposeSubComponentData = (listData) => {
    let returnList = [];

    listData.map((item) => {
      let listItem = {};
      listItem.key = item?.submissionId;
      listItem.id = "submitted";
      let headerData = [];

      let headerItem = {};
      headerItem.label =
        parseInt(item?.createUser) > 0 ? "Reviewed on" : "Submitted on";
      headerItem.text = item?.submissionDate;
      headerData.push(headerItem);

      let headerItem1 = {};
      headerItem1.label =
        parseInt(item?.createUser) > 0 ? "Review Notes" : "My Notes";
      headerItem1.text =
        parseInt(item?.createUser) > 0
          ? item?.assessmentNotes
          : item?.submissionNotes;
      headerData.push(headerItem1);

      listItem.headerData = headerData;

      listItem.listLabel =
        parseInt(item?.createUser) > 0
          ? "Review Documents"
          : "Submitted Documents";

      let docArr = [];
      item?.submissionDocuments?.map((docs) => {
        let docObj = {};
        docObj.itemId = docs.documentID;
        docObj.itemIcon = "";
        docObj.itemName = docs.docName;
        docObj.linkTarget = docs.downloadLink;
        docArr.push(docObj);
      });

      listItem.listData = docArr;
      returnList.push(listItem);
    });

    return returnList;
  };

  const findShowDetails = (status, iconDocs) => {
    let statusList = iconDocs.every((icon) => icon.isclick === status);
    if (statusList === false && !status) {
      return true;
    } else if (statusList === false && status) {
      return true;
    } else if (statusList === true && status) {
      return true;
    } else if (statusList === true && !status) {
      return true;
    }
  };
  const handleClick = (id, docId) => {
    console.log("listItem",state.listItems);
    let tempListItems = [...state.listItems];    
    let selectedItem = tempListItems.find((item) => item.controlId == id);
    if (selectedItem) {

      if(selectedItem.showDetail){
        let temp = selectedItem.iconDocs.filter((item) => item.isclick == true);
        if(temp.length && temp[0].id !== docId){
          temp[0].isclick = false
          selectedItem.showDetail = false
        }
      }
      
      selectedItem.showDetail = !selectedItem.showDetail
      let tempIconDoc = selectedItem.iconDocs.find((doc) => doc.id == docId);
      tempIconDoc.isclick = !tempIconDoc.isclick;

      if (!selectedItem?.subComponentData) {
          try {
            Axios.get(
              constants.BASEURL +
                "EnrolmentAssessment/Submissions/" +
                id +
                "?programId=" +
                programId
            ).then((res) => {                
              const subComponentData = [...res?.data];
              selectedItem["subComponentData"] = [
                selectedItem.assessmentDocuments,
                ...transposeSubComponentData(subComponentData),
              ];              
              setState((prevState) => ({ ...prevState, listItems: tempListItems }));
            });
          } catch {
            console.log("error");
          }
        } 
        else{
          setState((prevState) => ({ ...prevState, listItems: tempListItems }));
        }

    }

    // tempListItems && tempListItems.map((item) => {
    //   if (item.controlId === id) {
    //     let updatedItem = {
    //       ...item,

    //       iconDocs: item.iconDocs.map((doc) => {
    //         if (doc.id === docId) {
    //           doc["isclick"] = !doc?.isclick;
    //         }
    //         return doc;
    //       }),
    //     };
    //     updatedItem = {
    //       ...updatedItem,
    //       showDetail: findShowDetails(
    //         updatedItem?.showDetail,
    //         updatedItem.iconDocs
    //       ),
    //     };
      

    //     if (!item?.subComponentData) {
    //       try {
    //         Axios.get(
    //           constants.BASEURL +
    //             "EnrolmentAssessment/Submissions/" +
    //             id +
    //             "?programId=" +
    //             programId
    //         ).then((res) => {                
    //           const subComponentData = [...res?.data];
    //           updatedItem["subComponentData"] = [
    //             item.assessmentDocuments,
    //             ...transposeSubComponentData(subComponentData),
    //           ];


    //         });
    //       } catch {
    //         console.log("error");
    //       }
    //     }      
    //   }

    // })

    // old code
    // setState((prevState) => {
    //   const updatedListItems = prevState?.listItems?.map((item) => {
    //     if (item.controlId === id) {
    //       let updatedItem = {
    //         ...item,

    //         iconDocs: item.iconDocs.map((doc) => {
    //           if (doc.id === docId) {
    //             doc["isclick"] = !doc?.isclick;
    //           }
    //           return doc;
    //         }),
    //       };
    //       updatedItem = {
    //         ...updatedItem,
    //         showDetail: findShowDetails(
    //           updatedItem?.showDetail,
    //           updatedItem.iconDocs
    //         ),
    //       };

    //       // Add asynchronous code here
    //       if (!item?.subComponentData) {
    //         try {
    //           Axios.get(
    //             constants.BASEURL +
    //               "EnrolmentAssessment/Submissions/" +
    //               id +
    //               "?programId=" +
    //               programId
    //           ).then((res) => {                
    //             const subComponentData = [...res?.data];
    //             updatedItem["subComponentData"] = [
    //               item.assessmentDocuments,
    //               ...transposeSubComponentData(subComponentData),
    //             ];
    //           });
    //         } catch {
    //           console.log("error");
    //         }
    //       }
    //       return updatedItem;
    //     }
    //     return item;
    //   });
    //   return { ...prevState, listItems: updatedListItems };
    // });
  };

  const uploadDocument = (item) => {
    setState((prevState) => ({
      ...prevState,
      fields: {
        assessmentId: item?.assessmentID,
        submissionId: item?.lastSubmissionID,
        programId: programId,
        subjectId: item.objectID,
      },
      modalState: true,
      // buttonList: [
      //   {
      //     seq: 2,
      //     buttonClass: "my-button-indigo",
      //     clickFunction: ()=>submitAssessment(),
      //     buttonText: "Submit",
      //   },
      //   {
      //     seq: 1,
      //     buttonClass: "my-button-gray",
      //     clickFunction: closeModal,
      //     buttonText: "Cancel",
      //   },
      // ],
    }));
  };
  const answerQuiz = (item) => {
    window.open(window.location.origin + "/AssessmentQuiz/1", "_blank");
  };

  const closeAlert = () => {
    setState((prevState) => ({
      ...prevState,
      alertState: false,
    }));
  };

  const transposeAssessmentDocuments = (docData) => {
    if (docData.length === 0) {
      return null;
    }

    const listItem = {
      key: docData[0].documentID,
      id: "assessment",
      listLabel: "Assessment Documents",
      listData: docData.map((docs) => ({
        itemId: docs.documentID,
        itemIcon: "",
        itemName: docs.docName,
        linkTarget: docs.downloadLink,
      })),
    };

    return listItem;
  };

  const getAssessmentStatusColor = (status) => {
    switch (parseInt(status)) {
      case 1:
      case 2:
        return "#FB923C";
      case 3:
      case 5:
        return "#047857";
      case 4:
        return "#DC2626";
      case 6:
        return "#7F1D1D";
      default:
        return "inherit";
    }
  };

  const handleAssessmentData = (e) => {
    handleClick(+e);
  };

  const transposeAssessmentData = (listData) => {
    const hasParent = listData.some((ld) => ld.parentAssessment === true);
    const returnList = listData.map((item) => {
      const listItem = {
        controlId: item.assessmentID,
        name: item.assessmentID,
        showDetail: false,
        headingText: `${item.assessmentCode} - ${item.assessmentName}`,
        linkTarget: "",
        clickEvent: (e) => handleAssessmentData(e?.currentTarget?.id),
        buttonIcon: UPLOADICON,
        buttonText: item.assessmentStatus === 4 ? "Resubmit" : "Submit",
        iconDocs: [
          {
            id: "assessment",
            showIconDocs: item?.assessmentDocuments?.length > 0,
            isclick: false,
            icon: "M15,7H20.5L15,1.5V7M8,0H16L22,6V18A2,2 0 0,1 20,20H8C6.89,20 6,19.1 6,18V2A2,2 0 0,1 8,0M4,4V22H20V24H4A2,2 0 0,1 2,22V4H4Z",
            // cbOnClick: () => {
            //   item["isclick"] = !item.isclick;
            // },
          },
          {
            id: "submitted",
            showIconDocs: item.submissionDate,
            isclick: false,
            icon: "M11 20H6.5Q4.22 20 2.61 18.43 1 16.85 1 14.58 1 12.63 2.17 11.1 3.35 9.57 5.25 9.15 5.88 6.85 7.75 5.43 9.63 4 12 4 14.93 4 16.96 6.04 19 8.07 19 11 20.73 11.2 21.86 12.5 23 13.78 23 15.5 23 17.38 21.69 18.69 20.38 20 18.5 20H13V12.85L14.6 14.4L16 13L12 9L8 13L9.4 14.4L11 12.85Z",

            // cbOnClick: () => {
            //   item["isclick"] = !item.isclick;
            // },
          },
        ],
        hideButton:
          window?.assessmentsConfig &&
          !window?.assessmentsConfig?.submitAssessments,
        buttonFunction: null,
        itemList: [
          { itemText: item.description },
          {
            itemText: parser(
              `<span style="font-size: 0.875rem, font-weight: bold; color:${getAssessmentStatusColor(
                item.assessmentStatus
              )};">${
                hasParent &&
                !item.parentAssessment &&
                item.currentStatus === "Awaiting Submission"
                  ? ""
                  : item.currentStatus
              }</span>`
            ),
          },
          {
            itemText:
              item.submissionDate && item?.submissionDate !== ""
                ? `Submitted on: ${item?.submissionDate}`
                : "",
          },
          {
            itemText:
              item.currentStatus === "Completed Successfully" &&
              item.assessmentNotes !== ""
                ? `Notes: ${item.assessmentNotes}`
                : "",
          },
        ],
        assessmentDocuments: transposeAssessmentDocuments(
          item.assessmentDocuments
        ),
      };

      if (
        (item.assessmentStatus === 1 || item.assessmentStatus === 4) &&
        (item.parentAssessment === true || !hasParent)
      ) {
        listItem.buttonFunction = item.quizId
          ? () => answerQuiz(item)
          : () => uploadDocument(item);
      }

      return listItem;
    });

    setState((pre) => ({ ...pre, listItems: returnList }));
  };

  return (
    <>
      {state?.listItems?.length > 0 && (
        <>
          {state?.alertMessage && (
            <Alert
              alertStatus={state.alertState}
              closeFunction={closeAlert}
              alertMessage={state.alertMessage}
              alertIcon={state.alertIcon}
              iconColor={state.iconColor}
            />
          )}
          <WideList
            cardHeaderSettings={cardHeaderSettings}
            listData={state?.listItems}
            onItemClick={handleAssessmentData}
            cb={true}
            cbDocsClick={handleClick}
            docAssessment={true}
          />

          <Modal
            modalState={state.modalState}
            headingText="Submit Assignments"
            buttonsList={[
              {
                // seq: 1,
                buttonClass: "w-full px-4 py-1.5 font-medium bg-white border border-blue-600 text-blue-600 cursor-pointer shadow rounded-md disabled:cursor-not-allowed",
                clickFunction: closeModal,
                buttonText: "Cancel",
                loading: loading,
              },
              {
                // seq: 2,
                buttonClass: "w-full px-4 py-1.5 font-medium text-white bg-blue-600 cursor-pointer shadow hover:bg-blue-500 rounded-md disabled:bg-blue-600/30 disabled:cursor-not-allowed",
                clickFunction: submitAssessment,
                buttonText: "Submit",
                loading: loading,
              }
            ]}
          >
            {renderUploader()}
          </Modal>
        </>
      )}
    </>
  );
};

export default Assessments;
