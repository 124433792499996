import React from "react";
import FormLabel from "./FormLabel";

const TextAreaBox = ({
  dependentOn,
  error,
  label,
  name,
  value,
  onChange,
  onClick,
  onBlur,
  readOnly,
  rows,
  type,
}) => {
  return (
    <div
      // xs={24}
      // sm={24}
      // md={8}
      // lg={8}
      // xl={8}
      style={{ display: !dependentOn ? "none" : "inherit", width: "100%" }}
    >
      <FormLabel
        text={label}
        color={error ? "red" : "black"}
        error={error}
        errorText={`${label} must be entered.`}
      >
        <textarea
          className={`border px-3 py-2.5 rounded-lg focus:outline-none text-black ${error ? "border-red-400 focus:border-red-400" : "focus:border-blue-400"}`}
          id={name}
          rows={rows}
          name={name}
          onClick={onClick}
          onChange={onChange}
          onBlur={onBlur}
          readOnly={readOnly}
          >
          {value}
        </textarea>
      </FormLabel>
    </div>
  );
};

export default TextAreaBox;
