import React, { useState, useEffect } from "react";
import Axios from "axios";
import FormSection from "../controls/FormSection";
import * as constants from "../../constants";
import { RenderCourse } from "./RenderCourse";
import updateCourseObject from "./UpdatedCourseObject";
import moment from "moment";

let controlIndex = 0;

const blankCourse = {
  Campus: null,
  Intake: null,
  StartDate: null,
  EndDate: null,
  StudyWeeks: null,
  HolidayWeeks: null,
  Program: null,
  Subject: null,
  UOS: null,
  error: {},
};
export const formatDate = (date) => {
  const dt = new Date(date);
  const day = dt.getDate();
  const month = dt.toLocaleString("default", { month: "2-digit" });
  const year = dt.getFullYear();

  return `${year}/${month}/${day}`;
};
const CourseSection = (props) => {
  const {
    intakes = [],
    campuses = [],
    courseType,
    campusObjects,
    program,
    trainingLocations,
  } = props;

  const [hideActionButton, setHideActionButton] = useState(false);
  const [currentCourse, setCurrentCourse] = useState({});
  const [courses, setCourses] = useState([]);

  const calculateCourseEndDate = async (course, index) => {
    try {
      const response = await Axios.get(
        constants.BASEURL + "WebForms/getProgramInfo",
        {
          params: {
            CollegeID: constants.COLLEGEID,
            Program: course.Program,
            Campus: course.Campus,
            Intake: course.Intake,
            StartDate: formatDate(course.StartDate),
          },
        }
      );
      const result = response.data;
      // const sd = new Date(course.StartDate);
      // course.StartDate = moment(course?.StartDate, "DD/MMM/YYYY");

      const ed = new Date(result.endDate);
      // course.EndDate = moment(
      //   new Date(ed.getTime() - ed.getTimezoneOffset() * 60000),
      //   "DD/MMM/YYYY"
      // );
      course.EndDate = ed
      course.StudyWeeks = result.studyWeeks;
      course.HolidayWeeks = result.holidayWeeks;
      latestCourse(course, index);
    } catch (error) {
      console.log(error);
    }
  };

  const addCourse = (index) => {
    const cCourse = blankCourse;
    cCourse.error = {};
    cCourse.Program = courseType !== 9 ? program : null;
    if (courseType === 22) cCourse.Subject = null;
    if (courseType === 23) cCourse.UOS = null;
    const updatedCourses = [...courses, cCourse];
    loadDefaults(cCourse, index);
    setCourses(updatedCourses);
    props.onChange(updatedCourses);
  };

  const removeCourse = (index) => {
    const updatedCourses = [...courses];
    updatedCourses.splice(index, 1);
    setCourses(updatedCourses);
    props.onChange(updatedCourses);
  };

  const loadDefaults = (course, index) => {
    // let course = [currentCourse[index]];

    if (campuses.length == 1) {
      course["Campus"] = campuses[0].campusId;
    }
    let objects = [];
    const intakeData = [...intakes];
    if (parseInt(course.Campus) > 0) {
      // following code is filtering CampusIds
      objects = campusObjects.filter((p) => {
        let campusIds = "," + p.campusIds + ",";
        if (
          campusIds.indexOf(",-1,") >= 0 ||
          campusIds.indexOf("," + course.Campus + ",") >= 0
        )
          return p;
      });
    }

    if (courseType === 9 && objects.length === 1) {
      course.Program = objects[0].objectId;
    } else if (courseType === 22 && objects.length === 1) {
      course.Subject = objects[0].objectId;
    } else if (courseType === 23 && objects.length === 1) {
      course.UOS = objects[0].objectId; // UOS -> Unit of Study
    }

    if (
      (!course.Intake || course.Intake === 0 || course.Intake === "") &&
      parseInt(course.Program) > 0 &&
      parseInt(course.Campus) > 0
    ) {
      let intData = intakeData.filter((i) => i.programId === +course.Program);

      if (intData.length === 1) {
        course.Intake = intData[0].intakeId;
        if (
          (campuses.length === 1 && courseType === 9 && objects.length === 1) ||
          (campuses.length === 1 && courseType !== 9)
        ) {
          setHideActionButton(true);
        }
        const currentIntake = intakeData.find(
          (i) => i.intakeId === course.Intake
        );
        const rollingIntake = currentIntake && currentIntake.rollingIntake;
        if (!rollingIntake && currentIntake.startDate) {
          course.StartDate = new Date(currentIntake.startDate);
          calculateCourseEndDate(course, index);
        } else {
          if (
            window.webformconfigs &&
            window.webformconfigs.makeCurrentDateStartDate
          ) {
            // course.StartDate = moment().format("DD/MMM/YYYY");
            calculateCourseEndDate(course, index);
          }
        }
      }
    }
  };

  const latestCourse = (course, index) => {
    // setCurrentCourse(course);

    updateCourseObject({
      currentCourse: course,
      courses,
      index,
      setCourses,
      courseType: props?.courseType,
      onChange: props?.onChange,
      setCurrentCourse,
    });
  };

  const handleChange = (e, name, type, id) => {
    const updatedCourse = { ...currentCourse };
    let controlName = "";
    if (name && type !== "Select") {
      var index = name.slice(0, 1);
      controlIndex = index;
      name = name.slice(1);
      updatedCourse[name] = e;
      controlName = name;
    } else if (name && type === "Select") {
      var targetID = id;
      var index = targetID.slice(0, 1);
      controlIndex = index;
      updatedCourse[name] = e;
      controlName = name;
    } else {
      var targetID = e.target.id;
      var index = targetID.slice(0, 1);
      controlIndex = index;
      updatedCourse[e.target.name] = e.target.value;
      controlName = e.target.name;
    }

    if (controlName === "Campus") {
      updatedCourse.Subject = null;
      updatedCourse.UOS = null;
      updatedCourse.Program = "";
      updatedCourse.EndDate = "";
      updatedCourse.StartDate = null;
      updatedCourse.Intake = "";
      updatedCourse.StudyWeeks = "";
      updatedCourse.HolidayWeeks = "";
      updatedCourse.error = {};
      loadDefaults(updatedCourse, controlIndex);
      latestCourse(updatedCourse, controlIndex);
    } else if (controlName === "Program") {
      updatedCourse.EndDate = "";
      updatedCourse.StartDate = null;
      updatedCourse.Intake = "";
      updatedCourse.StudyWeeks = "";
      updatedCourse.HolidayWeeks = "";
      updatedCourse.error = {};

      loadDefaults(updatedCourse, controlIndex);
      latestCourse(updatedCourse, controlIndex);
    } else if (controlName === "Intake") {
      updatedCourse.EndDate = "";
      updatedCourse.StartDate = "";
      updatedCourse.StudyWeeks = "";
      updatedCourse.HolidayWeeks = "";
      updatedCourse.error = {};
      const intakeData = [...intakes];
      if (parseInt(updatedCourse.Intake) > 0) {
        const currentIntake = intakeData.find(
          (i) => i.intakeId === updatedCourse.Intake
        );

        const rollingIntake = currentIntake && currentIntake.rollingIntake;
        if (!rollingIntake && currentIntake.startDate) {
          // updatedCourse.StartDate = moment(
          //   currentIntake.startDate,
          //   "DD/MMM/YYYY"
          // );
          updatedCourse.StartDate = new Date(currentIntake.startDate);
          calculateCourseEndDate(updatedCourse, controlIndex);
        } else {
          latestCourse(updatedCourse, controlIndex);
        }
      } else {
        latestCourse(updatedCourse, controlIndex);
      }
    } else if (controlName === "StartDate") {
      updatedCourse.EndDate = "";
      updatedCourse.error = {};
      calculateCourseEndDate(updatedCourse, controlIndex);
    } else {
      latestCourse(updatedCourse, controlIndex);
    }
  };

  useEffect(() => {
    if (courses.length === 0) {
      addCourse(0);
    }
  }, []);

  return (
    <FormSection key={"FS" + props.label} label={props.label}>
      {courses.length === 0 ? (
        <RenderCourse
          course={{}}
          index={0}
          hideActionButton={hideActionButton}
          handleChange={handleChange}
          addCourse={addCourse}
          removeCourse={removeCourse}
          courses={courses}
          {...props}
        />
      ) : (
        courses?.map((course, index) => (
          <RenderCourse
            key={`render-${index}`}
            course={course}
            index={index}
            hideActionButton={hideActionButton}
            handleChange={handleChange}
            addCourse={addCourse}
            removeCourse={removeCourse}
            courses={courses}
            {...props}
          />
        ))
      )}
    </FormSection>
  );
};

export default CourseSection;
