import React from "react";

import Select from "react-select";
import FormLabel from "./FormLabel";

function MultiSelectBox({
  error,
  data,
  dependentOn,
  displayValue,
  name,
  label,
  selectedValue,
  onChange,
}) {
  return (
    <div
      // xs={24}
      // sm={24}
      // md={8}
      // lg={8}
      // xl={8}
      style={{ display: !dependentOn ? "none" : "inherit" }}
    >
      <FormLabel
        text={label}
        name={name}
        color={error ? "red" : "black"}
        error={error}
        errorText={`${label} must be selected.`}
      >
        {/* <Multiselect options={data}
                showCheckbox={true}
                selectedValues={selectedValue}
                onSelect={(selectedArr, selectedObject) => onChange(selectedArr, name)}
                onRemove={(selectedArr, selectedObject) => onChange(selectedArr, name)}
                displayValue={displayValue}
                placeholder={label}
            /> */}

        <Select
          // defaultValue={[colourOptions[2], colourOptions[3]]}
          menuPortalTarget={document.body}
          selectedValue="avetCodeId"
          getOptionLabel={(option) =>
            `${option.avetCode} - ${option.avetDescription}`
          }
          // getOptionValue={option => option.avetCodeId}
          isMulti={true}
          name={name}
          onChange={onChange}
          options={data}
          styles={{
            borderColor: error && "#FECACA",
            color: error && "#9B2C2C",
          }}

          // className="basic-multi-select"
          // classNamePrefix="select"
        />
      </FormLabel>
    </div>
  );
}

export default React.memo(MultiSelectBox);
