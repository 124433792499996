import React, { useEffect, useState } from "react";
import Tabs from "./common/Tabs";
import {
  PROGRAMICON,
  PROGRAMOUTLINEICON,
  SUBJECTICON,
  FEESICON,
} from "../IconConstants";
import { ProgramDetailRoutes } from "../routes";
import queryString from "query-string";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Axios from "axios";
import { BASEURL } from "../constants";
import Spinner from "./common/Spinner";

const Menu = (props) => {
  const location = useLocation();

  return <Tabs {...props} location={location} />;
};

const EnrolmentDetail = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [tabsCollection, setTabsCollection] = useState([]);

  const { uos, subjects } = queryString.parse(location?.search);
  const { id } = useParams();
  useEffect(() => {
    let updatedTabsCollection = [
      {
        title: "Summary",
        icon: PROGRAMICON,
        linkTarget: `summary?uos=${uos}&subjects=${subjects}`,
        selectedOption: true,
        matchedText: "summary",
        isShown: true,
      },
      {
        title: "Course Outline",
        icon: PROGRAMOUTLINEICON,
        linkTarget: `outline?objectType=13&uos=${uos}&subjects=${subjects}`,
        selectedOption: false,
        matchedText: "outline",
        isShown: true,
      },
      {
        title: "Subjects",
        icon: SUBJECTICON,
        linkTarget: `subjects?uos=${uos}&subjects=${subjects}&subTab=current`,
        selectedOption: false,
        matchedText: "subjects",
        isShown: true,
      },
      {
        title: "Account",
        icon: FEESICON,
        linkTarget: `feedetail?uos=${uos}&subjects=${subjects}`,
        selectedOption: false,
        matchedText: "feedetail",
        isShown: true,
      },
    ];
    let pathName = "summary";
    const isUos = uos > 0 || ( location.pathname.slice(-3) === "uos");

    if (isUos) {
      updatedTabsCollection[2].title = "Units Of Study";
      updatedTabsCollection[2].linkTarget = `uos?uos=${uos}&subjects=${subjects}`;
      updatedTabsCollection[2].matchedText = "uos";
    }

    if (
      uos === 0 &&
      subjects === 0 &&
      ( pathName.slice(-3) !== "uos")
    ) {
      updatedTabsCollection = updatedTabsCollection.filter(
        (tabs) => tabs.title !== "Subjects"
      );
    }
    setLoading(true);
    effect();
    async function effect() {
      try {
        const respSummary = await Axios.get(
          BASEURL + "Student/ProgramSummary/" + id
        );
        if (!respSummary?.data?.hasOwnProperty("enrolmentId")) {
          updatedTabsCollection[0]["isShown"] = false;
        }
      } catch (error) {
        console.log("Error in Summary:", error);
        updatedTabsCollection[0]["isShown"] = false;
      }

      try {
        const respCourse = await Axios.get(
          BASEURL + "LmsPage/0?objectId=" + id + "&objectType=" + 13
        );
        if (!respCourse?.data?.hasOwnProperty("lmspageId")) {
          updatedTabsCollection[1]["isShown"] = false;
        }
      } catch (error) {
        console.log("Error in Course:", error);
        updatedTabsCollection[1]["isShown"] = false;
      }

      try {
        const feeResponse = await Axios.get(
          BASEURL + "Student/FinancialsSummary/" + id
        );
        if (
          window.configs.hideFinancials ||
          feeResponse?.data ===
            "<p align='center'><b>No financial transactions have been raised for this enrolment</b></p>"
        ) {
          updatedTabsCollection[3]["isShown"] = false;
        }
      } catch (error) {
        console.log("Error in feeResponse:", error);
        updatedTabsCollection[3]["isShown"] = false;
      }

      try {
        if (!isUos) {
          const respSubject = await Axios.get(
            BASEURL + "Student/Subjects/" + id
          );
          if (!respSubject?.data.length > 0) {
            updatedTabsCollection[2]["isShown"] = false;
          }
        } else if (isUos) {
          const responseUOS = await Axios.get(BASEURL + "Student/UOS/" + id);
          if (!responseUOS?.data) {
            updatedTabsCollection[2]["isShown"] = false;
          }
        }
      } catch (error) {
        console.log("Error in respSubject or responseUOS:", error);
        updatedTabsCollection[2]["isShown"] = false;
      }

      const arrayTabUpdate = [];
      updatedTabsCollection.forEach((i) =>
        location.pathname.includes(i?.matchedText)
          ? arrayTabUpdate.push({ ...i, selectedOption: true })
          : arrayTabUpdate.push({ ...i, selectedOption: false })
      );

      console.log(arrayTabUpdate, updatedTabsCollection);
      setTabsCollection(arrayTabUpdate);

      setLoading(false);
    }
  }, [location?.pathname]);
  const handleOptionChoose = (e) => {
    // setTabSelect(e);
    const updatedTabsCollection = [];
    tabsCollection.forEach((i) =>
      i.linkTarget === e
        ? updatedTabsCollection.push({ ...i, selectedOption: true })
        : updatedTabsCollection.push({ ...i, selectedOption: false })
    );
    setTabsCollection(updatedTabsCollection);
    navigate(e);
  };
  return (
    <React.Fragment>
      {!loading && tabsCollection?.length > 0 && (
        <div className="px-[2rem]">
          <Menu
            tabsCollection={tabsCollection}
            // pathname={tabSelect}
            handleOptionChoose={handleOptionChoose}
          />

          <main style={{ backgroundColor: "#edf2f7cc" }}>
            <ProgramDetailRoutes />
          </main>
        </div>
      )}
      {loading && <Spinner status={loading} />}
    </React.Fragment>
  );
};

export default EnrolmentDetail;
