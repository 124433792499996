import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";

const Tabs = ({ tabsCollection, pathname, handleOptionChoose }) => {
  const location = useLocation();
  const param = useParams();

  const renderOptions = (selOptions, index) => {
    let selectedPath = pathname;
    if (selOptions.selectedOption) {
      return (
        <option key={`${index}`} value={selOptions?.linkTarget} defaultValue>
          {selOptions.title}
        </option>
      );
    } else {
      return (
        <option key={`${index}`} value={selOptions?.linkTarget}>
          {selOptions.title}
        </option>
      );
    }
  };

  const renderTabs = (tabOption, index, handleOptionChoose) => {
    const handleClick = (event) => {
      event.preventDefault();
      handleOptionChoose(tabOption?.linkTarget);
    };
    if (!tabOption?.isShown) {
      return null;
    }
    return (
      <NavLink
        key={`${tabOption?.linkTarget}-${index}`}
        onClick={handleClick}
        // to={`./${tabOption.linkTarget}`}
        className={`group flex items-center gap-0.5 mr-5 pb-2 font-medium text-sm ${tabOption?.selectedOption ? "border-b-2 text-indigo-600 border-b-indigo-600" : "text-slate-400 hover:text-gray-800 hover:border-gray-800 hover:border-b-2"}`}
        // to={"./" + tabOption.linkTarget}
      >
        <svg
          className={`w-5 h-5 ${tabOption?.selectedOption ? "fill-indigo-600" : "fill-slate-400 group-hover:fill-gray-800"}`}
          viewBox="0 0 20 20"
        >
          <path fillRule="evenodd" d={tabOption.icon} clipRule="evenodd" />
        </svg>
        <span className="ml-1">{tabOption.title}</span>
      </NavLink>
    );
  };

  return (
    <div>

      <div>
        <select
          aria-label="Selected tab"
          className="mb-4 w-full font-normal p-2 rounded-lg focus:outline-none text-black border focus:border-blue-400 sm:hidden block"
          defaultValue="summary"
          value={tabsCollection.find((i) => i.selectedOption)?.linkTarget}
          onChange={(e) => handleOptionChoose(e.target.value)}
        >
          {tabsCollection.map(renderOptions)}
        </select>
      </div>

      <div className="sm:block hidden">
        <div className="bg-gray">
          <nav className="flex">
            {tabsCollection.map((e, indx) =>
              renderTabs(e, indx, handleOptionChoose)
            )}
          </nav>
        </div>
      </div>

    </div>
  );
};

export default Tabs;
