import React from "react";
// import moment from "moment";

import SelectBox from "../controls/SelectBox";
import DateBox from "../controls/DateBox";
import TextBox from "../controls/TextBox";
import RenderActionButtons from "./RenderActionButtons";
import { RenderObject } from "./RenderObject";

import moment from "moment";

export const RenderCourse = ({
  courseError,
  course,
  index,
  hideActionButton,
  handleChange,
  addCourse,
  removeCourse,
  campuses,
  allowMultiplePrograms,
  courseType,
  campusObjects,
  intakes,
  trainingLocations = [],
  ...props
}) => {

  console.log("asdsada",courseError)

  const { error } = course;
  let intakeData = [];
  let rollingIntake = false;
  let trainingLocationData = [];
  intakes.forEach(
    (i) => (i.intakeDetail = i.intakeCode + " - " + i.intakeName)
  );
  trainingLocations.map(
    (i) => (i.trainingLocationDetail = i.locationCode + " - " + i.locationName)
  );

  if (parseInt(course?.Program) > 0) {
    intakeData = [...intakes];
    intakeData = intakeData.filter((i) => i.programId === +course.Program);
  }
  if (parseInt(course?.Campus) > 0) {
    trainingLocationData = trainingLocations;
    trainingLocationData = trainingLocationData.filter((location) => {
      if(location.tlCampusIds !== "-1"){
        return ("," + location.tlCampusIds.replace(" ", "") + ",").includes("," + course.Campus + ",");
      }else{
        return location;
      }
    }
    );
    if (trainingLocationData.length === 1) {
      course.TrainingLocation = trainingLocationData[0].trainingLocationId;
    }
  }
  if (parseInt(course.Intake) > 0) {
    const currentIntake = intakeData.find((i) => i.intakeId === course.Intake);
    rollingIntake = currentIntake && currentIntake.rollingIntake;
    if (!rollingIntake && currentIntake.startDate) {
      course.StartDate = new Date(currentIntake.startDate)
      // moment(currentIntake.startDate, "DD/MMM/YYYY");
    }
  }

  const handleSelect = (e, name) => {
    handleChange(e, name, "Select", `${index}${name}`);
  };
  return (
    <div className={`col-span-3 ${courseError ? "border border-red-500": ""}`}>
      <div style={{ display: hideActionButton ? "none" : "block" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "no-wrap",
            flexShrink: 0,
          }}
        >
          {allowMultiplePrograms && (
            <>
              <h3
                style={{
                  fontSize: "1rem",
                  lineHeight: "1.5rem",
                  fontWeight: 500,
                  color: "#1F2937",
                }}
              >
                {window.webformconfigs &&
                window.webformconfigs.courseLabelToShow &&
                window.webformconfigs.courseLabelToShow != ""
                  ? window.webformconfigs.courseLabelToShow
                  : "Course No." + (index + 1)}
              </h3>
              <RenderActionButtons
                index={index}
                ProgramCourse={course.Program}
                addCourse={addCourse}
                removeCourse={removeCourse}
              />
            </>
          )}
        </div>
      </div>
      
      <div>
        <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-x-6 gap-y-4">
          <SelectBox
            key={"campus" + index}
            id={index + "Campus"}
            name="Campus"
            disabled={campuses.length === 1}
            label="Campus*"
            idField="campusId"
            valueField="campusDetail"
            error={error?.Campus}
            onChange={handleSelect}
            data={campuses}
            multiple={index}
            value={course?.Campus}
            dependentOn={
              window.webformconfigs &&
              window.webformconfigs.defaultWebFormCampus > 0
                ? false
                : true
            }
          />
            
          <RenderObject
            courses={course}
            index={index}
            courseType={courseType}
            campusObjects={campusObjects}
            handleChange={handleSelect}
          />

          <SelectBox
            key={"intake" + index}
            id={index + "Intake"}
            multiple={index}
            name="Intake"
            disabled={intakeData.length === 1}
            label="Intake*"
            error={error?.Intake}
            onChange={handleSelect}
            data={intakeData}
            idField="intakeId"
            valueField="intakeDetail"
            value={course["Intake"]}
            dependentOn={
              window.webformconfigs &&
              window.webformconfigs.defaultRollingIntake > 0
                ? false
                : true
            }
          />
          <DateBox
            key={"StartDate" + index}
            id={"StartDate" + (index + 1)}
            label="Start Date"
            error={error?.StartDate}
            disabled={!rollingIntake}
            value={course["StartDate"]}
            name={index + "StartDate"}
            onChange={handleChange}
            dependentOn={
              window.webformconfigs &&
              window.webformconfigs.hideCourseSelectionDatesAndWeeks
                ? false
                : true
            }
          />

          <TextBox
            key={"TB8" + index}
            id={index + "Study Weeks"}
            label="Study Weeks"
            readOnly
            value={course["StudyWeeks"]}
            name="StudyWeeks"
            onChange={handleChange}
            dependentOn={
              window.webformconfigs &&
              window.webformconfigs.hideCourseSelectionDatesAndWeeks
                ? false
                : true
            }
          />

          {course["HolidayWeeks"] > 0 && (
            <TextBox
              key={"HolidayWeeks" + index}
              id={index + "HolidayWeeks"}
              label="Holiday Weeks"
              readOnly
              error={error?.HolidayWeeks}
              value={course["HolidayWeeks"]}
              name="HolidayWeeks"
              onChange={handleChange}
              dependentOn={
                window.webformconfigs &&
                window.webformconfigs.hideCourseSelectionDatesAndWeeks
                  ? false
                  : true
              }
            />
          )}
          
          <DateBox
            key={"EndDate" + index}
            id={"EndDate" + (index + 1)}
            label="End Date"
            error={error?.EndDate}
            disabled
            value={course["EndDate"]}
            name={index + "EndDate"}
            // onChange={handleChange}
            dependentOn={
              window.webformconfigs &&
              window.webformconfigs.hideCourseSelectionDatesAndWeeks
                ? false
                : true
            }
          />
          <SelectBox
            key={"trainingLocation" + index}
            id={index + "TrainingLocation"}
            multiple={index}
            name="TrainingLocation"
            disabled={trainingLocationData.length === 1}
            label="Training Location"
            error={error?.TrainingLocation}
            onChange={handleSelect}
            data={trainingLocationData}
            idField="trainingLocationId"
            valueField="trainingLocationDetail"
            value={course["TrainingLocation"]}
            dependentOn={
              window.webformconfigs && window.webformconfigs.showTrainingLocations
                ? true
                : false
            }
          />
        </div>
      </div>
    </div>
  );
};
