import React, { useState } from "react";
import FormLabel from "./FormLabel";
import Dropzone from "react-dropzone";


const Uploader = (props) => {
  const { error, label, name = "uploader", onUpload, docTypeId, files } = props;
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const draggerStyle = {
    marginTop: "2px",
    border: `${error ? "1px solid red" : "1px dashed gray"}`,
  };
  const handleDrop = (fileList) => {
    setUploadedFiles(fileList)
    if (onUpload) {
      onUpload(fileList, docTypeId);
    }
  };

  const handleDelete = (event, file) => {
    event.stopPropagation();
    const newFiles = files.filter((f) => f !== file);
    setUploadedFiles(newFiles)

    if (onUpload) {
      onUpload(newFiles, docTypeId);
    }
  };

  const fileList = (file) => {
    return (
      <li
        key={file.name}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <p>{file.name} <span className="text-blue-600 text-xs ml-2">{file.size} bytes</span></p>
        <span onClick={(e) => handleDelete(e,file)}>
          <svg
            style={{
              height: "1.25rem",
              width: "1.25rem",
              color: "red",
              display: "inline-block",
              cursor: "pointer",
            }}
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              fillRule="evenodd"
              d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
              clipRule="evenodd"
            />
          </svg>
        </span>
      </li>
    );
  };

  return (
    <div>
      <FormLabel
        text={label}
        name={name}
        color={error ? "red" : "black"}
        error={error}
      >
        <Dropzone onDrop={handleDrop}>
          {({ getRootProps, getInputProps }) => {
            return (
              <div>
                <div
                  className={
                    "mt-2 flex justify-center px-6 pt-5 pb-6 border-2 " +
                    (error ? "border-red-400" : "border-gray-300") +
                    " border-dashed rounded-md"
                  }
                  {...getRootProps()}
                >
                  <div className="text-center">
                    <svg
                      className="mx-auto h-12 w-12 text-gray-400"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 48 48"
                    >
                      <path
                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <p className="mt-1 text-sm text-gray-600">
                      <input {...getInputProps()} />
                      <button
                        type="button"
                        className="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition duration-150 ease-in-out"
                      >
                        Upload a file
                      </button>
                      <span className="pl-1">or drag and drop</span>
                    </p>
                  </div>
                </div>
                  <ul className="w-full">
                    {uploadedFiles.map((file) => fileList(file))}
                  </ul>
              </div>
            );
          }}
        </Dropzone>
      </FormLabel>
    </div>
  );
};

export default Uploader;
