import React from "react";
import { IoMdCheckmark } from "react-icons/io";

const CustomModal = ({
  modalState,
  headingIcon,
  headingText,
  buttonsList,
  children,
}) => {
  
  if (!buttonsList) return null;

  return (
    <>
   {modalState && (
      <div className="fixed top-0 left-0 h-[100vh] w-full bg-black/40 grid place-content-center">
        <div className="absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] bg-white border rounded-lg w-[550px]">
          <form >

            <div className="flex items-center bg-green-200/50 py-2.5 gap-2 px-3 rounded-t-lg">
              <IoMdCheckmark className="w-6 h-6 text-green-700 mt-1" />
              <h3 id="modal-headline" className="text-green-700">{headingText}</h3>
            </div>

            <div className="p-4">{children}</div>
            
            <div className="grid grid-cols-2 gap-2 p-4">
              {buttonsList.map((buttonItem) => (
                <span key={buttonItem.buttonText}>
                  <button
                    type="button"
                    className={buttonItem?.buttonClass}
                    onClick={buttonItem?.clickFunction}
                    disabled={buttonItem?.loading}
                  >
                    {buttonItem.buttonText}
                  </button>
                </span>
              ))}
            </div>
          </form>
        </div>
      </div>
    )}
    </>  
  );
};
export default CustomModal;
