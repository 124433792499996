import React, { useState, useEffect } from "react";
import axios from "axios";
import * as constants from "../constants";
import parse from "html-react-parser";
import { useParams } from "react-router-dom";
import { removeDecimalIfZero } from "../services/helperFunction";
import { toast } from "react-toastify";
import moment from "moment/moment";

const ProgramFee = () => {
  const [transactions, setTransactions] = useState(undefined);
  const [financialSummary, setFinancialSummary] = useState(undefined);
  const [error, setError] = useState(false);
  let { id } = useParams();

  const renderStatus = (cancelled, isOverDue, type, isPaid, transactionNo) => {
    if (type === "Invoice" && !transactionNo.startsWith("-")) {
      if (cancelled === "1") {
        return (
          <span className="font-medium rouonded-full inline-block px-1 text-xs rounded-full text-red-800 bg-red-100">
            Cancelled
          </span>
        );
      } else {
        if (isOverDue === "1") {
          return (
            <span className="font-medium rouonded-full inline-block px-1 text-xs rounded-full text-yellow-800 bg-yellow-100">
              Overdue
            </span>
          );
        } else {
          if(isPaid === "1"){
            return (
              <span className="font-medium rouonded-full inline-block px-1 text-xs rounded-full text-sky-600 bg-sky-100">
                Paid
              </span>
            );  
          }else{
            return (
              <span className="font-medium rouonded-full inline-block px-1 text-xs rounded-full text-green-800 bg-green-100">
                Active
              </span>
            );
          }
        }
      }
    }
  };

  const renderDue = (transaction) => {

    const { transactionType, transactionNo, cancelled, dueInDays, dueDate } = transaction;

    const isInvoice = transactionType.toLowerCase() === "invoice" && !transactionNo.startsWith("-");
    const isCancelled = parseInt(cancelled);
    const isDueDateFuture = moment(dueDate).isAfter(moment());
    const dueDays = parseInt(dueInDays);

    if(!isInvoice || isCancelled || isDueDateFuture ){
      return ""
    }

    if(dueDays === 0){
      return "Due Today"
    }

    if(dueDays < 0){
      return `Due ${dueDays * -1} days ago`
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [financialSummaryResponse, transactionsResponse] =
          await Promise.all([
            axios.get(constants.BASEURL + "Student/FinancialsSummary/" + id),
            axios.get(constants.BASEURL + "Student/Financials/" + id)
          ]);
        if (financialSummaryResponse.data.length > 0) {
          setFinancialSummary(financialSummaryResponse.data[0]);
        }

        setTransactions(transactionsResponse.data);
      } catch (error) {
        setError(true);
        toast.error("Something went wrong")
        // message.error('Something went wrong');
      }
    };

    fetchData();  
  }, [id]);

  if (!transactions) return "loading...";
  if (error) return "Something went wrong";

  // const columns = [
  //   {
  //     title: <span className="text-gray-500">Description</span>,
  //     dataIndex: "description",
  //     key: "description",
  //   },
  //   {
  //     title: <span className="text-gray-500">Date</span>,
  //     dataIndex: "date",
  //     key: "date",
  //   },
  //   {
  //     title: <span className="text-gray-500">Debit</span>,
  //     dataIndex: "debit",
  //     key: "debit",
  //     align: "right",
  //   },
  //   {
  //     title: <span className="text-gray-500">Credit</span>,
  //     dataIndex: "credit",
  //     key: "credit",
  //     align: "right",
  //   },
  //   {
  //     title: <span className="text-gray-500">Balance</span>,
  //     dataIndex: "balance",
  //     key: "balance",
  //     align: "right",
  //     render: (text) => {
  //       return (
  //         <>
  //           <p style={{ color: "black" }}> {text}</p>
  //         </>
  //       );
  //     },
  //   },
  // ];

  // const dataSource = transactions.map((transaction) => ({
    
  //   key: transaction?.transactionNo,
  //   description: (
  //     <div>
  //       <div className="description">
  //         <div className="icon">
  //           <div
  //             className={`iconContent ${
  //               transaction.transactionType === "Receipt"
  //                 ? "receipt"
  //                 : transaction.transactionType === "Refund"
  //                 ? "refund"
  //                 : transaction.transactionType === "Transfer"
  //                 ? "transfer"
  //                 : transaction.transactionNo.startsWith("-")
  //                 ? "negative"
  //                 : ""
  //             } `}
  //           >
  //             {transaction.transactionNo.startsWith("-") ? (
  //               <p>CN</p>
  //             ) : (
  //               transaction.transactionType.substring(0, 3)
  //             )}
  //           </div>
  //         </div>
  //         <div className="info text-gray-900">
  //           <div className="title">
  //             {transaction.transactionType +
  //               "# " +
  //               transaction.transactionNo +
  //               " "}
  //             {renderStatus(
  //               transaction.cancelled,
  //               transaction.isOverDue,
  //               transaction.transactionType
  //             )}
  //           </div>
  //           <div className="subtitle text-gray-500">
  //             {"created on " + transaction.createDate}
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   ),
  //   date: (
  //     <div>
  //       <div className="date text-gray-900 ">
  //         {transaction.transactionType +
  //           " Date: " +
  //           transaction.transactionDate}
  //       </div>
  //       <div className="subtitle text-gray-500 ">
  //         {transaction.dueDate ? <p>Due Date: {transaction.dueDate}</p> : ""}
  //         {parseInt(transaction.dueInDays) === 0 ? (
  //           <p>Due Today</p>
  //         ) : parseInt(transaction.dueInDays) > 0 &&
  //           parseInt(transaction.dueInDays) < 30 ? (
  //           <p>Due in {transaction.dueInDays} days</p>
  //         ) : (
  //           ""
  //         )}
  //       </div>
  //     </div>
  //   ),
  //   debit: removeDecimalIfZero(transaction.debit),
  //   credit: removeDecimalIfZero(transaction.credit),
  //   balance: removeDecimalIfZero(transaction.balance),
  // }));
  // console.log(dataSource)
  return (
    <div className="">
      <div className="overflow-hidden bg-white pt-4">
        <div>
          <h2 className="text-indigo-600 ml-2 text-lg font-semibold">Account Summary</h2>
          <div className="my-4 ml-2 text-sm text-gray-400 space-y-3">

            <p>
              {financialSummary?.totalStudentInvoiced &&
                financialSummary?.totalStudentInvoiced !== "$0.00" && (
                  <span>
                    Invoiced <b>{financialSummary?.totalStudentInvoiced}</b>
                  </span>
                )}

              {financialSummary?.totalStudentCancelledInvoiced &&
                financialSummary?.totalStudentCancelledInvoiced !== "$0.00" && (
                  <span>
                    , Invoiced Cancelled{" "}
                    <b>{financialSummary?.totalStudentCancelledInvoiced}</b>
                  </span>
                )}
            </p>
            
            {financialSummary?.overDueAmount &&
              financialSummary?.overDueAmount !== "$0.00" && (
                <p>
                  Overdue{" "}
                  <b style={{ color: "#ef4444" }}>
                    {financialSummary?.overDueAmount}
                  </b>
                  {financialSummary?.lastDueDate &&
                    financialSummary?.lastDueDate?.trim() !== "" && (
                      <span>
                        {" "}
                        since <b>{financialSummary?.lastDueDate}</b>
                      </span>
                    )}
                </p>
              )}

            {financialSummary?.totalStudentReceipted &&
              financialSummary?.totalStudentReceipted !== "$0.00" && (
                <p>
                  Receipted <b>{financialSummary?.totalStudentReceipted}</b>
                  {financialSummary?.lastReceiptAmount &&
                    financialSummary?.lastReceiptAmount !== "$0.00" && (
                      <span>
                        , last receipt{" "}
                        <b>{financialSummary?.lastReceiptAmount}</b>
                      </span>
                    )}
                  {financialSummary?.lastReceiptDate &&
                    financialSummary?.lastReceiptDate?.trim() !== "" && (
                      <span>
                        {" "}
                        since <b>{financialSummary?.lastReceiptDate}</b>
                      </span>
                    )}
                </p>
              )}

            {financialSummary?.totalBalanceOwing &&
              financialSummary?.totalBalanceOwing !== "$0.00" && (
                <p>
                  Balance owing <b>{financialSummary?.totalBalanceOwing}</b>
                  {financialSummary?.nextInvoiceDueAmount &&
                    financialSummary?.nextInvoiceDueAmount !== "$0.00" && (
                      <span>
                        , next invoice due{" "}
                        <b>{financialSummary?.nextInvoiceDueAmount}</b>
                      </span>
                    )}
                  {financialSummary?.nextInvoiceDueDate &&
                    financialSummary?.nextInvoiceDueDate?.trim() !== "" && (
                      <span>
                        {" "}
                        since <b>{financialSummary?.nextInvoiceDueDate}</b>
                      </span>
                    )}
                </p>
              )}
          </div>
        </div>
      </div>
      <div className="p-4">
        <div className="overflow-x-auto overflow-y-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Description</th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Date</th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Debit</th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Credit</th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">balance</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {transactions.map((item) => (
                <tr className="even:bg-gray-50 hover:bg-gray-100 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out" key={item.transactionId}>
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                    <div>
                      <div className="flex items-center">
                        <div className="h-10 w-10 rounded-full bg-red-400 mr-2">
                          <div
                            className={`h-10 w-10 grid place-content-center text-base font-extrabold text-white ${
                              item.transactionType === "Receipt"
                                ? "rounded-full bg-emerald-400"
                                : item.transactionType === "Refund"
                                ? "rounded-full bg-sky-400"
                                : item.transactionType === "Transfer"
                                ? "rounded-full bg-orange-400"
                                : item.transactionNo.startsWith("-")
                                ? "bg-gray-300 rounded-full"
                                : ""
                            } `}
                          >
                            {item.transactionNo.startsWith("-") ? (
                              <p>CN</p>
                            ) : (
                              item.transactionType.substring(0, 3)
                            )}
                          </div>
                        </div>
                        <div className="ml-3 text-gray-900">
                          <div className="text-sm text-semibold text-blue-950">
                            <span className={item.transactionNo.startsWith("-") ? "text-red-500" : ""}>{item.transactionType + "# " + item.transactionNo + " "}</span>

                            {renderStatus(
                              item.cancelled,
                              item.isOverDue,
                              item.transactionType,
                              item.isPaid,
                              item.transactionNo
                            )}
                          </div>
                          <div className="text-sm text-gray-500">
                            {"created on " + item.createDate}
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm">
                    <div>
                      <div className="text-sm text-gray-900 ">
                        {item.transactionType +
                          " Date: " +
                          item.transactionDate}
                      </div>
                      <div className="text-sm text-gray-500 ">
                        {item.dueDate ? <p>Due Date: {item.dueDate}</p> : ""}
                        {renderDue(item)}
                      </div>
                    </div>
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm">{removeDecimalIfZero(item.debit)}</td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm">{removeDecimalIfZero(item.credit)}</td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm">{removeDecimalIfZero(item.balance)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ProgramFee;
