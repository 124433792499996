import React from "react";
import Icon from "./Icon";

function ListItem(props) {
  const { listItem } = props;
  return (
    <React.Fragment>
      <div className="mr-[1.5rem] flex items-center font text-sm text-slate-400 my-1.5">
        {listItem?.icon && (
          <Icon src={listItem.icon} viewBox={listItem?.viewBox} />
        )}
        {listItem?.iconComp}
        {listItem?.itemText}
      </div>
    </React.Fragment>
  );
}

export default ListItem;
