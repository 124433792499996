import React, { useRef } from "react";
import ContactSection from "../common/ContactSection";
import CourseSection from "../courseSection/index";
import PreferencesSection from "../common/PreferencesSection";
import ReferenceSection from "../common/ReferenceSection";
import CheckBox from "../controls/CheckBox";
import FormSection from "../controls/FormSection";
import RadioBox from "../controls/RadioBox";
import Uploader from "../controls/Uploader";
import parser from "html-react-parser";
import SignaturePad from "../controls/SignaturePad";

const RenderCustomSections = ({
  content,
  formSectionID,
  formSectionTitle,
  formFields,
  expanded,
  webForm,
  webFormFields,
  handleChange,
  setWebFormFields,
  DropDowns,
  sigPadRef,
  updatePreference,
  updateCourse,
  setFiles,
  updateReference,
  updateContact,
  clearSignature,
  trim,
}) => {
  const section = webForm?.webFormDetail?.formSections.filter(
    (fs) => fs.formSectionID == formSectionID
  )[0];
  switch (formSectionID) {
    case 1:
      const {
        applicationFor,
        programId,
        applicationObjects,
        programIntakes,
        webFormCampus,
        allowOneProgramOnly,
        trainingLocations,
      } = webForm;

      return (
        <CourseSection
          courseError={section.error}
          label={formSectionTitle}
          allowMultiplePrograms={!allowOneProgramOnly}
          onChange={updateCourse}
          // course={webFormFields.courses}
          courseType={applicationFor}
          program={programId}
          campuses={webFormCampus}
          campusObjects={applicationObjects}
          intakes={programIntakes}
          trainingLocations={trainingLocations}
        />
      );

    case 2:
      if (webForm.uploadDocuments?.length > 0) {
        return (
          <FormSection
            expanded={expanded}
            headerClick={() => {
              expanded = !expanded;
            }}
            label={formSectionTitle}
          >
            {webForm.uploadDocuments.map((upd, idx) => (
              <Uploader
                error={upd.error}
                onUpload={setFiles}
                docTypeId={upd.docTypeID}
                label={upd.docType}
                files={upd.files}
                key={`uploader-${upd.docTypeID}-${idx}`}
              />
            ))}
          </FormSection>
        );
      }
      break;

    case 3:
    case 4:
    case 5:
    case 40:
      let fieldName = "DeclarationChecked";
      if (formSectionID === 3) fieldName = "TermsChecked";
      if (formSectionID === 5) fieldName = "AgentTermsChecked";
      if (formSectionID === 40) fieldName = "PersonalConsent";

      return (
        <FormSection
          expanded={expanded}
          headerClick={() => {
            expanded = !expanded;
          }}
          label={formSectionTitle}
        >
          <div className={`col-span-3 overflow-auto ${section.error && "1px solid #f87171"}`}>{parser(content)}</div>
          <div className="col-span-3">
            <div className="flex items-center justify-center">
              {formSectionID === 40 ? (
                <div>
                  <RadioBox
                    dependentOn={true}
                    value={webFormFields[fieldName]}
                    name={fieldName}
                    align="justify-center"
                    onChange={handleChange}
                    dataStr="1|I Agree,2|I do not Agree"
                  />
                </div>
              ) : (
                <CheckBox
                  label="I Agree"
                  dependentOn={true}
                  name={fieldName}
                  value={webFormFields[fieldName]}
                  align={{
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onChange={handleChange}
                />
              )}
            </div>
          </div>
        </FormSection>
      );

    case 22:
      return (
        <PreferencesSection
          label={formSectionTitle}
          onChange={updatePreference}
          preference={webFormFields.preferences}
          campuses={webForm?.webFormCampus}
          programs={webForm?.applicationObjects}
        />
      );

    case 24:
      return (
        <ReferenceSection
          label={formSectionTitle}
          onChange={updateReference}
          references={webFormFields.references}
        />
      );

    case 29:
      return (
        <ContactSection
          dropDowns={DropDowns}
          label={formSectionTitle}
          onChange={updateContact}
          contactFields={formFields}
          contacts={webFormFields.contacts}
        />
      );

    case 34:
      return (
        <SignaturePad
          error={section.error}
          label={formSectionTitle}
          reference={sigPadRef}
          clearFn={clearSignature}
          onEnd={trim}
        />
      );

    default:
      return null;
  }
};

export default RenderCustomSections;
