import React, { useEffect, useState } from "react";
import Axios from "axios";
import * as constants from "../constants";
import Documents from "../services/Documents";
import { useLocation, useParams } from "react-router";
import queryString from "query-string";

const UOSSummary = (props) => {
  const location = useLocation();
  const { programId } = queryString.parse(location.search);
  const [programUsedId, setProgramUsedId] = useState(programId);
  const { id } = useParams();
  const [subjectSummary, setSubjectSummary] = useState(undefined);

  const resourcesCardHeaderSettings = {
    headingText: "Resources",
    description: "available to download",
    objectType: 23,
  };

  const documentsCardHeaderSettings = {
    headingText: "My Assignments",
    description: "submitted so far",
    objectType: 13,
    objectId: id,
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Axios.get(
          constants.BASEURL +
            "Student/SubjectUOSSummary/" +
            id +
            "?objectType=uos&programId=" +
            programUsedId
        );
        const subjectSummary = response.data;
        setSubjectSummary(subjectSummary);
        setProgramUsedId(subjectSummary.enrolmentId);
      } catch (error) {
        // Handle error
      }
    };

    fetchData();
  }, [id, programId]);

  const renderSubjectSummary = () => {
    if (!subjectSummary) {
      return "loading....";
    } else {
      let startText = "";
      let finishText = "";
      if (subjectSummary.startDate) {
        startText =
          (subjectSummary.started ? " Started on " : "Starting on ") +
          subjectSummary.startDate;
      }

      if (subjectSummary.endDate) {
        finishText =
          (subjectSummary.finished ? " Finished on " : "Finishing on ") +
          subjectSummary.endDate;
      }

      return (
        <div>
          <span className="text-[1.125rem] leading-normal font-bold text-[#888888]"
            
          >
            Code
          </span>
          <span className="text-[1.25rem] leading-normal font-medium text-[#111111]"
            
          >
            &nbsp;{subjectSummary.subjectCode}
          </span>

          <dd style={{ marginTop: "0.5rem" }}>
            <p className={"text-base text-gray-500"}>{subjectSummary.subjectName}</p>
            <p className={"text-base text-gray-500"}>{startText}</p>
            <p className={"text-base text-gray-500"}>{finishText}</p>
          </dd>
        </div>
      );
    }
  };

  const renderDocuments = (documentType) => {
    let docs = undefined;
    if (documentType === 1) {
      let optionSettings = resourcesCardHeaderSettings;
      optionSettings.objectId = id;
      return <Documents options={resourcesCardHeaderSettings} />;
    } else {
      return <Documents options={documentsCardHeaderSettings} />;
    }
  };

  return (
    <div className="flex flex-col p-[1rem]"
      
    >
      <div className="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 ">
        <div className="border-b border-gray-200 px-5 py-5 sm:px-6">
          <h3 className="text-lg font-medium text-gray-900">
            Unit of Study Summary
          </h3>
        </div>
        <div className="px-4 py-5 sm:p-6">
          {renderSubjectSummary()}
        </div>
      </div>
      {id ? renderDocuments(1) : null}
    </div>
  );
};

export default UOSSummary;
