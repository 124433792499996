import React, { useState, useEffect } from "react";
import PageBlock from "./PageBlock";
import axios from "axios";
import * as constants from "../constants";
import { useLocation, useParams } from "react-router-dom";
import queryString from "query-string";

const LmsPage = () => {
  const [lmsPage, setLmsPage] = useState(undefined);
  const location = useLocation();
  const { id } = useParams();
  const { objectType } = queryString.parse(location.search);

  useEffect(() => {
    const fetchData = async () => {
      const config = { headers: { "Content-Type": "application/json" } };

      try {
        const response = await axios.get(
          constants.BASEURL +
            "LmsPage/0?objectId=" +
            id +
            "&objectType=" +
            objectType
        );
        setLmsPage(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [location?.search, id]);

  if (lmsPage === "") {
    return "No information found";
  }

  if (!lmsPage) {
    return "Loading....";
  }

  return (
    <div className="overflow-hidden p-4 pb-4 shadow rounded-md ">
      <div className="border-b boder-gray-200">
        <h3 className="text-[1.5rem] font-semibold text-[#1F2937] leading-normal mt-4">
           {lmsPage.lmspageName}
        </h3>
        <p className="my-[1.25rem] text-[0.875rem] leading-tight text-[#6B7280]">
          {lmsPage.lmspageDescription}
        </p>
      </div>

      <div className="w-full" >
        {lmsPage.lmsPageBlocks.map((pageBlock) => (
          <PageBlock
            key={pageBlock.lmspageBlockId}
            blockContent={pageBlock}
          ></PageBlock>
        ))}
      </div>
    </div>
  );
};

export default LmsPage;
