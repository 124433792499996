import React, { useEffect, useState } from "react";
import SelectBox from "../controls/SelectBox";
import FormSection from "../controls/FormSection";

const blankPreference = {
  Campus: null,
  Program: null,
  error: {},
};

const PreferencesSection = ({
  label,
  program,
  campuses,
  programs,
  onChange,
}) => {
  const [preferences, setPreferences] = useState([]);

  useEffect(() => {
    if (preferences.length === 0) {
      addPreference();
    }
  }, []);

  const loadDefaults = (preference) => {
    if (campuses.length === 1) preference["Campus"] = campuses[0].campusId

    let objects = [...programs];

    if (parseInt(preference.Campus) > 0) {
      objects = objects.filter((p) => {
        let campusIds = "," + p.campusIds + ",";
        if (
          campusIds.indexOf(",-1,") >= 0 ||
          campusIds.indexOf("," + preference.Campus + ",") >= 0
        ) {
          return p;
        }
      });
    } else {
      objects = [];
    }

    if (objects.length === 1) {
      preference.Program = objects[0].objectId;
    }
  };

  const addPreference = () => {
    const cPreference = { ...blankPreference };
    cPreference.error = {};
    cPreference.Program = program;
    const newPreferences = [...preferences, cPreference];
    loadDefaults(cPreference);
    setPreferences(newPreferences);
    onChange(newPreferences);
  };

  const removePreference = (index) => {
    const updatedPreferences = preferences;
    updatedPreferences.splice(index, 1);
    setPreferences(updatedPreferences);
    onChange(updatedPreferences);
  };

  const handleChange = (e, name, preference, indx) => {
    const updatedPreference = preference;
    let controlName = "";
    if (name) {
      updatedPreference[name] = e;
      controlName = name;
    } else {
      updatedPreference[e.target.name] = e.target.value;
      controlName = e.target.name;
    }

    if (controlName === "Campus") {
      updatedPreference.Program = "";
      updatedPreference.error = {};
    }

    const keys = Object.keys(updatedPreference);
    keys.forEach((key) => {
      if (key !== "error") {
        if (
          (updatedPreference[key] == null || updatedPreference[key] === "") &&
          updatedPreference.error[key] === undefined
        ) {
          return;
        }
        if (
          (updatedPreference[key] == null || updatedPreference[key] === "") &&
          updatedPreference.error[key]
        ) {
          updatedPreference.error[key] = true;
          return;
        }
      }
    });
    const tempPreference = [...preferences];
    tempPreference[indx] = updatedPreference;

    onChange(tempPreference);
  };

  return (
    <FormSection label={label}>
      {preferences.length === 0 ? (
        <RenderPreference
          preference={{}}
          index={0}
          removePreference={removePreference}
          programs={programs}
          addPreference={addPreference}
          handleChange={handleChange}
          campuses={campuses}
        />
      ) : (
        preferences.map((preference, index) => (
          <RenderPreference
            key={`prefrence-section-${index}`}
            preference={preference}
            index={index}
            programs={programs}
            removePreference={removePreference}
            addPreference={addPreference}
            handleChange={handleChange}
            campuses={campuses}
          />
        ))
      )}
    </FormSection>
  );
};

export default PreferencesSection;

const RenderPreference = ({
  preference,
  index,
  programs,
  handleChange,
  removePreference,
  addPreference,
  campuses = [],
}) => {
  const { error } = preference;
  const objects =
    parseInt(preference.Campus) > 0
      ? programs.filter((p) => {
        const campusIds = "," + p.campusIds + ",";
        if (
          campusIds.indexOf(",-1,") >= 0 ||
          campusIds.indexOf("," + preference.Campus + ",") >= 0
        ) {
          return p;
        }
      })
      : [];

  objects.forEach(
    (o) => (o.objectDetail = o.objectCode.trim() + " - " + o.objectName)
  );

  return (
    <div key={`preference-${index}`} className="col-span-3">
      <div className="flex items-center justify-between">
        <h3 className="font-medium">Program No. {index + 1}</h3>
        <RenderActionButtons
          index={index}
          program={preference.Program}
          addPreference={addPreference}
          removePreference={removePreference}
        />
      </div>
      <div className="grid md:grid-cols-3 sm:grid-cols-2 gap-x-6 gap-y-4 mt-2">
        <SelectBox
          dependentOn={true}
          name="Campus"
          disabled={campuses.length === 1}
          label="Campus"
          idField="campusId"
          valueField="campusDetail"
          error={error?.Campus}
          // onClick={() => setCurrentPreference(preference)}
          value={preference["Campus"]}
          onChange={(e, name) => handleChange(e, name, preference, index)}
          data={campuses}
        />
        <SelectBox
          dependentOn={true}
          disabled={objects.length === 1}
          error={error?.Program}
          name="Program"
          label="Program"
          // onClick={() => setCurrentPreference(preference)}
          value={preference["Program"]}
          onChange={(e, name) => handleChange(e, name, preference, index)}
          data={objects}
          idField="objectId"
          valueField="objectDetail"
        />
      </div>
    </div>
  );
};

const RenderActionButtons = ({ index, addPreference, removePreference }) => {
  return (
    <div className="mt-2">
      {index === 0 ? (
        <button
          type="button"
          onClick={addPreference}
          className="flex items-center px-4 py-1.5 font-medium text-white bg-blue-600 cursor-pointer shadow hover:bg-blue-500 rounded-md"
        >
          +
        </button>
      ) : (
        <button
          type="button"
          onClick={() => removePreference(index)}
          className="flex items-center px-4 py-1.5 font-medium text-white bg-blue-600 cursor-pointer shadow hover:bg-blue-500 rounded-md"
        >
          -
        </button>
      )}
    </div>
  );
};
