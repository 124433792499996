import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Axios from "axios";
import * as constants from "../constants";
import Documents from "../services/Documents";
import EndIcon from "../assets/icon/endIcon";
import StartIcon from "../assets/icon/startIcon";


const ProgramSummary = (props) => {
  const [activeTab, setActiveTab] = useState("1");
  let { id } = useParams();
  const [state, setState] = useState({
    courseId: undefined,
    enrolmentSummary: undefined,
    programDocuments: undefined,
    enrolmentDocuments: undefined,
    resourcesCardHeaderSettings: {
      headingText: "Resources",
      description: "available to download",
      objectType: 9,
    },
    documentsCardHeaderSettings: {
      headingText: "My Documents",
      description: "submitted so far",
      objectType: 13,
      objectId: id,
    },
  });

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  useEffect(() => {
    const fetchProgramSummary = async () => {
      try {
        const response = await Axios.get(
          constants.BASEURL + "Student/ProgramSummary/" + id
        );
        const enrolmentSummary = response.data;
        setState((prevState) => ({
          ...prevState,
          enrolmentSummary: enrolmentSummary,
          courseId: enrolmentSummary.courseId,
          documentsCardHeaderSettings: {
            ...prevState.documentsCardHeaderSettings,
            objectId: id,
          },
        }));
      } catch (error) {
        console.error("Error fetching program summary:", error);
      }
    };

    fetchProgramSummary();
  }, [id]);

  const renderProgramSummary = () => {
    const { enrolmentSummary } = state;

    if (!enrolmentSummary) {
      return "loading....";
    } else {
      return (
        <div>
          <div className="flex">
          <p className=" text-[1.115rem] font-semibold text-gray-400">Enrolment #</p>
          <p className="text-[1.125rem] font-medium text-[#111827]" >
            &nbsp;{enrolmentSummary.enrolmentNo}
          </p>
          </div>
          <div  className="">
            <div className="mt-1">
              <p className=" text-sm leading-6 text-gray-400">
                {enrolmentSummary.courseName}
              </p>
            </div>
            <dt>
              <p className="text-sm leading-6 text-gray-400">
                {enrolmentSummary.campusName} Campus
              </p>
            </dt>
            <dt className="flex mt-[10px]">
              <span className="text-gray-400">
                <StartIcon />
              </span>
              <p className="text-sm leading-6 text-gray-400">
                {enrolmentSummary.startDate}
              </p>
            </dt>
            <dt className="flex mt-[5px]">
                <span className="text-gray-400">
                  <EndIcon />
                </span>
              <p className=" text-sm leading-6 text-gray-400">
                {enrolmentSummary.endDate}
              </p>
            </dt>
            <dt className="mt-2">
              <p className="text-sm leading-6
               text-gray-400">
                {enrolmentSummary.studyWeeks} weeks of study
              </p>
            </dt>
          </div>
        </div>
      );
    }
  };

  const renderDocuments = (documentType) => {
    if (documentType === 1) {
      let optionSettings = state?.resourcesCardHeaderSettings;
      optionSettings.objectId = state?.courseId;
      return <Documents options={state?.resourcesCardHeaderSettings} />;
    } else {
      return <Documents options={state?.documentsCardHeaderSettings} />;
    }
  };
  return (
    <div className="p-[1rem]">
      <div className="bg-[#fff] overflow-hidden mb-[1rem] rounded-[0.375rem] shadow-[0 0 0.5rem rgba(0, 0, 0, 0.1)]">
        <div className="px-[1.25rem] py-[1.5rem]">
          <h3 className="text-[1.125rem] font-medium text-[#111827] m-0">
            Program Summary
          </h3>
        </div>
        <div className="px-[1rem] py-[1.5rem]">{renderProgramSummary()}</div>
      </div>
      {state.courseId && renderDocuments(1)}
      {id && renderDocuments(2)}
    </div>
  );
};

export default ProgramSummary;
