import React from "react";

function Avatar(props) {
  return (
      <img
        className={props.className}
        src={props?.url}
        alt=""
      />
  );
}

export default Avatar;
