import SelectBox from "../controls/SelectBox";

export const RenderObject = ({
  courses = {},
  index,
  courseType,
  campusObjects,
  handleChange,
}) => {
  const { error } = courses;
  let objects = [...campusObjects];

  if (parseInt(courses.Campus) > 0) {
    objects = objects.filter((p) => {
      let campusIds = "," + p.campusIds + ",";
      if (
        campusIds.indexOf(",-1,") >= 0 ||
        campusIds.indexOf("," + courses.Campus + ",") >= 0
      )
        return p;
    });
  } else {
    objects = [];
  }

  objects.map(
    (o) => (o.objectDetail = o.objectCode.trim() + " - " + o.objectName)
  );

  if (courseType === 9) {
    if (objects.length === 1) courses["Program"] = objects[0]?.objectId;

    return (
      <SelectBox
        key={"program" + index}
        id={index + "Program"}
        disabled={objects.length == 1}
        error={error?.Program}
        name={"Program"}
        label="Program*"
        onChange={handleChange}
        data={objects}
        idField={"objectId"}
        valueField={"objectDetail"}
        multiple={index}
        value={courses["Program"]}
        dependentOn={true}
      />
    );
  } else if (courseType === 22) {
    if (objects.length == 1) courses["Subject"] = objects[0]?.objectId;
    return (
      <SelectBox
        key={"subjects" + index}
        id={index + "Subject"}
        multiple={index}
        disabled={objects.length == 1}
        error={error.Subject}
        name="Subject"
        label="Subjects"
        onChange={handleChange}
        data={objects}
        idField="objectId"
        valueField="objectDetail"
        value={courses[index]?.Subject}
        dependentOn={true}
      />
    );
  } else if (courseType == 23) {
    if (objects.length == 1) courses.UOS = objects[0].objectId;
    return (
      <SelectBox
        key={"UOS" + index}
        id={index + "UOS"}
        multiple={index}
        disabled={objects.length == 1}
        error={error.UOS}
        name="UOS"
        label="UOS"
        onChange={handleChange}
        data={objects}
        idField="objectId"
        valueField="objectDetail"
        value={courses[index]?.UOS}
        dependentOn={true}
      />
    );
  }
};
