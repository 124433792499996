import React, { useEffect, useState } from "react";
import Axios from "axios";
import * as constants from "../constants";
import Documents from "../services/Documents";
import Fileupload from "./common/FileUpload";
import Assessments from "./Assessments";
import { useLocation, useParams } from "react-router";
import queryString from "query-string";
import EndIcon from "../assets/icon/endIcon";
import StartIcon from "../assets/icon/startIcon";

const SubjectSummary = () => {
  const { id } = useParams();
  const location = useLocation();
  const { programId } = queryString.parse(location.search);

  const [state, setState] = useState({
    buttonList: undefined,
    programId: programId,
    subjectId: id,
    subjectSummary: undefined,
    programDocuments: undefined,
    enrolmentDocuments: undefined,
    resourcesCardHeaderSettings: {
      headingText: "Resources",
      description: "available to download",
      objectType: 22,
    },
    cardHeaderSettings: {
      headingText: "My Assessments",
      description: "To be submitted to Trainer",
    },
  });

  useEffect(() => {
    Axios.get(
      constants.BASEURL +
        "Student/SubjectUOSSummary/" +
        id +
        "?objectType=subject&programId=" +
        state?.programId
    ).then((response) => {
      let subjectSummary = response.data;
      setState((pre) => ({
        ...pre,
        subjectSummary: subjectSummary,
        programId: subjectSummary.enrolmentId,
      }));
    });
  }, [id, state?.programId]);

  const renderSubjectSummary = () => {
    if (!state?.subjectSummary) {
      return "loading....";
    } else {
      let startText = "";
      let finishText = "";
      if (state?.subjectSummary.startDate) {
        startText = state?.subjectSummary.startDate;
      }
      if (state?.subjectSummary.endDate) {
        finishText = state?.subjectSummary.endDate;
      }
      return (
        <>
          <span className="text-[1.125rem] font-bold text-[#6B7280]">
            Code
          </span>
          <span className="text-[1.125rem] font-normal text-[#111827]"
           
          >
            &nbsp;{state?.subjectSummary.subjectCode}
          </span>
          <dt className="mt-[1.5rem] space-y-3">
            <p className="text-[1rem] text-[#6B7280]">
              {state?.subjectSummary.subjectName}
            </p>
            {startText && (
              <p className="flex items-center gap-[0.5rem] text-[1rem] text-[#6B7280]" >
                {" "}
                <StartIcon /> {startText}
              </p>
            )}
            {finishText && (
              <p className="flex items-center gap-[0.5rem] text-[1rem] text-[#6B7280]" >
                <EndIcon /> {finishText}
              </p>
            )}
          </dt>
        </>
      );
    }
  };

  const renderDocuments = (documentType) => {
    if (documentType === 1) {
      let optionSettings = state?.resourcesCardHeaderSettings;
      optionSettings.objectId = state?.subjectId;
      return <Documents options={state?.resourcesCardHeaderSettings} />;
    } else {
      return <Fileupload />;
    }
  };

  return (
    <div className="sm:mt-4 sm:p-4">
      <div className="bg-white overflow-hidden shadow rounded-md mb-4 p-4">
        <div className="border-b border-gray-200">
          <h3 className="text-[1.125rem] font-medium text-[#111827] py-4">
            Subject Summary
          </h3>
        </div>
        <div className="mt-[10px]">{renderSubjectSummary()}</div>
      </div>

      {/* Resources Section */}
      {state?.subjectId && renderDocuments(1)}

      {/* My Assessments */}
      <Assessments
        cardHeaderSettings={state?.cardHeaderSettings}
        listData={state?.buttonList}
        objectId={id}
        objectType={1}
        programId={state?.programId}
      />
    </div>
  );
};

export default SubjectSummary;
