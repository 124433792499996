import React, { useState, useEffect } from "react";
import FormLabel from "./FormLabel";
// import "moment/locale/en-gb";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function DateBox({
  dependentOn,
  disabled = false,
  error,
  name,
  label,
  minDate,
  maxDate,
  onChange,
  value = null,
}) {
  // const [selectedDate, setSelectedDate] = useState(null);

  // useEffect(() => {
  //   if (
  //     value &&
  //     moment(selectedDate).format("DD/MMM/YYYY") !==
  //       moment(value).format("DD/MMM/YYYY")
  //   ) {
  //     setSelectedDate(moment(value, "DD/MMM/YYYY"));
  //   }
  // }, [value]);

  let selectedDate = undefined;
  // debugger;
  if (value) selectedDate = value;
  // if(value) selectedDate = typeof value == "string" ? moment(value) : {...value};
  // console.log("DateBOX:",selectedDate);

  const handleDateChange = (event, dateString, id) => {
    // setSelectedDate(event);
    // const formatDate = moment(event).tz("Australia/Sydney").toISOString();

    onChange(event, name);
  };

  const handleDisabledDate = (current) => {
    if (minDate) {
      return current && current < moment(minDate).startOf("day");
    }
    if (maxDate) {
      return current && current > moment(maxDate).startOf("day");
    }
  };
  return (
    <div
      // xs={24}
      // sm={24}
      // md={8}
      // lg={8}
      // xl={8}
      style={{ display: !dependentOn ? "none" : "inherit" }}
    >
      <div style={{ width: "100%" }}>
        <FormLabel
          text={label}
          name={name}
          color={error ? "red" : "black"}
          error={error}
          errorText={`${label} must be selected.`}
        >
          <ReactDatePicker
            utcOffset={0}
            id={name}
            selected={value}
            onChange={(val) => {
              handleDateChange(val)
            }}
            dateFormat="dd/MMM/yyyy"
            placeholderText="dd/mm/yyyy"
            showYearDropdown
            yearDropdownItemNumber={60}
            scrollableYearDropdown 
            minDate={minDate}
            maxDate={maxDate}                   
            showMonthDropdown
            disabled={disabled}
            className={`w-full font-normal px-3 py-2.5 rounded-lg focus:outline-none read-only:bg-gray-100 text-black border ${error ? "border-red-400 focus-within:border-red-400" : "focus-within:border-blue-400"}`}
          />

          {/* <DatePicker
            id={name}
            className="custom-datepicker"
            value={selectedDate}
            onChange={handleDateChange}
            format="DD/MMM/YYYY"
            placeholder="DD/MMM/YYYY"
            status={error && "error"}
            size="large"
            disabledDate={handleDisabledDate}
            disabled={disabled}
            renderExtraFooter={() =>
              error && (
                <div className="ant-form-item-explain ant-form-item-explain-error">
                  {label} must be selected.
                </div>
              )
            }
          ></DatePicker> */}
        </FormLabel>
      </div>
    </div>
  );
}
export default React.memo(DateBox);
