import React, { useEffect, useState } from "react";
import axios from "axios";
import * as constants from "../constants";
import WideList from "../components/common/WideList";
import * as images from "../ImageConstants";
import { LuDownload } from "react-icons/lu";
const Documents = (props) => {
  const { description, headingText } = props.options;
  const [state, setState] = useState({
    cardHeaderSettings: {
      headingText: headingText,
      description: description,
    },
    listData: undefined,
  });

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const response = await axios.get(
          constants.BASEURL +
            "Document/Objects/" +
            props?.options?.objectId +
            "?objectType=" +
            props?.options?.objectType
        );
        parseDocuments(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchDocuments();
  }, [props?.options?.objectId, props?.options?.objectType]);

  const parseDocIcon = (docName) => {
    let docArr = docName.split(".");
    let docExt = docArr[docArr.length - 1];
    switch (docExt.toUpperCase()) {
      case "DOC":
      case "DOCX":
        return images.DOCICON;
      case "PNG":
      case "JPG":
      case "JPEG":
      case "SVG":
      case "PNG":
      case "BMP":
      case "GIF":
        return images.PHOTOICON;
      case "PDF":
        return images.PDFICON;
      default:
        return null;
    }
  };

  const parseDocuments = (listItem) => {
    const newListData = listItem.map((item) => {
      item.controlId = item.documentId;
      item.headingText = item.docName;
      item.linkTarget = item.downloadLink;
      item.svgIcon = <LuDownload />;
      //item.rightLabel = item.courseType;
      let itemsList = [];

      if (item.details !== "") {
        let startText = "Details ";
        itemsList.push({
          itemText: startText + item.details,
        });
      }

      if (item.docSize !== "") {
        itemsList.push({
          itemText: "Size " + item.docSize,
        });
      }

      itemsList.push({
        icon: "",
        itemText: "Uploaded on " + item.uploadDate,
      });

      item.itemList = itemsList;

      return item;
    });

    setState((prevState) => ({
      ...prevState,
      listData: newListData,
    }));
  };

  if (!state.listData?.length) {
    return null;
  }

  return (
    <div className="bg-white overflow-hidden rounded-md shadow mb-4 p-4">
      <WideList
        key={props?.objectType}
        cardHeaderSettings={state?.cardHeaderSettings}
        listData={state?.listData}
      />
    </div>
  );
};

export default Documents;
