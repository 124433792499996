import React from "react";

function FormLabel({
  color = "gray",
  name,
  text,
  children,
  error,
  errorText = `${text} must be selected.`,
  style = {},
}) {
  return (
    <div className="w-full block text-[0.875rem] font-semibold leading-[1.25rem]"
      style={{
        color: color,
        marginBottom: 0,
        ...style,
      }}
      // validateStatus={error ? "error" : ""}
    >
      <label htmlFor={name} className="text-gray-800">{text}</label>

      <div className="mt-4">
        {children}
      </div>

      <div className="mt-2 text-xs">
        {error && errorText}
      </div>

    </div>
    // <Form.Item
    //   htmlFor={name}
    //   label={text}
    //   style={{
    //     width: "100%",
    //     display: "block",
    //     fontSize: "0.875rem",
    //     fontWeight: "500",
    //     lineHeight: "1.25rem",
    //     color: color,
    //     marginBottom: 0,
    //     ...style,
    //   }}
    //   validateStatus={error ? "error" : ""}
    //   help={error && errorText}
    // >
    //   {children}
    // </Form.Item>
  );
}

export default FormLabel;
