import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import EnrolmentDetail from "./components/EnrolmentDetail";
import Program from "./components/Program";
import Login from "./components/Login";
import NotFound from "./components/NotFound";
import LmsPage from "./components/LmsPage";
import ProgramSummary from "./components/ProgramSummary";
import ProgramSubjects from "./components/ProgramSubjects";
import ProgramUOS from "./components/ProgramUOS";
import ProgramFee from "./components/ProgramFee";
import SubjectDetail from "./components/SubjectDetail";

import SubjectSummary from "./components/SubjectSummary";
import UOSSummary from "./components/UOSSummary";
import MakeARequest from "./components/MakeARequest";
import UOSDetail from "./components/UOSDetail";
import WebForm from "./components/WebForm";
import Alerts from "./components/Alerts";
import UserProfile from "./components/UserProfile";
import ChangePassword from "./components/ChangePassword";
import UOSSubjects from "./components/UOSSubjects";
export const UOSDetailRoutes = () => (
  <div>
    <Routes>
      <Route path="/summary" element={<UOSSummary />} />
      <Route path="/subjects" element={<UOSSubjects />} />
      <Route path="outline" element={<LmsPage />} />
      <Route exact path="/" element={<UOSSummary />} />
    </Routes>
  </div>
);
export const SubjectDetailRoutes = () => (
  <div>
    <Routes>
      <Route exact path="/summary" element={<SubjectSummary />} />
      <Route path="/outline" element={<LmsPage />} />
      <Route exact path="/" element={<SubjectSummary />} />
    </Routes>
  </div>
);

export const ProgramDetailRoutes = () => (
  <div>
    <Routes>
      <Route path="/summary" element={<ProgramSummary />} />
      <Route path="/outline" element={<LmsPage />} />
      <Route path="/subjects" element={<ProgramSubjects />} />
      <Route path="/uos" element={<ProgramUOS />} />
      <Route path="/feedetail" element={<ProgramFee />} />
      <Route exact path="/" element={<ProgramSummary />} />
    </Routes>
  </div>
);

export const DashboardRoutes = () => (
  <div className="sm:px-10">
    <Routes>
      <Route path="/programs/:id/*" element={<EnrolmentDetail />} />
      <Route path="/subjects/:id/*" element={<SubjectDetail />} />
      <Route path="/uos/:id/*" element={<UOSDetail />} />
      <Route exact path="/programs" element={<Program />} />
      <Route exact path="/" element={<Program />} />
      <Route path="/makeARequest" element={<MakeARequest />} />
      <Route path="/alerts" element={<Alerts />} />
      <Route path="/profile" element={<UserProfile />} />
      <Route path="/changepassword" element={<ChangePassword />} />
    </Routes>
  </div>
);

export const AppRoutes = () => (
  <Routes>
    <Route
      path="/webform/:id/application/:applicationId/quiz/:quizId"
      element={<WebForm />}
    />
    <Route path="/webform/:id" element={<WebForm />} />

    <Route path="/login" element={<Login />} />
    <Route path="/dashboard/*" element={<Dashboard />} />
    <Route path="/" element={<Login />} />
    <Route path="*" element={<NotFound />} />
  </Routes>
);
