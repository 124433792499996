import React from "react";
import { useNavigate } from "react-router-dom";
import { Menu as HeadLessMenu } from '@headlessui/react'
import Avatar from "./Avatar";
import { toast } from "react-toastify";

const Menu = ({ isOpen, props, handleClick }) => {
  const studentName = localStorage.getItem("studentName");

  // useNavigation hook
  const navigate = useNavigate();

  // const items = [
  //   {
  //     label: studentName,
  //     key: "1",
  //     disabled: true,
  //     // style: {
  //     //   backgroundColor: "#f3f4f6",
  //     //   fontWeight: 500,
  //     //   fontSize: "1.1rem",
  //     //   color: "black",
  //     //   cursor: "pointer",
  //     // },
  //   },
  //   {
  //     label: "My Profile",
  //     key: "2",
      
  //   },
  //   {
  //     label: "Change Password",
  //     key: "4",
  //   },
  //   {
  //     label: "Sign out",
  //     key: "3",
  //   },
  // ];

  // const handleMenuClick = (e) => {
  //   const { key } = e;
  //   handleClick();
  //   if (key === "1") {
  //     return;
  //   } else if (key === "2") {
  //     navigate("/dashboard/profile");
  //   } else if (key === "3") {
  //     localStorage.clear();
  //     navigate("/");
  //     message.success("Logged Out Successfully");
  //   }
  //   else if (key==="4"){
  //     navigate("/dashboard/changepassword");
  //   }
  // };

  // const menuProps = {
  //   items,
  //   onClick: handleMenuClick,
  //   style: {
  //     padding: "0.5rem .2rem",
  //     width: "12rem",
  //     backgroundColor: "#f7fafc",
  //     fontSize: "1rem",
  //     fontWeight: "500",
  //     color: "#2d3748",
  //     lineHeight: "40px",
  //   },
  // };

  return (

    <HeadLessMenu  open={isOpen} className="relative inline-block text-left">
      <div>
        <HeadLessMenu.Button className="block pt-2">
        <Avatar className='w-10 rounded-full  ' url={`https://ui-avatars.com/api/name=${studentName}/?background=random`}/>
          </HeadLessMenu.Button>
          <HeadLessMenu.Items className="absolute z-10 right-0 mt-2 w-[170px] origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-md ring-1 ring-black/5 focus:outline-none">
              
            {/* <div className="sm_visible" onClick={handleClick}>
              {!isOpen ? (
                <MenuOutlined style={{ fontSize: "1.5rem" }} />
              ) : (
                <CloseOutlined style={{ fontSize: "1.5rem" }} />
              )}
            </div> */}

        
            <div className="px-1 py-1">
              <HeadLessMenu.Item>
                <button className="px-2 py-1 bg-gray-100 text-gray-600 w-full font-medium text-left" disabled>{studentName}</button>
              </HeadLessMenu.Item>
              <HeadLessMenu.Item>
                <button className="px-2 py-1.5 w-full hover:bg-gray-100 text-left focus:outline-none focus:bg-gray-50 text-sm font-extralight" onClick={()=>{
                navigate("/dashboard/profile")
                }}>My Profile</button>
              </HeadLessMenu.Item>
              <HeadLessMenu.Item>
                <button className="px-2 py-1.5 w-full hover:bg-gray-100 focus:outline-none text-left focus:bg-gray-50 text-sm font-extralight" onClick={()=>{
                  navigate("/dashboard/changepassword");
                }}>Change Password</button>
              </HeadLessMenu.Item>
              <HeadLessMenu.Item>
                <button className="px-2 py-1.5 w-full text-left hover:bg-gray-100 focus:outline-none focus:bg-gray-50 text-sm font-extralight" onClick={()=>{
                  localStorage.clear();
                  navigate("/");
                  toast.success("Logged Out Successfully");
                }}>Sign out</button>
              </HeadLessMenu.Item>
            </div>
          </HeadLessMenu.Items>
      </div>
    </HeadLessMenu>
  );
};

export default Menu;
