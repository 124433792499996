import React, { useState } from "react";
import NavBar from "./common/NavBar";
import { DashboardRoutes } from "../routes";

const Dashboard = () => {
  const [avatarurl, setAvatarurl] = useState(localStorage.getItem("avatarurl"));
  return (
    <div  className="min-h-[100vh] bg-white">
      <NavBar avatarurl={avatarurl} />
      <header></header>
      <main>
        <DashboardRoutes />
      </main>
    </div>
  );
};

export default Dashboard;
