import React, { useState, useEffect } from "react";
import SelectBox from "../controls/SelectBox";
import FormSection from "../controls/FormSection";
import TextBox from "../controls/TextBox";

const blankContact = {
  FName: null,
  LName: null,
  MemberShipEducation: null,
  Position: null,
  QualificationExperience: null,
  error: {},
};

const ContactSection = (props) => {
  const [hideActionButton, setHideActionButton] = useState(false);
  const [currentContact, setCurrentContact] = useState({});
  const [contacts, setContacts] = useState([]);
  const { contactFields, onChange } = props;
  useEffect(() => {
    if (contacts.length === 0) {
      addContact();
    }
  }, []);

  const addContact = () => {
    const cContact = { ...blankContact };
    cContact.error = {};
    const updatedContacts = [...contacts, cContact];

    setContacts(updatedContacts);
    onChange(updatedContacts);
  };

  const removeContact = (index) => {
    const updatedContacts = [...contacts];
    updatedContacts.splice(index, 1);

    setContacts(updatedContacts);
    props.onChange(updatedContacts);
  };

  const renderActionButtons = (index) => {
    return (
      <div className="mt-2">
        <button
          type="button"
          onClick={index === 0 ? addContact : () => removeContact(index)}
          className="flex items-center px-4 py-1.5 font-medium text-white bg-blue-600 cursor-pointer shadow hover:bg-blue-500 rounded-md"
        >
          {index === 0 ? "+" : "-"}
        </button>
      </div>
    );
  };

  const handleChange = (e, name) => {
    let updatedContact = { ...currentContact };

    if (name) {
      updatedContact[name] = e;
    } else {
      updatedContact[e.currentTarget.name] = e.currentTarget.value;
    }

    const keys = Object.keys(updatedContact);
    keys.forEach((key) => {
      if (key !== "error") {
        if (
          (updatedContact[key] === null || updatedContact[key] === "") &&
          updatedContact.error[key] === undefined
        ) {
          return;
        }

        if (
          (updatedContact[key] === null || updatedContact[key] === "") &&
          updatedContact.error[key]
        ) {
          updatedContact.error[key] = true;
          return;
        }
      }
    });

    setCurrentContact(updatedContact);
    onChange(contacts);
  };

  const renderContactFields = (cfield, index, contact) => {
    const { error } = contact;
    const { dropDowns: DropDowns } = props;
    const handleContactFieldClick = () => {
      setCurrentContact(contact);
    };
    if (cfield.fieldType === 1) {
      return (
        <TextBox
          name={cfield.fieldName}
          dependentOn={true}
          error={error[cfield.fieldName]}
          label={cfield.fieldLabel}
          onClick={handleContactFieldClick}
          value={contact[cfield.fieldName]}
          onChange={handleChange}
        />
      );
    } else {
      return (
        <SelectBox
          dependentOn={true}
          name={cfield.fieldName}
          error={error[cfield.fieldName]}
          onClick={handleContactFieldClick}
          label={cfield.fieldLabel + (cfield.isMandatory ? "*" : "")}
          value={contact[cfield.fieldName]}
          onChange={handleChange}
          data={DropDowns[cfield.fieldName]}
        />
      );
    }
  };

  const renderContact = (contact, index) => {
    return (
      <>
        <div className={`col-span-3 ${hideActionButton ? "hidden" : ""}`}>
          <div className="flex items-center justify-between">
            <h3 className="font-medium">Person {index + 1}</h3>
            {renderActionButtons(index)}
          </div>
        </div>
        <div className="col-span-3">
          <div className="grid md:grid-cols-3 sm:grid-cols-2 gap-x-6 gap-y-4 mt-2">
            {contactFields.map((cfield, index) =>
              renderContactFields(cfield, index, contact)
            )}
          </div>
        </div>
      </>
    );
  };

  return (
    <FormSection label={props.label}>
      {contacts.length === 0
        ? renderContact({ FName: null, LName: null }, 0)
        : contacts.map((contact, index) => renderContact(contact, index))}
    </FormSection>
  );
};

export default ContactSection;
