export function removeDecimalIfZero(inputString) {
  if (inputString.endsWith(".00")) {
    return inputString.slice(0, -3); // Remove the last 3 characters (".00")
  } else {
    return inputString; // No change needed
  }
}

export function formatCurrencySign(number) {
  // Use toFixed(2) to round the number to two decimal places
  // Use replace to add commas for thousands
  return "$" + number.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
