import React from "react";
import parse from "html-react-parser";
const CardHeader = ({
  expanded = false,
  settings,
  onClick,
  ActionComponent,
}) => {
  const renderButtons = () => {
    return (
      <>
        <div
          style={{ marginLeft: "1rem", marginTop: "1rem", cursor: "pointer" }}
          onClick={onClick}
        >
          <span
            style={{
              marginRight: "0.5rem",
              height: "1.75rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            <svg
              style={{
                transform: `rotate(${expanded ? "-180deg" : "0deg"})`,
                height: "1rem",
                width: "1rem",
              }}
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </span>
        </div>
        {ActionComponent}
      </>
    );
  };

  return (
    <div className="flex justify-between items-center flex-wrap bg-white pb-5 pt-3">
      <div className="mt-[1rem] ml-[1rem]">
        <h3 className="text-lg font-medium text-[#1F2937]">
          {settings?.headingText}
        </h3>
        <p className="mt-1 text-sm text-gray-500">{parse(settings?.description || "")}</p>
      </div>
      {onClick && renderButtons(settings?.button)}
    </div>
  );
};

export default CardHeader;
