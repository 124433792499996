import React from "react";
import parse from "html-react-parser";
import axios from "axios";
import { BASEURL } from "../constants";
import { useState } from "react";



const PageBlock = ({ blockContent }) => {
  const [loading, setLoading] = useState(false);

  const getSingleDoc = async (documentId) => {
    setLoading(true);
    try {
      const res = await axios.get(BASEURL + "Document/SingleDoc/" + documentId);
      if (res.data?.length === 0) {
        return alert("No document found");
      }
      window.open(res.data[0].downloadLink, "_blank");
    } catch (error) {
      alert("Unable to get the document");
    } finally {
        setLoading(false);
    }
  };

  const renderPageBlock = (blockContent) => {
    switch (parseInt(blockContent.typeOfBlock)) {
      case 1:
        return (
          <h4 className=" text-2xl font-medium">{blockContent.blockSubTitle}</h4>
        );
      case 2:
        return (
          <div className="bg-[#FFFFFF] rounded-[0.375rem] p-[1rem] flex flex-col justify-between w-full grow-1">
            <h4 className="text-2xl pt-[1rem] font-medium">{parse(blockContent.blockDescription)}</h4>
            <p className=" pt-[1rem] font-light text-gray-500">{parse(blockContent.contentTextOfHtml)}</p>
          </div>
        );
      // case 3:
      //   return (
      //     <div
      //       style={{
      //         // border: "1px solid #CBD5E0",
      //         backgroundColor: "#FFFFFF",
      //         borderRadius: "0.375rem",
      //         padding: "1rem",
      //         display: "flex",
      //         flexGrow: 1,
      //         width: "100%",
      //         flexDirection: "column",
      //         justifyContent: "space-between",
      //       }}
      //     >
      //       <Title level={4} strong>
      //         Student Declaration
      //       </Title>
      //       <Text type="secondary">{blockContent.blockDescription}</Text>
      //       <Checkbox>I agree</Checkbox>
      //       <div style={{ textAlign: "center" }}>
      //         <Button type="primary">Submit</Button>
      //       </div>
      //     </div>
      //   );
      case 4:
        return (
          <div className="bg-[#FFFFFF] rounded-[0.375rem] p-[1rem] flex flex-col justify-between w-full grow-1">
            <h4 className=" text-2xl font-medium">{blockContent.blockSubTitle}</h4>
            
            <p className=" pt-[1rem] font-light text-gray-500">{blockContent.blockDescription}</p>
            
            {parseInt(blockContent.documentId) > 0 ? (
              <p>
                {loading ? (
                  <span className="text-gray-200 text-[15px] ">Fetching doc...</span>
                ) : (
                  <a
                    href="#" className="text-[#3B82F6]"
                    onClick={() => getSingleDoc(blockContent.documentId)}
                  >
                    {blockContent.blockName}
                  </a>

                )}
              </p>
            ) : (
              <p className="text-[#3B82F6]">
                <a target="_blank" href={blockContent.externalLink}>
                  {blockContent.blockName}
                </a>
              </p>
            )}
          </div>
        );
      case 5:
        return (
          <div className="bg-[#FFFFFF] rounded-[0.375rem] p-[1rem] flex flex-col justify-between w-full grow-1"
            
          >
            <h4 className=" text-2xl font-medium">{blockContent.blockSubTitle}</h4>
            <p className=" pt-[1rem] font-light text-gray-500"> {parse(blockContent.blockDescription)}</p>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="flex mb-[1rem] w-full">
      {renderPageBlock(blockContent)}
    </div>
  );
};

export default PageBlock;
