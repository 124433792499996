import React, { useEffect, useState } from "react";
import axios from "axios";
import * as constants from "../constants";
import WideList from "./common/WideList";
import { useParams } from "react-router-dom";

const ProgramUOS = (props) => {
  let { id } = useParams();
  const [cardHeaderSettings, setCardHeaderSettings] = useState({
    headingText: "Units of Study",
    description: "",
  });
  const [listData, setListData] = useState(undefined);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          constants.BASEURL + "Student/UOS/" + id
        );
        parseUOS(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [id]);

  const parseUOS = (listItem) => {
    const updatedListData = listItem.map((item) => {
      item.controlId = item.enrolmentModuleId;
      item.headingText = item.subjectCode + " - " + item.subjectName;
      item.linkTarget =
        "../../uos/" +
        item.subjectId +
        "/summary?programId=" +
        item.enrolmentId;

      let itemsList = [];

      if (item.startDate) {
        var subItem = {};
        subItem.icon =
          "M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z";
        let startText = "Start Date ";
        subItem.itemText = startText + item.startDate;
        itemsList.push(subItem);
      }

      if (item.endDate) {
        subItem = {};
        subItem.icon =
          "M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z";
        subItem.itemText = "End Date " + item.endDate;
        itemsList.push(subItem);
      }

      subItem = {};
      subItem.icon = "";
      subItem.itemText = item.subjectType;
      itemsList.push(subItem);

      subItem = {};
      subItem.icon = "";
      subItem.itemText = "Compulsory ";
      itemsList.push(subItem);

      item.itemList = itemsList;

      return item;
    });

    setListData(updatedListData);
  };

  if (!listData) {
    return "loading....";
  }

  return (
    <React.Fragment>
      <WideList
        key={props.programType}
        cardHeaderSettings={cardHeaderSettings}
        listData={listData}
      ></WideList>
    </React.Fragment>
  );
};

export default ProgramUOS;
