import React from "react";
import { useState } from "react";
import useDebounce from "../../hooks/useDebounce";
import { useRef } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import FormLabel from "./FormLabel";
import Icon from "../common/Icon";
import { IoIosInformationCircleOutline } from "react-icons/io";


const TextBox = ({
  dependentOn,
  error,
  label,
  name,
  value,
  onChange,
  onClick,
  onBlur,
  readOnly,
  type,
  fullwidth = false,
  id = null,
}) => {
  // const [inputValue, setInputValue] = useState("");
  // const inputElem = useRef(null);
  // const { debounce } = useDebounce();

  // useEffect(() => {
  //   if (value && inputValue !== value) {
  //     setInputValue(value);
  //   }
  // }, [value]);
  // const handleInputChange = useCallback((e) => {
  //   setInputValue(e.target.value);

  //   debounceSearch(e);
  // }, []);
  // const debounceSearch = debounce((e) => {
  //   onChange(e, undefined);
  // }, 1000);
  return (
    <div
      // xs={24}
      // sm={24}
      // md={fullwidth ? 24 : 8}
      // lg={fullwidth ? 24 : 8}
      // xl={fullwidth ? 24 : 8}
      style={{ display: !dependentOn ? "none" : "inherit" }}
    >
      <FormLabel
        text={label}
        name={name}
        color={error ? "red" : "black"}
        error={error}
        errorText={`${label} must be entered.`}
      >
        <div className={`flex items-center border rounded-lg  ${error ? "border-red-400 focus-within:border-red-400" : "focus-within:border-blue-400"}`}>
          <input
            type={!type ? "text" : type}
            id={id || `input-${label ? label : name}`}
            name={name}
            onClick={onClick}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            readOnly={readOnly}
            className={`w-full font-normal px-3 py-2.5 rounded-lg focus:outline-none read-only:bg-gray-100 text-black`}
          />
          {error && <IoIosInformationCircleOutline className="w-5 h-5 mr-2" /> }
        </div>


        {/* <Input
          //  ref={inputElem}
          type={!type ? "text" : type}
          id={id || `input-${label ? label : name}`}
          name={name}
          style={{ width: "100%" }}
          size={"large"}
          onClick={onClick}
          onChange={onChange}
          status={error && "error"}
          onBlur={onBlur}
          value={value}
          readOnly={readOnly}
          suffix={
            error && <ExclamationCircleOutlined style={{ color: "#EF4444" }} />
          }
        /> */}
      </FormLabel>
    </div>
  );
};

export default React.memo(TextBox);
