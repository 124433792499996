import React, { useState } from "react";
import CardHeader from "../common/CardHeader";

export default function FormSection({
  ActionComponent,
  label,
  subTitle,
  children,
}) {
  const [expanded, setExpanded] = useState(true);
  if (label == "VET Student Loans")
    subTitle = `<span style={{color:'black'}}>Go to this link <a target='_blank' style={{textDecoration: 'underline', color: '#3B82F6', '&:hover': { color: '#1D4ED8'},'&:visited': {color: '#9333EA'}}} href='https://icanlearn.edu.au/vet-student-loans-information'>https://icanlearn.edu.au/vet-student-loans-information/</a> and read all information about VET Student Loans.</span>`;
  return (
    <div className="px-4 mb-4 shadow-md shadow-gray-100 rounded-lg overflow-hidden border border-gray-50">
      <CardHeader
        ActionComponent={ActionComponent}
        onClick={() => {
          setExpanded(!expanded);
        }}
        expanded={expanded}
        settings={{ headingText: label, description: subTitle }}
      />

      <div className={`py-4 ${expanded ? "" : "hidden"}`}>
        <div
          // gutter={[16, 16]}
          className="px-4 grid sm:grid-cols-2 md:grid-cols-3 gap-x-6 gap-y-4"
          // style={{ marginLeft: 0, marginRight: 0 }}
        >
          {children}
        </div>
      </div>
    </div>
  );
}
