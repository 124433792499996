import React, { useEffect, useState } from "react";
import axios from "axios";
import * as constants from "../constants";
import WideList from "./common/WideList";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import StartIcon from "../assets/icon/startIcon";
import EndIcon from "../assets/icon/endIcon";
import queryString from "query-string";
import moment from "moment";

const subTabs = ["current", "past", "future"];

const ProgramSubjects = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { subTab } = queryString.parse(location?.search);
  const [subjectType, setSubjectType] = useState(subTab);

  const { id } = useParams();
  const [cardHeaderSettings, setCardHeaderSettings] = useState({
    headingText: "Subjects",
    description: "",
  });
  const [listData, setListData] = useState(undefined);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const config = { headers: { "Content-Type": "application/json" } };
      const studentId = 1;
      try {
        const response = await axios.get(
          constants.BASEURL + "Student/Subjects/" + id
        );
        parseSubjects(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [id]);

  const parseSubjects = (listItem) => {
    const newListData = listItem.map((item) => {
      item.controlId = item.enrolmentModuleId;
      item.headingText = item.subjectCode + " - " + item.subjectName;
      item.linkTarget = item.linkTarget =
        item.invoiceAmountDue > 0
          ? ""
          : "../../subjects/" +
            item.subjectId +
            "/summary?programId=" +
            item.enrolmentId;

      let itemsList = [];

      if (item.startDate) {
        const subItem = {
          iconComp: <StartIcon />,
          itemText: `${item.startDate}`,
        };
        itemsList.push(subItem);
      }

      if (item.endDate) {
        const subItem = {
          iconComp: <EndIcon />,
          itemText: `${item.endDate}`,
        };
        itemsList.push(subItem);
      }

      const subItem = {
        icon: "",
        itemText: item.subjectType,
      };
      itemsList.push(subItem);

      if (item.compulsory) {
        const subItem = {
          icon: "",
          itemText: "Compulsory",
        };
        itemsList.push(subItem);
      }

      if (item.result !== "") {
        const subItem = {
          icon: "",
          itemText: `Result - ${item.result}`,
        };
        itemsList.push(subItem);
      }

      if (item.resultDate !== "") {
        const subItem = {
          icon: "M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z",
          itemText: `Result Date ${item.resultDate}`,
        };
        itemsList.push(subItem);
      }

      if (parseInt(item.totalAssessments) > 0) {
        const subItem = {
          icon: "",
          itemText: `Total Assessments ${item.totalAssessments}`,
        };
        itemsList.push(subItem);

        if (parseInt(item.totalAssessmentsAttempted) > 0) {
          const subItem = {
            icon: "",
            itemText: `Attempted ${item.totalAssessmentsAttempted}`,
          };
          itemsList.push(subItem);
        }

        if (parseInt(item.totalAssessmentsPending) > 0) {
          const subItem = {
            icon: "",
            itemText: `Pending ${item.totalAssessmentsPending}`,
          };
          itemsList.push(subItem);
        }
      }

      item.itemList = itemsList;
      return item;
    });

    setListData(newListData);
    setFilteredData(newListData);
    filterTabData(newListData,subjectType)
  };

  
  // Filtering data acc. to current, past and future
  const filterTabData = (data, type) => {
    const today = moment();

    let returnData = data.filter((item) => {
      const startDate = moment(item.startDate, 'DD/MM/YYYY'); 
      const endDate = moment(item.endDate, 'DD/MM/YYYY'); 

      switch (type) {
        // if there is no date for a subject then putting it into the current tab
        case "current":
          return !item.startDate || !item.endDate || (startDate.isSameOrBefore(today, "day") && endDate.isSameOrAfter(today, "day"));
        case "past":
          return endDate.isBefore(today, "day");
        case "future":
          return startDate.isAfter(today, "day");
      }
    })
  
    setFilteredData(returnData);
  };
  
  

  // updating the subTab in the url
  // done to highlight the last active tab after page reload
  const handleClick = (tabname) => {
    filterTabData(listData, tabname)
    setSubjectType(tabname)
    let url = queryString.parse(location?.search)
    url.subTab = tabname;
    navigate(location.pathname+ "?" +queryString.stringify(url))
  }

  if (!listData) {
    return "loading....";
  }

  return (
    <div className="p-4">
      <ul className="flex items-center gap-4 mb-4">
        {subTabs.map((tab) => (
          <li key={tab} className={`cursor-pointer capitalize text-sm px-4 py-1.5 font-medium ${subjectType === tab ? "bg-white shadow text-indigo-600 rounded-full" : ""}`} onClick={() => {handleClick(tab)}}>{tab}</li>
        ))}
      </ul>
      <WideList
        key={props.programType}
        cardHeaderSettings={cardHeaderSettings}
        listData={filteredData}
        hideSubject={window.configs.hideSubjectResults}
      />
    </div>
  );
};

export default ProgramSubjects;
