import ListItem from "./ListItem";
import CardHeader from "./CardHeader";
import { Link } from "react-router-dom";
import Image from "./Image";
import ListItemDetail from "./ListItemDetail";
import Icon from "./Icon";
import { FaCircleCheck } from "react-icons/fa6";

const WideList = ({
  cardHeaderSettings,
  listData,
  onItemClick,
  cb = false,
  cbDocsClick,
  docAssessment = false,
  hideSubject = false,
}) => {
  const renderContents = (
    svgIcon,
    headingIcon,

    headingText,
    itemList,
    contentClass
  ) => {
    const headingStyle = {
      fontSize: "0.875rem",
      fontWeight: "500",
      color: contentClass || "#3182ce",
      alignItems: "center",
      cursor: "pointer",
      display: "flex",
    };

    return (
      <div className="p-4 sm:px-6 hover:bg-gray-100 focus:outline-none focus:bg-gray-50">
        <div className="mb-3">
          <div className="flex text-sm font-medium items-center cursor-pointer text-sky-600">
            {headingIcon && (
              <Image src={`${process.env.PUBLIC_URL}headingIcon`} />
            )}
            {svgIcon ? <div className="mr-2">{svgIcon}</div> : null}
            {headingText}
          </div>
        </div>

        <div className="mt-2">
          <div className="flex sm:flex-row flex-col gap-2">
            {itemList?.map((subItem, index) => {
              if (
                hideSubject &&
                !subItem?.itemText?.toLowerCase()?.includes("result")
              ) {
                return (
                  <ListItem
                    className="list_item"
                    listItem={subItem}
                    key={index}
                    hideSubject={hideSubject}
                  />
                );
              } else if (!hideSubject) {
                return (
                  <ListItem
                    className="list_item"
                    listItem={subItem}
                    key={index}
                    hideSubject={hideSubject}
                  />
                );
              }
            })}
          </div>
        </div>
      </div>
    );
  };

  const renderExpandableContents = (
    headingIcon,
    headingText,
    itemList,
    showDetail,
    buttonIcon,
    buttonText,
    buttonFunction,
    clickEvent,
    hideButton,
    iconDocs,
    controlId,
    name = ""
  ) => {
    const buttonClassName = buttonFunction
      ? "flex items-center gap-1 bg-blue-600 text-white px-3 py-2 rounded-lg text-sm hover:bg-blue-500"
      : "flex items-center gap-1 bg-blue-600 text-white px-3 py-2 rounded-lg text-sm hover:bg-blue-500 disabled:cursor-not-allowed";
    return (
      <div className="p-4 sm:px-6 hover:bg-gray-100">
        <div className="flex justify-between overflow-hidden text-ellipsis whitespace-nowrap">
          <div
            className="flex text-base font-medium text-sky-600 cursor-pointer overflow-hidden whitespace-nowrap text-ellipsis"
            id={name}
            onClick={(e) => !docAssessment && clickEvent(e)}
          >
            {!docAssessment && (
              <span className="mr-2 h-7 items-center">
                <svg
                  className={
                    showDetail ? " h-4 w-4 rotate-0" : " h-4 w-4 rotate-[-180] svg_style_rotate"
                  }
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </span>
            )}
            {headingIcon ? (
              <Image src={`${process.env.PUBLIC_URL}headingIcon`} />
            ) : null}
            {headingText}
            {docAssessment &&
              iconDocs?.length > 0 &&
              iconDocs.map((doc, idx) => {
                if (doc?.showIconDocs) {
                  return (
                    <span
                      key={doc?.id}
                      // style={{ height: "auto" }}
                      onClick={() => {
                        cbDocsClick(controlId, doc.id);
                      }}
                    >
                      <Icon
                        src={doc.icon}
                        viewBox={"0 0 24 24"}
                        className={
                          doc?.isclick ? "ml-2 h-auto hover:text-indigo-500 items-center text-indigo-500" : "text-sky-600 ml-2 h-auto items-center hover:text-indigo-500"
                        }
                      />
                    </span>
                  );
                }
              })}
          </div>
          <div className="ml_2 f_shrink_0 flex">
            <span className="d_inline_flex  b_radius_0375 shadow_sm">
              {buttonFunction && (
                <button
                  onClick={buttonFunction}
                  type="button"
                  className={buttonClassName}
                >
                  <svg
                    className="mr-2 ml-0.5 h-4 w-4"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d={buttonIcon} />
                  </svg>
                  {buttonText}
                </button>
              )}
              {!buttonFunction && (
                <span
                  className=""
                >
                  <FaCircleCheck className="w-5 h-5 text-green-600" />
                </span>
              )}
            </span>
          </div>
        </div>
        <div style={{ marginTop: "0.5rem", justifyContent: "space-between" }}>
          <div>
            {itemList?.map((subItem, index) => (
              <ListItem listItem={subItem} key={index} />
            ))}
          </div>
        </div>
      </div>
    );
  };
  const renderLink = (
    controlId,
    headingText,
    itemList,
    linkTarget,
    headingIcon,
    clickEvent,
    subComponentData,
    showDetail,
    buttonIcon,
    buttonText,
    buttonFunction,
    hideButton,
    svgIcon = null,
    iconDocs,
    name,
    hideSubject
  ) => {
    if (linkTarget?.indexOf("amazonaws.com") > 0 || clickEvent) {
      if (clickEvent) {
        return (
          <li>
            <a className="text-gray-200 cursor-pointer transition duration-200 ease-in-out focus:outline-none focus:bg-white hover:bg-white">
              {renderExpandableContents(
                headingIcon,
                headingText,
                itemList,
                showDetail,
                buttonIcon,
                buttonText,
                buttonFunction,
                clickEvent,
                hideButton,
                iconDocs,
                name,
                controlId
              )}
            </a>
            <ListItemDetail
              showControl={showDetail}
              itemData={subComponentData}
              iconDocs={iconDocs}
            ></ListItemDetail>
          </li>
        );
      } else {
        return (
          <li key={controlId}>
            {linkTarget !== "" ? (
              <a
                href={linkTarget || "#"}
                className="text-gray-200 cursor-pointer transition duration-200 ease-in-out focus:outline-none focus:bg-white hover:bg-white"
                target="_blank"
              >
                {renderContents(
                  svgIcon,
                  headingIcon,
                  headingText,
                  itemList,
                  null
                )}
              </a>
            ) : (
              renderContents(
                svgIcon,
                headingIcon,
                headingText,
                itemList,
                "#dc2626"
              )
            )}
          </li>
        );
      }
    } else {
      return (
        <li key={controlId}>
          {linkTarget !== "" ? (
            <Link to={linkTarget || "#"} className="text-gray-200 cursor-pointer transition duration-200 ease-in-out focus:outline-none focus:bg-white hover:bg-white">
              {renderContents(
                svgIcon,
                headingIcon,
                headingText,
                itemList,
                null
              )}
            </Link>
          ) : (
            renderContents(
              svgIcon,
              headingIcon,
              headingText,
              itemList,
              "#dc2626"
            )
          )}
        </li>
      );
    }
  };

  return (
    <div className="bg-white overflow-hidden rounded-md">
      <CardHeader settings={cardHeaderSettings} />
      <ul style={{ padding: 0, margin: 0 }}>
        {listData &&
          listData?.map(
            (
              {
                controlId,
                headingText,
                itemList,
                linkTarget,
                headingIcon,
                clickEvent,
                subComponentData,
                showDetail,
                buttonIcon,
                buttonText,
                buttonFunction,
                hideButton,
                svgIcon,
                iconDocs,
                name,
              },
              index
            ) => {
              return (
                <span style={{ margin: 0, padding: 0 }} key={index}>
                  {renderLink(
                    controlId,
                    headingText,
                    itemList,
                    linkTarget,
                    headingIcon,
                    clickEvent,
                    subComponentData,
                    showDetail,
                    buttonIcon,
                    buttonText,
                    buttonFunction,
                    hideButton,
                    svgIcon,
                    iconDocs,
                    name
                  )}
                </span>
              );
            }
          )}
      </ul>
    </div>
  );
};

export default WideList;
