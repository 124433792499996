const RenderActionButtons = ({ index, addCourse, removeCourse }) => {
    return (
        <div style={{ marginLeft: '1rem', marginTop: '0.5rem', flexGrow: 0 }}>
            <span style={{ display: 'inline-flex', borderRadius: '0.375rem', boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.05)' }}>
                {index == 0 ? (
                    <button
                        key={index}
                        type="button"
                        onClick={() => addCourse(index)}
                        className="flex items-center px-4 py-1.5 font-medium text-white bg-blue-600 cursor-pointer shadow hover:bg-blue-500 rounded-md"
                    >
                        +
                    </button>
                ) : (
                    <button
                        type="button"
                        onClick={() => removeCourse(index)}
                        className="flex items-center px-4 py-1.5 font-medium text-white bg-blue-600 cursor-pointer shadow hover:bg-blue-500 rounded-md"
                    >
                        -
                    </button>
                )}
            </span>
        </div>
    );
};

export default RenderActionButtons;