import React, { useState, useEffect } from "react";
import AsyncSelect from "react-select/async";
import * as constants from "../../constants";
import Axios from "axios";
import FormLabel from "../controls/FormLabel";

const AutoComplete = (props) => {
  const [inputValue, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState({});

  useEffect(() => {
    if (inputValue.length < 3) setIsLoading(false);
    else setIsLoading(true);
  }, [inputValue]);

  const handleInputChange = (newValue) => {
    setInputValue(newValue);
    return newValue;
  };

  const loadOptions = (inputValue, cb) => {
    const { endPoint, name, onChange, multiple, selectedItem } = props;
    let url = "";
    if (name === "NewProgram") {
      url = constants.BASEURL + endPoint + "&searchText=" + inputValue;
    } else {
      url =
        constants.BASEURL +
        (endPoint || "Suburb/getSuburb") +
        "?searchText=" +
        inputValue;
    }
    if (inputValue.length >= 3) {
      Axios.get(url).then((response) => {
        const data = response.data;
        if (name === "AgentId") {
          data.forEach((d) => (d.value = d.agentID));
        } else if (name === "NewProgram") {
          data.forEach((d) => (d.value = d.programCampusID));
        } else {
          data.forEach((d) => (d.value = d.suburbID));
        }
        cb(data);
      });
    } else {
      const selectedOption = selectedItem || {};
      setSelectedOption(selectedOption);
      onChange(name, selectedOption);
      cb();
    }
  };

  const setOption = (selectedOption) => {
    const { onChange, name } = props;
    setSelectedOption(selectedOption);
    onChange(name, selectedOption);
  };

  const setLabel = (option) => {
    if (props.name === "AgentId") {
      return `${option.agentCode} - ${option.agentName}`;
    } else if (props.name === "NewProgram") {
      return `${option.courseCode} - ${option.courseName}`;
    } else {
      return `${option.name}: ${option.postCode}`;
    }
  };

  const {
    name,
    label,
    error,
    multiple,
    dependentOn = true,
    selectedItem,
  } = props;

  return (
    <div className={`flex-col ${!dependentOn ? "hidden" : ""}`}>
      <FormLabel
        name={name}
        text={label}
        color={error ? "red" : "black"}
        error={error}
        errorText={`${label} must be entered or selected.`}
      >
        <div style={{ position: "relative" }}>
          <AsyncSelect
            menuPortalTarget={document.body}
            maxMenuHeight={100}
            loadOptions={loadOptions}
            onInputChange={handleInputChange}
            getOptionLabel={(option) => setLabel(option)}
            isMulti={multiple}
            onChange={(option) => setOption(option)}
            value={selectedItem}
            className={
              ""
            }
          />
        </div>
        {/* {error && (
          <p className="mt-2 text-sm text-red-600" id="email-error">
            {label} must be entered or selected.
          </p>
        )} */}
      </FormLabel>
    </div>
  );
};

export default AutoComplete;
