import React, { useState } from "react";
import axios from "axios";

const Fileupload = () => {
  const [file, setFile] = useState("");

  const submit = async (e) => {
    e.preventDefault();
    const url = "http://localhost:61331/api/Uploadfiles/Uploadfile";
    const formData = new FormData();
    formData.append("body", file);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    return axios.post(url, formData, config);
  };

  const setFileHandler = (e) => {
    setFile(e.target.files[0]);
  };

  return (
    <div className="container-fluid">
      <form onSubmit={submit}>
        <div className="col-sm-12 btn btn-primary">File Upload</div>
        <h1>File Upload</h1>
        <input type="file" onChange={setFileHandler} multiple />
        <button className="btn btn-primary" type="submit">
          Upload
        </button>
      </form>
    </div>
  );
};

export default Fileupload;
