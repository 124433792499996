import React, { useState, useEffect } from "react";
import FormSection from "../controls/FormSection";
import TextBox from "../controls/TextBox";
const blankReference = {
  ContactName: null,
  InstitutionName: null,
  Position: null,
  ContactNumber: null,
  Email: null,
  WorkDateFromTo: null,
  error: {},
};
let controlIndex = 0;

const ReferenceSection = ({ label, references = [], onChange }) => {
  const [currentReferences, setCurrentReference] = useState({});
  const [referenceList, setReferenceList] = useState([]);
  useEffect(() => {
    if (
      references?.length === 0 ||
      references === null ||
      references === undefined
    ) {
      addReference();
    }
  }, []);

  const addReference = () => {
    const cReference = { ...blankReference };
    cReference.error = {};
    const updatedReferences = [...referenceList, cReference];
    setReferenceList(updatedReferences);
    onChange(updatedReferences);
  };

  const removeReference = (index) => {
    const updatedReferences = [...referenceList];
    updatedReferences.splice(index, 1);
    setReferenceList(updatedReferences);
    onChange(updatedReferences);
  };

  const renderActionButtons = (index) => {
    return (
      <div className="ml-4 mt-2 flex-shrink-0">
        <span className="inline-flex rounded-md shadow-sm mr-2">
          {index === 0 ? (
            <button
              type="button"
              onClick={() => addReference()}
              className="flex items-center px-4 py-1.5 font-medium text-white bg-blue-600 cursor-pointer shadow hover:bg-blue-500 rounded-md"
            >
              +
            </button>
          ) : (
            <button
              type="button"
              onClick={() => removeReference(index)}
              className="flex items-center px-4 py-1.5 font-medium text-white bg-blue-600 cursor-pointer shadow hover:bg-blue-500 rounded-md"
            >
              -
            </button>
          )}
        </span>
      </div>
    );
  };

  const handleChange = (e, name, type, id) => {
    let currentReferencesUpdate = { ...referenceList[controlIndex] };
    let controlName = "";
    if (name && type !== "Select") {
      let index = name.slice(0, 1);
      controlIndex = index;
      name = name.slice(1);
      currentReferencesUpdate[name] = e;
      controlName = name;
    } else if (name && type === "Select") {
      let targetID = id;
      let index = targetID.slice(0, 1);
      controlIndex = index;
      currentReferencesUpdate[name] = e;
      controlName = name;
    } else {
      let targetID = e.target.id;
      let index = targetID.slice(0, 1);
      controlIndex = index;
      currentReferencesUpdate[e.target.name] = e.target.value;
      controlName = e.target.name;
    }

    let cReference = currentReferencesUpdate;
    const keys = Object.keys(cReference);
    keys.map((key) => {
      if (key !== "error") {
        if (
          (currentReferencesUpdate[key] === null ||
            currentReferencesUpdate[key] === "") &&
          currentReferencesUpdate.error[key] === undefined
        )
          return;
        if (
          (currentReferencesUpdate[key] === null ||
            currentReferencesUpdate[key] === "") &&
          currentReferencesUpdate.error[key]
        ) {
          currentReferencesUpdate.error[key] = true;
          return;
        }
      }
    });

    let updatedReferences = [...referenceList];
    updatedReferences[controlIndex] = currentReferencesUpdate;

    setReferenceList(updatedReferences);
    onChange(updatedReferences);
  };

  const renderReference = (reference, index) => {
    const { error } = reference;
    console.log("index", index);
    return (
      <div className="col-span-3" key={index}>
        <div>
          <div className="flex justify-between items-center">
            {
              <>
                <h3 className="text-base leading-6 font-medium text-gray-900">
                  Reference No. {index + 1}
                </h3>
                {renderActionButtons(index)}
              </>
            }
          </div>
        </div>
        <div className="grid md:grid-cols-3 sm:grid-cols-2 gap-x-6 gap-y-4 mt-2.5">
          <TextBox
            key={"InstitutionName" + index}
            id={index + "InstitutionName"}
            name="InstitutionName"
            dependentOn={true}
            error={error?.InstitutionName}
            label="Institution Name"
            // onClick={() => setCurrentReference(reference)}
            value={reference[index]?.["InstitutionName"]}
            onChange={handleChange}
          />
          <TextBox
            key={"ContactName" + index}
            id={index + "ContactName"}
            name="ContactName"
            dependentOn={true}
            error={error?.ContactName}
            label="Contact Name"
            // onClick={() => setCurrentReference(reference)}
            value={reference[index]?.["ContactName"]}
            onChange={handleChange}
          />
          <TextBox
            key={"Position" + index}
            id={index + "Position"}
            name="Position"
            dependentOn={true}
            error={error?.Position}
            label="Position"
            // onClick={() => setCurrentReference(reference)}
            value={reference[index]?.["Position"]}
            onChange={handleChange}
          />
          <TextBox
            key={"ContactNumber" + index}
            id={index + "ContactNumber"}
            name="ContactNumber"
            dependentOn={true}
            error={error?.ContactNumber}
            label="Contact Number"
            // onClick={() => setCurrentReference(reference)}
            value={reference[index]?.["ContactNumber"]}
            onChange={handleChange}
          />
          <TextBox
            key={"Email" + index}
            id={index + "Email"}
            name="Email"
            dependentOn={true}
            error={error?.Email}
            label="Email"
            // onClick={() => setCurrentReference(reference)}
            value={reference[index]?.["Email"]}
            onChange={handleChange}
          />
          <TextBox
            key={"WorkDateFromTo" + index}
            id={index + "WorkDateFromTo"}
            name="WorkDateFromTo"
            dependentOn={true}
            error={error?.WorkDateFromTo}
            label="Dates when you worked with them"
            // onClick={() => setCurrentReference(reference)}
            value={reference[index]?.["WorkDateFromTo"]}
            onChange={handleChange}
          />
        </div>
      </div>
    );
  };

  return (
    <FormSection label={label}>
      {referenceList?.length === 0 && renderReference({}, 0)}
      {referenceList?.length > 0 &&
        referenceList?.map((reference, index) =>
          renderReference(reference, index)
        )}
    </FormSection>
  );
};

export default ReferenceSection;
