import React, { useEffect, useState } from "react";
import axios from "axios";
import * as constants from "../constants";
import WideList from "../components/common/WideList";
import { Navigate } from "react-router-dom";
import EndIcon from "../assets/icon/endIcon";
import StartIcon from "../assets/icon/startIcon";
import { formatCurrencySign, removeDecimalIfZero } from "./helperFunction";

const Programs = ({ programType, title, description }) => {
  const cardHeaderSettings = {
    headingText: title,
    description: description,
  };
  const [listData, setListData] = useState(undefined);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const config = { headers: { "Content-Type": "application/json" } };
        const studentId = localStorage.getItem("studentId");
        const response = await axios.get(
          constants.BASEURL +
            "Student/" +
            programType +
            "/Programs/" +
            studentId,
          config
        );
        parsePrograms(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [programType]);

  const parsePrograms = (listItem) => {
    const updatedList = listItem.map((item) => {
      item.controlId = item.enrolmentId;
      item.headingText = item.courseName;
      item.linkTarget =
        "/dashboard/programs/" +
        item.enrolmentId +
        "/summary?uos=" +
        item.totalUOS +
        "&subjects=" +
        item.totalSubjects;
      item.test = item.courseId;

      let itemsList = [];

      let subItem = {};
      subItem.iconComp = <StartIcon />;

      subItem.itemText = item.startDate;
      itemsList.push(subItem);

      subItem = {};
      subItem.iconComp = <EndIcon />;
      subItem.itemText = item.endDate;
      itemsList.push(subItem);

      subItem = {};
      subItem.icon = "";
      let objectText = "Subjects ";
      let totalObjects = item.totalSubjects;
      let completedObjects = item.completedSubjects;
      if (item.totalUOS > 0) {
        objectText = "Units ";
        totalObjects = item.totalUOS;
        completedObjects = item.completedUOS;
      }
      if (totalObjects !== 0) {
        subItem.itemText = "Total " + objectText + totalObjects;
        itemsList.push(subItem);
      }
      if (completedObjects !== 0 && !window.configs.hideSubjectResults) {
        subItem = {};
        subItem.icon = "";
        subItem.itemText = "Completed " + objectText + completedObjects;
        itemsList.push(subItem);
      }

      if (!window.configs.hideFinancials && item.totalInvoiced !== 0) {
        subItem = {};
        subItem.icon = "";
        subItem.itemText = "Invoiced " + removeDecimalIfZero(formatCurrencySign(item.totalInvoiced));
        itemsList.push(subItem);

        if (!window.configs.hideFinancials && item.totalReceipted !==0) {
          subItem = {};
          subItem.icon = "";
          subItem.itemText = "Receipted " + removeDecimalIfZero(formatCurrencySign(item.totalReceipted));
          itemsList.push(subItem);

          subItem = {};
          subItem.icon = "";
          subItem.itemText = "Last Receipt " + removeDecimalIfZero(formatCurrencySign(item.lastReceiptAmount));
          itemsList.push(subItem);

          subItem = {};
          subItem.icon = "";
          subItem.itemText = "on " + item.lastReceiptDate;
          itemsList.push(subItem);
        }
      }

      item.itemList = itemsList;

      return item;
    });

    setListData(updatedList);
  };

  if (!localStorage.getItem("studentId")) {
    return <Navigate to={"/Login"} />;
  }

  if (!listData) {
    return "loading....";
  }

  return (
    <React.Fragment>
      <WideList
        key={programType}
        cardHeaderSettings={cardHeaderSettings}
        listData={listData}
      />
    </React.Fragment>
  );
};

export default Programs;
