import moment from "moment";
const updateCourseObject = ({
  currentCourse,
  courses,
  index,
  setCourses,
  courseType,
  onChange,
  setCurrentCourse,
}) => {
  var ccourse = { ...currentCourse };
  const keys = Object.keys(ccourse); // Getting all the keys of currentCourse
  // Getting the value of course type from props

  keys?.map((key) => {
    if (!currentCourse) {
      currentCourse = courses; // Use the courses state instead of this.state
    }

    if (key !== "error" && key !== "HolidayWeeks") {
      if (courseType === 9 && (key === "UOS" || key === "Subject")) {
        currentCourse.error[key] = false;
        return;
      }
      if (courseType === 22 && key === "UOS") {
        currentCourse.error[key] = false;
        return;
      }

      if (courseType === 23 && key === "Subject") {
        currentCourse.error[key] = false;
        return;
      }

      if (
        (currentCourse[key] === null || currentCourse[key] === "") &&
        currentCourse.error[key] === undefined
      )
        return;

      if (
        (currentCourse[key] === null || currentCourse[key] === "") &&
        currentCourse.error[key]
      ) {
        currentCourse.error[key] = true;
        return;
      }

      if (
        currentCourse[key].constructor === Number().constructor &&
        currentCourse[key] > 0
      ) {
        currentCourse.error[key] = false;
        return;
      }

      if (
        currentCourse[key].constructor === String().constructor &&
        currentCourse[key].trim() !== "" &&
        currentCourse[key].trim() !== "0"
      ) {
        //currentCourse.error[key] = false;
        return;
      }
      // const myMoment = moment();
      // console.log(key + " = " + myMoment.constructor);
      // console.log(key + " = " + currentCourse[key].constructor);
      if (
        currentCourse[key] instanceof moment ||
        currentCourse[key].constructor === new Date().constructor ||
        typeof currentCourse[key] == "object"
      ) {
        currentCourse.error[key] = false;
        return;
      }

      currentCourse.error[key] = true;
    }
  });

  let updatedCourses = [...courses];
  updatedCourses[index] = { ...currentCourse };
  setCourses(updatedCourses);
  onChange(updatedCourses);
  setCurrentCourse(currentCourse);
};

export default updateCourseObject;
