import React, { useEffect, useState } from "react";
import * as constants from "../constants";
import ReactHtmlParser from "html-react-parser";
import Axios from "axios";
import TextAreaBox from "./controls/TextAreaBox";
import TextBox from "./controls/TextBox";
import RadioBox from "./controls/RadioBox";
import CheckBox from "./controls/CheckBox";


function AnswerQuiz(props) {
  const { quizId, objectId, objectType, programId, afterUpdate } = props;
  const [quizData, setQuizData] = useState(null);
  const [quizResponse, setQuizResponse] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Axios.get(constants.BASEURL + "Quiz/" + quizId);
        const result = response.data;
        const quizResponseData = {
          quizId: parseInt(quizId),
          objectType,
          objectId,
          programId,
          answers: result.questions.map(
            ({ quizResponseId, quizQuestionId }) => ({
              quizResponseId,
              quizQuestionId,
            })
          ),
        };
        setQuizData(result);
        setQuizResponse(quizResponseData);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [quizId, objectId, objectType, programId]);

  const submitQuiz = (e) => {
    const { questions } = quizData;
    const { answers } = quizResponse;

    let validated = true;
    questions.forEach((question) => {
      const { answerText } = answers.find(
        (answer) => answer.quizQuestionId == question.quizQuestionId
      );
      switch (question.questionType) {
        case 2:
        case 4:
        case 10:
          if (answerText && answerText !== "") {
            question.questionColor = "black";
          } else {
            question.questionColor = "red";
            validated = false;
          }
          break;
        case 11:
          if (
            question.questionOptions.some(
              (qOption) =>
                qOption.sequence === 0 || qOption.sequence === undefined
            )
          ) {
            question.questionColor = "red";
            validated = false;
          } else {
            question.questionColor = "black";
          }
          break;
      }
    });

    if (validated) {
      Axios.post(constants.BASEURL + "Quiz", quizResponse)
        .then((result) => {
          let message = "Success!";

          if (!result.data.success) {
            message = result.data.message;
            return;
          }
          if (afterUpdate) afterUpdate(quizId);
        })
        .catch((ex) => {
          console.error(ex);
        });
    }
  };

  const handleChange = (e, quizQuestionId, questionType, multipleAnswers) => {
    const { questions } = quizData;
    const { answers } = quizResponse;
    const currentAnswer = answers.find(
      (answer) => answer.quizQuestionId == quizQuestionId
    );
    if (!currentAnswer) return;

    switch (questionType) {
      case 2:
      case 10:
        currentAnswer.answerText = e.currentTarget.value;
        break;
      case 4:
        const { questionOptions } = questions.find(
          (question) => question.quizQuestionId == quizQuestionId
        );
        const tempOptions = [...questionOptions];
        const currentObj = questionOptions.find(
          (coption) => coption.questionOptionsId == e.currentTarget.value
        );
        if (currentObj) {
          currentObj.checked = e.currentTarget.checked;
          let answerText = e.currentTarget.value;
          if (multipleAnswers) {
            answerText = tempOptions
              .filter((opt) => opt.checked)
              .map((opt) => opt.questionOptionsId)
              .toString();
          }
          answers.find((qr) => qr.quizQuestionId == quizQuestionId).answerText =
            answerText;
        }
        break;
    }

    setQuizResponse(quizResponse);
  };

  const handleSequence = (e, currentOptionId, options) => {
    const { questions } = quizData;
    const { answers } = quizResponse;
    const quizQuestionId = options[0].quizQuestionId;
    const { questionOptions } = questions.find(
      (question) => question.quizQuestionId == quizQuestionId
    );

    const currentObj = questionOptions.find(
      (coption) => coption.questionOptionsId == currentOptionId
    );
    if (currentObj && currentObj.sequence > 0) {
      questionOptions
        .filter((qOption) => qOption.sequence > currentObj.sequence)
        .forEach((opt) => opt.sequence--);
      currentObj.sequence = 0;
    } else {
      let max = questionOptions.reduce((prev, current) => {
        prev.sequence = prev.sequence || 0;
        current.sequence = current.sequence || 0;
        return prev.sequence > current.sequence ? prev : current;
      });

      currentObj.sequence = max.sequence ? max.sequence + 1 : 1;
    }
    const tempOptions = [...questionOptions];
    const answerText = tempOptions
      .sort((a, b) => (a.sequence > b.sequence ? 1 : -1))
      .map((opt) => opt.questionOptionsId)
      .toString();

    answers.find((qr) => qr.quizQuestionId == quizQuestionId).answerText =
      answerText;

    setQuizData(quizData);
    setQuizResponse(quizResponse);
  };

  return (
    <>
      {quizData && (
        <div style={styles.quizMainContainer}>
          <div
            style={{
              "@media (max-width: 768px)": {
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              },
            }}
          >
            <div style={{ flex: "1", minWidth: "0" }}>
              <h2
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  lineHeight: "1.75",
                  color: "#333333",
                  "@media (min-width: 640px)": {
                    fontSize: "1.875rem",
                    lineHeight: "2.25",
                    display: "-webkit-box",
                    WebkitLineClamp: "1",
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                  },
                }}
              >
                {ReactHtmlParser(quizData?.quizIntro)}
              </h2>
            </div>
            <div
              style={{
                marginTop: "1rem",
                display: "flex",
                "@media (min-width: 768px)": {
                  marginTop: "0px",
                  marginLeft: "1rem",
                },
              }}
            >
              <span
                style={{
                  boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
                  borderRadius: "0.375rem",
                }}
              ></span>
              <span
                style={{
                  marginLeft: "0.75rem",
                  boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
                  borderRadius: "0.375rem",
                }}
              ></span>
            </div>
          </div>
          <div style={styles.quizMainContainer}>
            <div style={{ marginBottom: "1rem" }}>
              <form name="webform">
                <RenderQuiz
                  state={{ quizData, quizResponse }}
                  handleSequence={handleSequence}
                  handleChange={handleChange}
                />
                <div
                  style={{
                    width: "fit-content",
                    textAlign: "right",
                    gridColumn: "span 2",
                    marginTop: "1.5rem",
                    "@media (max-width: 640px)": { gridColumn: "span 1" },
                  }}
                >
                  <span
                    style={{
                      display: "inline-flex",

                      borderRadius: "0.375rem",
                      boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
                    }}
                  >
                    <button
                      type="button"
                      onClick={submitQuiz}
                      className="flex items-center px-4 py-1.5 font-medium text-white bg-blue-600 cursor-pointer shadow hover:bg-blue-500 rounded-md"
                    >
                      Submit
                    </button>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default AnswerQuiz;

const RenderQuiz = ({ handleSequence, state, handleChange }) => {
  const { questions } = state.quizData;
  if (!questions) {
    return;
  }

  return questions.map((question, index) => (
    <RenderQuestion
      question={question}
      index={index}
      handleSequence={handleSequence}
      state={state}
      handleChange={handleChange}
    />
  ));
};

const styles = {
  quizMainContainer: {
    maxWidth: "80rem",
    margin: "0 auto",
    "@media (min-width: 640px)": {
      paddingLeft: "1.5rem",
      paddingRight: "1.5rem",
    },
    "@media (min-width: 1024px)": {
      paddingLeft: "2rem",
      paddingRight: "2rem",
    },
  },
  questionContainer: {
    display: "flex",
    marginBottom: "1rem",
    marginTop: "1.5rem",
  },

  optionContainer: {
    "@media (min-width: 640px)": {
      gridColumn: "span 6",
      marginTop: "1.5rem",
    },
  },
  optionSubContainer: {
    marginLeft: "0.75rem",
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
  },

  optionInput: {
    marginRight: ".5rem",
    verticalAlign: "middle",
    color: "text-indigo-600",
  },
  optionSequence: {
    borderRadius: "9999px",
    height: "2.5rem",
    width: "2.5rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "1.25rem",
    fontWeight: "bold",
    backgroundColor: "#BFDBFE",
    color: "#1E40AF",
    cursor: "pointer",
  },
};

const RenderQuestion = ({
  question,
  index,
  handleSequence,
  state,
  handleChange,
}) => {
  const {
    quizDetailId,
    quizQuestionId,
    questionType,
    questiontext,
    questionOptions,
    multipleAnswers,
    questionColor,
  } = question;
  const { answers } = state.quizResponse;
  const currentAnswer = answers.find(
    (ans) => ans.quizQuestionId === quizQuestionId
  );

  const renderQuestionText = () => (
    <div>
      <p
        style={{
          marginTop: "0.25rem",
          fontSize: "0.875rem",
          lineHeight: "1.25rem",
          color: "var(--gray-500)",
        }}
      >
        {ReactHtmlParser(questiontext)}
      </p>
    </div>
  );

  const renderQuestionWithOptions = () => (
    <>
      <div style={styles.questionContainer}>
        <div
          style={{
            width: "1.25rem",
            fontSize: "1rem",
            fontWeight: "bold",
            color: `var(--${questionColor}-600)`,
          }}
        >
          {++index + ". "}
        </div>
        <div
          style={{
            width: "auto",
            fontSize: "1rem",
            fontWeight: "bold",
            color: `var(--${questionColor}-600)`,
          }}
        >
          {ReactHtmlParser(questiontext)}
        </div>
      </div>
      <div style={styles.optionContainer}>
        {questionOptions.map(
          ({ questionOptionsId, quizQuestionId, answerText, grade }) => (
            <div style={{ marginTop: "1rem" }} key={questionOptionsId}>
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "start",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: "1.25rem",
                  }}
                >
                  {multipleAnswers ? (
                    <CheckBox
                      // id={quizQuestionId}
                      onChange={(event) =>
                        handleChange(
                          event,
                          quizQuestionId,
                          questionType,
                          multipleAnswers
                        )
                      }
                      name={quizQuestionId}
                      value={questionOptionsId}
                      // style={{
                      //   height: "1rem",
                      //   width: "1rem",
                      //   color: "var(--indigo-600)",
                      //   transitionDuration: "150ms",
                      //   transitionTimingFunction: "ease-in-out",
                      // }}
                    />
                  ) : (
                    <RadioBox
                      id={quizQuestionId}
                      name={quizQuestionId}
                      onChange={(event) =>
                        handleChange(
                          event,
                          quizQuestionId,
                          questionType,
                          multipleAnswers
                        )
                      }
                      value={questionOptionsId}
                      // style={{
                      //   height: "1rem",
                      //   width: "1rem",
                      //   color: "var(--indigo-600)",
                      //   transitionDuration: "150ms",
                      //   transitionTimingFunction: "ease-in-out",
                      // }}
                    />
                  )}
                </div>
                <div style={styles.optionSubContainer}>
                  <p
                    style={{
                      fontWeight: "500",
                      color: `var(--${questionColor}-700)`,
                    }}
                  >
                    {answerText}
                  </p>
                </div>
              </div>
            </div>
          )
        )}
      </div>
    </>
  );

  const renderQuestionWithTextarea = () => (
    <>
      <div style={styles.questionContainer}>
        <div
          style={{
            width: "1.25rem",
            fontSize: "1rem",
            fontWeight: "bold",
            color: `var(--${questionColor}-600)`,
          }}
        >
          {++index + ". "}
        </div>
        <div
          style={{
            width: "auto",
            fontSize: "1rem",
            fontWeight: "bold",
            color: `var(--${questionColor}-600)`,
          }}
        >
          {ReactHtmlParser(questiontext)}
        </div>
      </div>
      <div style={{ display: "flex", marginBottom: "1rem" }}>
        <div
          style={{
            width: "100%",
            marginTop: "0.25rem",
            borderRadius: "0.375rem",
            boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
          }}
        >
          <TextAreaBox
            onChange={(event) =>
              handleChange(event, quizQuestionId, questionType)
            }
            id={quizQuestionId}
            rows={3}
            // style={{
            //   display: "block",
            //   width: "100%",
            //   transitionDuration: "150ms",
            //   transitionTimingFunction: "ease-in-out",
            //   fontSize: "0.875rem",
            //   lineHeight: "1.25rem",
            //   border: `1px solid var(--${questionColor}-500)`,
            //   "@media (min-width: 640px)": {
            //     fontSize: "1rem",
            //     lineHeight: "1.5rem",
            //   },
            // }}
            value={currentAnswer?.answerText}
          />
        </div>
      </div>
    </>
  );

  const renderQuestionWithInput = () => (
    <>
      <div style={styles.questionContainer}>
        <div
          style={{
            width: "1.25rem",
            fontSize: "1rem",
            fontWeight: "bold",
            color: `var(--${questionColor}-600)`,
          }}
        >
          {++index + ". "}
        </div>
        <div
          style={{
            width: "auto",
            fontSize: "1rem",
            fontWeight: "bold",
            color: `var(--${questionColor}-600)`,
          }}
        >
          {ReactHtmlParser(questiontext)}
        </div>
      </div>
      <div
        style={{
          "@media (min-width: 640px)": {
            gridColumnStart: "span 6",
          },
        }}
      >
        <div
          style={{
            marginTop: "0.25rem",
            borderRadius: "0.375rem",
            boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
            "@media (min-width: 640px)": {
              gridColumnStart: "span 2",
            },
          }}
        >
          <TextBox
            id={quizQuestionId}
            name={quizQuestionId}
            onChange={(event) =>
              handleChange(event, quizQuestionId, questionType)
            }
            value={currentAnswer?.answerText}
            // style={{
            //   display: "block",
            //   width: "100%",
            //   transitionDuration: "150ms",
            //   transitionTimingFunction: "ease-in-out",
            //   fontSize: "0.875rem",
            //   lineHeight: "1.25rem",
            //   border: `1px solid var(--${questionColor}-500)`,
            //   "@media (min-width: 640px)": {
            //     fontSize: "1rem",
            //     lineHeight: "1.5rem",
            //   },
            // }}
          />
        </div>
      </div>
    </>
  );

  const renderQuestionWithSequence = () => (
    <>
      <div style={styles.questionContainer}>
        <div
          style={{
            width: "1.25rem",
            fontSize: "1rem",
            fontWeight: "bold",
            color: `var(--${questionColor}-600)`,
          }}
        >
          {++index + ". "}
        </div>
        <div
          style={{
            width: "auto",
            fontSize: "1rem",
            fontWeight: "bold",
            color: `var(--${questionColor}-600)`,
          }}
        >
          {ReactHtmlParser(questiontext)}
        </div>
      </div>
      <div style={styles.optionContainer}>
        <fieldset>
          {questionOptions.map(
            ({
              questionOptionsId,
              quizQuestionId,
              answerText,
              grade,
              sequence,
            }) => (
              <div style={{ marginTop: "1rem" }} key={questionOptionsId}>
                <div
                  style={{
                    "@media (min-width: 640px)": {
                      gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
                      gap: "1rem",
                      alignItems: "start",
                      paddingTop: "1.25rem",
                      display: "grid",
                    },
                  }}
                >
                  <div style={styles.optionSubContainer}>
                    <p
                      style={{
                        fontWeight: "500",
                        color: `var(--${questionColor}-700)`,
                      }}
                    >
                      {answerText}
                    </p>
                  </div>
                  <div style={styles.optionSubContainer}>
                    <span
                      id={quizQuestionId}
                      onClick={(event) =>
                        handleSequence(
                          event,
                          questionOptionsId,
                          questionOptions
                        )
                      }
                      style={styles.optionSequence}
                    >
                      {sequence || "0"}
                    </span>
                  </div>
                </div>
              </div>
            )
          )}
        </fieldset>
      </div>
    </>
  );

  switch (questionType) {
    case 1:
      return renderQuestionText();
    case 2:
      return renderQuestionWithTextarea();
    case 4:
      return renderQuestionWithOptions();
    case 10:
      return renderQuestionWithInput();
    case 11:
      return renderQuestionWithSequence();
    default:
      return null;
  }
};
