import React from "react";
import FormLabel from "./FormLabel";
import { IoIosInformationCircleOutline } from "react-icons/io";

const SelectBox = ({
  data,
  dependentOn,
  disabled,
  idField = "idField",
  error,
  label,
  multiple,
  name,
  id,
  value = "",
  valueField = "valueField",
  onChange,
  onClick,
  onBlur,
  fullwidth = false,
}) => {
  const handleChange = (e) => {
    onChange(e, name);
  };

  return (
    <div className={!dependentOn ? "hidden" : ""}>
      <FormLabel
        text={label}
        color={error ? "red" : "black"}
        error={error}
        errorText={`${label} must be selected.`}
      >
        <div className={`flex items-center border rounded-lg  ${error ? "border-red-400 focus-within:border-red-400" : "focus-within:border-blue-400"}`}>
          <select
            id={!id ? name : id}
            key={`selectbox-${label}`}
            name={name}
            value={value}
            onClick={onClick}
            onChange={(e) => handleChange(parseInt(e.target.value))}
            disabled={disabled}
            className={`w-full font-normal px-3 py-2.5 rounded-lg focus:outline-none text-black`}
          >
            <option key={0} value={""}>
              {data ? "Select" : "Loading...."}
            </option>
            {data &&
              data.map((dataItem) => (
                <option key={dataItem[idField]} value={dataItem[idField]}>
                  {dataItem[valueField]}
                </option>
              ))}
          </select>
          {error && <IoIosInformationCircleOutline className="w-5 h-5 mr-2" /> }
        </div>
      </FormLabel>
    </div>
  );
};

export default React.memo(SelectBox);
