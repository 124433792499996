import React from "react";
import Programs from "../services/Programs";
import { CURRENTPROGRAM, PASTPROGRAM } from "../constants";

const Program = () => {
  return (
    <>
      <Programs
        key={"prog" + CURRENTPROGRAM}
        programType={CURRENTPROGRAM}
        title="Current Programs"
        description="Current Programs you are studying in"
      />

      <Programs
        key={"prog" + PASTPROGRAM}
        programType={PASTPROGRAM}
        title="Past Programs"
        description="Past Programs you have already completed"
      />
    </>
  );
};

export default Program;
