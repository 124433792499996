
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import { FaEyeSlash } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { toast } from 'react-toastify';
import Avatar from './common/Avatar';
import { BASEURL } from '../constants';

const ChangePassword = () => {
    const [data, setData] = useState({});
    const [passwordVisible, setPasswordVisible] =useState(false);
    const [pass1,setPass1]=useState('')
    const [pass2,setPass2]=useState('')
    const [pass3,setPass3]=useState('')
    const [pass_check,setPass_check]=useState(false)
    const[isSubmit,setSubmit]=useState(false)
    const[pass_show,setpass]=useState(false)
    const[pass_show2,setpass2]=useState(false)
    const [passwordError,setPasswordError]=useState(false)

    const navigate=useNavigate()
    useEffect(() => {
        if(pass2 !== pass3){
            setPass_check(true)
        } else {
            setPass_check(false)
        }
    }, [pass2, pass3])

    const studentName = localStorage.getItem("studentName");
    const handlecheck= async () => {
     if(passwordError===false)
    {
    
                    if(pass2 === pass3)
                    {   
                        setSubmit(true)
                        try {
                            let studentid = localStorage.getItem("studentId");
                            const temp =  await axios.get(BASEURL + `Student/UpdatePassword/${studentid}?currentpass=${pass1}&newpass=${pass2}`)
                            toast.success(temp.data.message)
                            navigate("/dashboard")
                        } catch (error) {
                        toast.error(error.response.data.message)
                        
                        }
                        finally{
                            setPass1('')
                            setPass2('')
                            setPass3('')
                            setSubmit(false)
                        }
                    }
                        else{
                            toast.error('Password do not match');
                    
                        }
                        
    }
    else{
        toast.error('Invalid Password Format')
    }

      
    }
    const togglepassw=()=>{
        setpass(prevState=>!prevState)
    }
    const togglepassw2=()=>{
        setpass2(prevState=>!prevState)
    }

    const validatePasswords = () => {
        const isValid = pass2.length >= 8 && /[A-Z]/.test(pass2) && /[a-z]/.test(pass2) && /\d/.test(pass2);
        setPasswordError(!isValid);
        return isValid;
    };
    const handlePasswordChange = (e) => {
        setPass2(e.target.value);
        validatePasswords();
    };
 
  return (
   <>
      
    <div className='grid justify-center h-calc[100vh-150px]' >
        <div className='flex flex-col' direction="vertical">
            <div style={{display:"flex" ,justifyContent:"center",marginBottom:"10px"}}>
            <Avatar  className='rounded-full w-[150px] h-[150px]' url={`https://ui-avatars.com/api/name=${studentName}/?background=random`}/>
            </div>
            <div  className='w-[500px] h-[320px] border-2 border-grey p-6 rounded-lg' >
                <h2 className='font-semibold mb-4'>Change Password</h2>
                <div className='flex flex-col' >
                    <div className='flex relative '>
                      <input className='w-full font-normal px-3 py-1.5 rounded-lg focus:outline-none text-black  border focus:border-blue-800' placeholder="old password" type={pass_show?"text":"password"} value={pass1} onChange={(e) => setPass1(e.target.value)} size='large'/>
                      <button className='absolute right-2  top-3' onClick={togglepassw}>{pass_show?<FaEye/>:<FaEyeSlash/>}</button>                 
                    </div>

                    <div className='mt-4 flex relative '>
                        <input className='w-full font-normal px-3 py-1.5 rounded-lg focus:outline-none text-black  border focus:border-blue-800'
                            status={pass_check ? "error" : ""}
                            placeholder="new password"
                            value={pass2}
                            onChange={handlePasswordChange}
                            type={pass_show2?"text":"password"}
                            size='large'

                        />
                        <button className='absolute right-2  top-3' onClick={togglepassw2}>{pass_show2?<FaEye/>:<FaEyeSlash/>}</button> 
                    </div>
                    <div className='flex justify-end'> <span className='text-xs text-red-500 flex justify-start pl-2px '>{passwordError && 'Password must include a number, uppercase letter, lowercase letter, and special character'}</span></div>
                      
                    <div className='mt-4 flex relative'>
                        <input className='w-full font-normal px-3 py-1.5 rounded-lg focus:outline-none text-black  border focus:border-blue-800'
                            status={pass_check ? "error" : ""}
                        placeholder="confirm password"
                        value={pass3}
                        onChange={(e)=>setPass3(e.target.value)}
                        size='large'
                        type={pass_show2?"text":"password"}
                        />  
                       <button className='absolute right-2  top-3' onClick={togglepassw2}>{pass_show2?<FaEye/>:<FaEyeSlash/>}</button> 
                    </div>
                      
                    
                    <button 
                        type="button"
                        onClick={handlecheck}
                        disabled={isSubmit}
                        className=" mt-6 text-large font-semibold  px-3 py-2.5 w-full flex justify-center items-center  text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300   me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                       
                      >
                        Submit
                      </button>

                </div>
            </div>
        </div>
    </div>
   
    </>
  )
}

export default ChangePassword
