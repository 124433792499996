import React, { useEffect, useState } from "react";
import Tabs from "./common/Tabs";
import { PROGRAMICON, PROGRAMOUTLINEICON, SUBJECTICON } from "../IconConstants";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import { UOSDetailRoutes } from "../routes";

const Menu = (props) => {
  const location = useLocation();
  return <Tabs {...props} location={location} />;
};

const UOSDetail = () => {
  const location = useLocation();
  const { programId } = queryString.parse(location?.search);
  const navigate = useNavigate();
  const [tabsCollection, setTabsCollection] = useState([
    {
      title: "Summary",
      icon: PROGRAMICON,
      linkTarget: "summary?programId=" + programId,
      selectedOption: false,
      matchedText: "summary",
      isShown: true,
    },
    {
      title: "Subjects",
      icon: SUBJECTICON,
      linkTarget: "subjects?programId=" + programId + "&subTab=current",
      selectedOption: false,
      matchedText: "subjects",
      isShown: true,
    },
    {
      title: "Outline",
      icon: PROGRAMOUTLINEICON,
      linkTarget: "outline?programId=" + programId + "&objectType=23",
      selectedOption: true,
      matchedText: "outline",
      isShown: true,
    },
  ]);
  useEffect(() => {
    const updatedTabsCollection = [];
    tabsCollection.forEach((i) =>
      location.pathname.includes(i.matchedText)
        ? updatedTabsCollection.push({ ...i, selectedOption: true })
        : updatedTabsCollection.push({ ...i, selectedOption: false })
    );
    setTabsCollection(updatedTabsCollection);
  }, [location.pathname]);
  const handleOptionChoose = (e) => {
    const updatedTabsCollection = [];
    tabsCollection.forEach((i) =>
      i.linkTarget === e
        ? updatedTabsCollection.push({ ...i, selectedOption: true })
        : updatedTabsCollection.push({ ...i, selectedOption: false })
    );
    setTabsCollection(updatedTabsCollection);
    navigate(e);
  };

  console.log(tabsCollection)
  return (
    <React.Fragment>
      <div className="pl-[2rem] pr-[2rem]">
        <Menu
          tabsCollection={tabsCollection}
          // pathname={tabSelect}
          handleOptionChoose={handleOptionChoose}
        />{" "}
        <main className="bg-[#edf2f7cc]">
          <UOSDetailRoutes />
        </main>
      </div>
    </React.Fragment>
  );
};

export default UOSDetail;
