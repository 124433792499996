import { IoIosInformationCircleOutline } from "react-icons/io";
import { useEffect, useState } from "react";
import contactlogIcon from "../assets/icon/contactlog.svg";
import Axios from "axios";
import { BASEURL } from "../constants";
import { AiOutlineFolder } from "react-icons/ai";

function Alerts() {
  const url = BASEURL + "ContactLog?ObjectID="+ localStorage.getItem("studentId") +"&ObjectType=5";
  const [loading, setLoading] = useState(false); 
  const [info, setInfo] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await Axios.get(url);
        setInfo(response.data); 
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []); 

  if(loading) {
    return <div style={{marginTop: "10rem", display: "grid", placeContent: "center"}}><p>Loading...</p></div>
  }

  if(info.length == 0){
    return <div className="text-gray-500 mt-[10rem] grid place-content-center">
      <div className="flex items-center gap-2">
        <AiOutlineFolder className="w-8 h-8"/>
        <span className="text-lg">No Data</span>
      </div>
    </div>
  }
  // console.log(info)
  return (
    <>
    <div className="mt-8">
      <label className="text-lg px-4 font-semibold">Contact Logs</label>
      <div>
        {info.map((item,key) => (
          <ul className="list-none flex py-4 rounded-md px-4 hover:bg-gray-100 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out" key={key}>
            <img src={contactlogIcon} width={25}/>
            <div className="flex justify-between w-full">
              <div className="ml-4 mt-2" >
                <li className="text-lg">{item.username}</li>
                <li className="text-sm text-gray-600">{item.logTypeDesc + ' on ' + item.nd}</li>
              </div> 
              
              <div className="group relative flex justify-center">
                <button className=""><IoIosInformationCircleOutline className="w-5 h-5" /></button>
                <div className="text-left opacity-0 invisible group-hover:opacity-100 group-hover:visible absolute top-0 right-8 group-hover:start min-w-[290px] ml-2 bg-white text-gray-800 border border-gray-200 rounded-lg shadow-lg py-2 z-10">
                    <div className="px-4 py-2">Message:</div>
                    <div className="px-4 py-1 text-sm text-gray-600">{item.message}</div>
                </div>
              </div>
            </div>
          </ul>
          ))}
      </div>
    </div>
    </>
  );
}

export default Alerts;