import React from "react";
import { useEffect } from "react";
import { HiPaperClip } from "react-icons/hi";
import { HiOutlineDocumentDownload } from "react-icons/hi";
import { AiOutlineFolder } from "react-icons/ai";
import { AiOutlineLoading3Quarters } from "react-icons/ai";


const ListItemDetail = (props) => {
  const { showControl, itemData, iconDocs } = props;

  const matchIconClick = (item) => {
    let finalVal = false;
    const findIcon = iconDocs.find((icon) => icon?.id === item?.id);

    if (findIcon && findIcon?.isclick) {
      finalVal = true;
    }

    return finalVal;
  };

  return (
    <>
      {showControl && itemData ? (
        <div
          style={{paddingInline: '1rem'}}
          className={
            !showControl ? "p-0 " : "p-0 block"
          }
        >
          <div className="grid sm:grid-cols-2 gap-4 py-2">
            {itemData &&
              itemData?.map((item, idx) => {
                let IsColumn = false;

                let filterItem = itemData.filter((it) => it?.id === item?.id);
                let iconClick = iconDocs.filter((icon) => icon.isclick);
                if (
                  iconClick.length >= 1 &&
                  item?.id === "submitted" &&
                  filterItem?.length > 1
                ) {
                  IsColumn = true;
                } else if (
                  iconClick.length > 1 &&
                  item?.id === "assessment" &&
                  filterItem?.length > 0
                ) {
                  IsColumn = true;
                }
                // console.log(" matchIconClick(item)", filterItem, iconClick);
                return (
                  item !== null &&
                  matchIconClick(item) && (
                    <div
                      key={item?.key}
                      className={((itemData.length - 1) % 2 !== 0) && IsColumn && (idx === 1) ? "col-span-2" : ""}
                      // xs={24}
                      // sm={24}
                      // md={IsColumn ? 12 : 24}
                      // lg={IsColumn ? 12 : 24}
                      // lg={((itemData.length - 1) % 2 !== 0) && IsColumn && (idx === 1) ? 24 : 12}
                      // xl={IsColumn ? 12 : 24}
                    > 
                      <div className="border rounded-lg h-full p-4">
                        <div className="grid grid-cols-2 mb-2">
                          {item?.headerData &&
                            item?.headerData?.map((headItem) => (
                              <div key={headItem?.label}>
                                <h1 className=" text-[0.875rem] font-medium mb-2 text-opacity-100 text-slate-400">
                                  {headItem.label}
                                </h1>
                                <p className="text-sm">{headItem.text}</p>
                              </div>
                            ))}
                        </div>
                        <h1 className="text-sm font-medium mb-2 mt-4">{item?.listLabel}</h1>

                        <div className="border rounded-lg p-2.5">
                            {item?.listData.length > 0 ? item?.listData.map((listItem) => (
                              <div className="flex items-center justify-between">
                                <div className="flex items-center gap-2">
                                    <HiPaperClip className="w-5 h-5 fill-gray-400" />
                                    <span className="text-sm">{listItem?.itemName}</span>
                                </div>

                                  <a
                                    href={listItem?.linkTarget}
                                    target="_blank"
                                  >
                                    <HiOutlineDocumentDownload className="w-6 h-6 text-blue-600"/>
                                  </a>
                              </div>
                            )) : (
                              <div className="flex justify-center">
                                <p className="text-gray-500 flex items-center gap-1.5">
                                  <AiOutlineFolder className="w-5 h-5"/>
                                  <span>No Data</span>
                                </p>
                              </div>
                            )}

                        </div>

                        {/* <List
                          bordered
                          locale={{
                            emptyText: (
                              <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                className="empty_box"
                                imageStyle={{ width: 35, height: 35 }}
                              />
                            ),
                          }}
                          dataSource={item?.listData}
                          renderItem={(listItem) => (
                            <List.Item>
                              <Row style={{ width: "100%" }} gutter={16}>
                                <Col span={18}>
                                  <div className="d_flex items_center listitms_">
                                    <svg
                                      className="list_items_svg"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                    <span className="list_itemname">
                                      {listItem?.itemName}
                                    </span>
                                  </div>
                                </Col>
                                <Col span={6}>
                                  <a
                                    href={listItem?.linkTarget}
                                    target="_blank"
                                    className="lg_flex flex-center"
                                  >
                                    <svg
                                      className="listitm_downloadbtn"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      fill="currentColor"
                                    >
                                      <title>file-download</title>
                                      <path d="M14,2H6C4.89,2 4,2.89 4,4V20C4,21.11 4.89,22 6,22H18C19.11,22 20,21.11 20,20V8L14,2M12,19L8,15H10.5V12H13.5V15H16L12,19M13,9V3.5L18.5,9H13Z" />
                                    </svg>
                                  </a>
                                </Col>
                              </Row>
                            </List.Item>
                          )}
                        /> */}
                      </div>
                    </div>
                  )
                );
              })}
          </div>
        </div>
      ) : (
        <AiOutlineLoading3Quarters  className={`w-5 h-5 fill-blue-500 animate-spin ${showControl ? "" : "hidden"}`}/>
      )}
    </>
  );
};
export default ListItemDetail;
