import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { LiaBell } from "react-icons/lia";
import logo from "../../assets/images/clientlogo.png";
import Menu from "./Menu";
import RequestIcon from "../../assets/icon/RequestIcon";

const NavBar = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const menuRef = useRef(null);

  const optionSelection = (option) => {
    if (option === "request") {
      setIsOpen(false);
    }
  };

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  // const handleClickOutside = (event) => {
  //   if (menuRef.current && !menuRef.current.contains(event.target)) {
  //     setIsOpen(false);
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener("click", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("click", handleClickOutside);
  //   };
  // }, []);
  useEffect(() => {
    if (!localStorage.getItem("studentId")) {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <header className="z-20 bg-white sm:px-10 pt-5 h-22">
      {/* <div className="responsive_header" style={{height: "90px"}}> */}
        <div className="flex justify-between p-2">
          <Link
            to="/dashboard"
            onClick={() => {
              optionSelection("request");
            }}
            className="h-12"
            // style={{
            //   height: "50px",
            //   width: "130px"
            // }}
          >
            <img className="h-full w-full"
              // style={{ height: "100%", width: "100%"}}
              src={logo}
              alt="Workflow logo"
            />
          </Link>

          <div className="flex items-center p-1 text-gray-400 rounded-md gap-4"
            aria-label="Alerts"
          >
            <button type="button" placement="bottomRight" title="Make a request" className="mr-1 cursor-pointer">
              <span
                onClick={() => navigate("/dashboard/makeARequest")}
                className="w-[25px] h-[25px] flex justify-center items-center"
              >
                <RequestIcon />
              </span>
            </button>

            <button type="button" placement="bottomRight" title="Alerts" className="mr-1 cursor-pointer">                      
              <LiaBell
                onClick={() => navigate("/dashboard/alerts")}
                className="block flex justify-center items-center w-[25px] h-[25px]"
              />
            </button>

            <div
             //  ref={menuRef}
            >
              <Menu isOpen={isOpen} props={props} handleClick={handleClick} />
            </div>
          </div>
        </div>
      {/* </div> */}
    </header>
  );
};

export default NavBar;
