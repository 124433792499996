import React from "react";
import FormSection from "../controls/FormSection";
import RenderCustomSections from "./RenderCustomSections";
import RenderFormSectionDetail from "./RenderFormSectionDetail";

const RenderWebform = ({
  webForm,
  webFormFields,
  handleChange,
  setWebFormFields,
  DropDowns,
  setDropDowns,
  sigPadRef,
  updatePreference,
  updateCourse,
  setFiles,
  updateReference,
  updateContact,
  clearSignature,
  trim,
  handleCheckListChange,
  handleCheckBoxGroup,
  handleAchievementsChange,
  handleAgent,
  handleSuburb,
}) => {
  if (!webForm || !webForm?.webFormDetail) {
    return null; // Add a fallback for when webForm or webFormDetail is not available
  }

  const { formSections } = webForm?.webFormDetail;

  return formSections.map(
    (
      {
        content,
        formSectionID,
        formSectionTitle,
        formFields,
        expanded,
        sectionType,
      },
      idx
    ) => {
      if (
        sectionType !== 2 &&
        (formSectionID < 6 ||
          formSectionID === 22 ||
          formSectionID === 24 ||
          formSectionID === 29 ||
          formSectionID === 34 ||
          formSectionID === 40)
      ) {
        return (
          <RenderCustomSections
            key={`customSection-${formSectionID}-${idx}`}
            content={content}
            formSectionID={formSectionID}
            formSectionTitle={formSectionTitle}
            formFields={formFields}
            expanded={expanded}
            webForm={webForm}
            webFormFields={webFormFields}
            handleChange={handleChange}
            setWebFormFields={setWebFormFields}
            DropDowns={DropDowns}
            sigPadRef={sigPadRef}
            updatePreference={updatePreference}
            updateCourse={updateCourse}
            setFiles={setFiles}
            updateReference={updateReference}
            updateContact={updateContact}
            clearSignature={clearSignature}
            trim={trim}
          />
        );
      } else {
        return (
          <FormSection
            key={`FormSection-${formSectionID}-${idx}`}
            expanded={expanded}
            // headerClick={() => {
            //   expanded = !expanded; // This assignment won't have any effect, consider removing it
            // }}
            label={formSectionTitle}
          >
            <RenderFormSectionDetail
              formFields={formFields}
              formSectionID={formSectionID}
              sectionType={sectionType}
              webFormFields={webFormFields}
              setWebFormFields={setWebFormFields}
              webForm={webForm}
              DropDowns={DropDowns}
              setDropDowns={setDropDowns}
              handleChange={handleChange}
              handleCheckListChange={handleCheckListChange}
              handleCheckBoxGroup={handleCheckBoxGroup}
              handleAchievementsChange={handleAchievementsChange}
              handleAgent={handleAgent}
              handleSuburb={handleSuburb}
            />
          </FormSection>
        );
      }
    }
  );
};

export default RenderWebform;
