import React from "react";
import CardHeader from "../common/CardHeader";
import SignatureCanvas from "react-signature-canvas";

export default function SignaturePad({
  dependentOn,
  error,
  onEnd,
  reference,
  label,
  subTitle,
  clearFn,
}) {
  return (
    <div className="mb-2">
      <div className="shadow-md shadow-gray-100 rounded-lg">
        <div className="p-4">
          <CardHeader
            settings={{ headingText: label, description: subTitle }}
          />

          <div className="px-4 pt-4">
            <div className={`grid md:grid-cols-2 ${error ? "border border-red-500" : ""}`}>
              <div>
                <p className="text-sm italic">
                  When signing this form, use your mouse or touchpad pen to sign
                  in the box below (with the best version of your signature)
                </p>
                <div className="mt-2 h-56">
                  <SignatureCanvas
                    ref={reference}
                    canvasProps={{
                      className: "w-[100%] h-[100%] border border-gray-200 rounded-lg",
                      willReadFrequently: true,
                    }}
                    onEnd={onEnd}
                  />
                </div>
              </div>
              <div className="flex md:justify-start justify-end items-end ml-4 mt-4">
                <button className="flex items-center px-6 py-3.5 font-medium text-white bg-blue-600 cursor-pointer shadow hover:bg-blue-500 rounded-full" onClick={clearFn} type="button">Clear</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // <div style={{ marginBottom: "1rem" }}>
    //   <div
    //     style={{
    //       backgroundColor: "#FFFFFF",
    //       boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.05)",
    //       overflow: "hidden",
    //       borderRadius: "0.375rem",
    //       "@media (min-width: 640px)": {
    //         borderRadius: "1rem",
    //       },
    //     }}
    //   >
    //     <div className="signature-pad-sub-Container">
    //       <CardHeader
    //         settings={{ headingText: label, description: subTitle }}
    //       />

    //       <div className="signature-pad-sub-Container">
    //         <Row
    //           gutter={[16, 16]}
    //           className={error ? "signature-pad-error" : ""}
    //         >
    //           <Col span={12} offset={12}>
    //             <p
    //               style={{
    //                 fontSize: "0.875rem",
    //                 lineHeight: "1.25rem",
    //                 fontStyle: "italic",
    //               }}
    //             >
    //               When signing this form, use your mouse or touchpad pen to sign
    //               in the box below (with the best version of your signature)
    //             </p>
    //           </Col>
    //           <Col span={12} offset={12}>
    //             <SignatureCanvas
    //               ref={reference}
    //               canvasProps={{
    //                 className: "sigPad",
    //                 willReadFrequently: true,
    //               }}
    //               onEnd={onEnd}
    //             />
    //           </Col>
    //           <Col span={12} offset={12} style={{ textAlign: "center" }}>
    //             <Button
    //               type="primary"
    //               onClick={clearFn}
    //               className="my-button-clear"
    //               style={{ width: "fit-content" }}
    //             >
    //               Clear
    //             </Button>
    //           </Col>
    //         </Row>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
}
