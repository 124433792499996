import React from "react";
import CheckList from "../controls/CheckList";
import RenderField from "./RenderField";

const RenderFormSectionDetail = ({
  formFields,
  formSectionID,
  sectionType,
  webFormFields,
  setWebFormFields,
  webForm,
  DropDowns,
  setDropDowns,
  handleChange,
  handleCheckListChange,
  handleCheckBoxGroup,
  handleAchievementsChange,
  handleAgent,
  handleSuburb,
}) => {
  if (sectionType === 2) {
    return (
      <RenderCheckList
        formSectionId={formSectionID}
        handleCheckListChange={handleCheckListChange}
        webForm={webForm}
      />
    );
  } else {
    return (
      <>
        {formFields.map((formField, indx) => (
          <RenderField
            key={`renderField-formField-${formField.formFieldID}-${indx}`} // Add a unique key prop for better performance
            formField={formField}
            webForm={webForm}
            webFormFields={webFormFields}
            handleChange={handleChange}
            setWebFormFields={setWebFormFields}
            DropDowns={DropDowns}
            handleCheckBoxGroup={handleCheckBoxGroup}
            handleAchievementsChange={handleAchievementsChange}
            handleAgent={handleAgent}
            handleSuburb={handleSuburb}
          />
        ))}
      </>
    );
  }
};

export default RenderFormSectionDetail;

const RenderCheckList = ({ formSectionId, handleCheckListChange, webForm }) => {
  const { checkLists } = webForm;
  const getListbyID = checkLists.find((cl) => cl.checkListId === formSectionId);
  const { checkListItems } = getListbyID;

  return (
    <CheckList
      data={checkListItems}
      dependentOn={true}
      onChange={handleCheckListChange}
    />
  );
};
