// In the following code we have imported React and some controls such as
// SelectBox, TextBox, DateBox,AutoComplete, and a custom alert component. Also
// we have imported two sound clips one for error and second for successful submit.

import React, { useEffect, useState } from "react";
import SelectBox from "./controls/SelectBox";
import Axios from "axios";
import * as constants from "../constants";
import TextBox from "./controls/TextBox";
import DateBox from "./controls/DateBox";
import AutoComplete from "./common/AutoComplete";
import Alert from "./common/Alert";
import { ERRORICON, SUCCESSICON } from "../IconConstants";
import ErrorSound from "../assets/sounds/error.mp3";
import SubmitSound from "../assets/sounds/submit.mp3";
import FormLabel from "./controls/FormLabel";
import moment from "moment";
// import moment from "moment";
import { LuDownload } from "react-icons/lu";

import Uploader from "./controls/Uploader";
import { toast } from "react-toastify";

const MakeARequest = () => {

  const [loading, setLoading] = useState(false);

  const [programSelected, setProgramSelected] = useState(false);
  const [requestSelected, setRequestSelected] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [avatarurl, setAvatarurl] = useState(localStorage.getItem("avatarurl"));
  const [studentCourses, setStudentCourses] = useState([]);
  const [studentRequests, setStudentRequests] = useState([]);
  const [currentRequests, setCurrentRequests] = useState([]);
  const [pastRequests, setPastRequests] = useState([]);
  const [changeCoursesData, setChangeCoursesData] = useState([]);
  const [campus, setCampus] = useState("");
  // const [minDate, setMinDate] = useState(moment());
  const [programDisable, setProgramDisable] = useState(false);
  const [notesBox, setNotesBox] = useState("hidden");
  const [btnHidden, setBtnHidden] = useState("hidden");
  const [amountBox, setAmountBox] = useState("hidden");
  const [dateBoxes, setDateBoxes] = useState("hidden");
  const [newPHidden, setNewPHidden] = useState("hidden");
  const [paidAmount, setPaidAmount] = useState(0);
  const [errorAudio] = useState(new Audio(ErrorSound));
  const [submitAudio] = useState(new Audio(SubmitSound));
  const studentId = localStorage.getItem("studentId");

  // To show the documents
  const [showDocs, setShowDocs] = useState(false);
  const [docs, setDocs] = useState([]);

  const [pendingRequestFound, setPendingRequestFound] = useState(false);
  const [uploadedDocs, setUploadDocs] = useState([]);
  const [history, setHistory] = useState([]);
  const [tempHistory, setTempHistory] = useState([]);

  // console.log("pendingRequestFound", pendingRequestFound);

  const [isUploadedFileValidError, setIsUploadedFileValidError] = useState(null);

  const [requestData, setRequestData] = useState({
    enrolmentId: "",
    requestValue: "",
    notes: "",
    courseId: "",
    amount: "",
    startDate: "",
    endDate: "",
  });
  const [errors, setErrors] = useState({
    amount: "",
    startDate: "",
    endDate: "",
    newCourse: "",
    notes: "",
  });
  const [alertState, setAlertState] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertIcon, setAlertIcon] = useState("");
  const [iconColor, setIconColor] = useState("");
  // following fuction will be called to for alert sound
  const playSound = (audioFile) => {
    audioFile.play();
  };

  // following request will make https request to return all the programs in which student has enrolled
  const studentProgramsGet = () => {

    Axios.get(constants.BASEURL + "Student/0/Programs/" + studentId)
      .then((response) => {
        let currentPrograms = response?.data;
        setStudentCourses(currentPrograms);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // following request will make https request to return all the Request types
  const allRequestsGet = () => {
    // let requestCode = 364;
    Axios.get(
      // constants.BASEURL + "usercode/getusercode?codetype=" + requestCode
      constants.BASEURL + "StudentRequests"
    )
      .then((response) => {
        let requestsData = response.data;
        let pastR = requestsData.filter(function (item) {
          return item.value == 4;
        });

        setPastRequests(pastR);
        setCurrentRequests(requestsData);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  // Following function will make https request for all the courses for new selection
  // const changeCourseGet = (campus) => {

  //   // console.log(constants.BASEURL + "Campus/Programs?campusId=" + campus)

  //   Axios.get(constants.BASEURL + "Campus/Programs?campusId=" + campus)
  //     .then((response) => {
  //       let cCData = response.data;
  //       setChangeCoursesData(cCData);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };
  // follwing function will handle onChange Request for Autocomplete
  const handleAutoControl = (e, name) => {
    let controlName = "";
    let value = "";
    let rData = { ...requestData };
    let errorD = errors;
    if (name) {
      controlName = e;
      value = name.programCampusID;
    }
    // else {
    //   controlName = e.currentTarget.name;
    //   value = e.currentTarget.value;
    // }

    if (controlName === "NewProgram" && value) {
      errorD.newCourse = "";
      rData.startDate = "";
      rData.endDate = "";
      rData.courseId = value;
      setRequestData(rData);
      setErrors(errorD);
    }
  };


  const checkForPendingRequests = (historyData) => {
    setPendingRequestFound(historyData.some((item) => item.requestStatus === "Pending"));
  }

  const getDocumentHistory = async (enrolmentId) => {
    try {
      const res = await Axios.get(constants.BASEURL + `StudentRequests/RequestHistory/${enrolmentId}`);
      setHistory(res.data);
      setTempHistory(res.data); 
      
    } catch (error) {
      console.log(error);
    } 
  }

  // follwing function will handle onChange Request for all events other than Autocomplete
  const handleChange = (e, name, request) => {

    let controlName = "";
    let value = "";
    let rData = { ...requestData };
    let errorD = errors;
    if (name) {
      controlName = name;
      value = e;
    } else {
      controlName = e.currentTarget.name;
      value = e.currentTarget.value;
    }
    if (controlName === "Program") {
      let selectedProgram = studentCourses.filter(function (item) {
        return item.enrolmentId === +value;
      });

      if (selectedProgram.length > 0) {
        setProgramSelected(true);
      } else {
        setProgramSelected(false);
      }

      let programType = selectedProgram[0]?.currentProgram;
      let paidAmnt = selectedProgram[0]?.totalReceipted;
      let campusId = selectedProgram[0]?.campusId;

      // changeCourseGet(campusId);

      // Getting the Document History each time user selects a program
      getDocumentHistory(selectedProgram[0]?.enrolmentId);

      if (programType === 1) {
        // comment - conditionally rendering Apply for refund
        if (selectedProgram[0]?.totalReceipted <= 0) {
          setStudentRequests(
            currentRequests.filter(
              (request) => request.value !== 2
            )
          );
        } else {
          setStudentRequests(currentRequests);
        }
      } else if (programType === 0) {
        setStudentRequests(pastRequests);
      }

      rData.enrolmentId = value ? parseInt(value) : "";
      rData.startDate = "";
      rData.endDate = "";
      rData.courseId = "";
      rData.notes = "";
      rData.requestValue = "";
      rData.amount = "";
      setRequestData(rData);
      setPaidAmount(paidAmnt);
      setCampus(parseInt(campusId));

      setNotesBox("hidden");
      setBtnHidden("hidden");
      setAmountBox("hidden");
      setDateBoxes("hidden");
      setNewPHidden("hidden");
      setShowDocs(false);

      setHistory([])

      setRequestSelected("");
      setUploadDocs([]);

      // setMinDate(moment());
    } else if (controlName === "Request") {
      setShowDocs(true);    

        setRequestSelected(request.codeName);
        setUploadDocs([]);

        setDocs(request.documents);

        // Filtering the Document History data
        // console.log(request)
        // let filteredRequests = history.filter((item) => item.requestType === request.value);
        let filteredRequests = history.filter((item) => item.requestType === request.userCodeId);
        setTempHistory(filteredRequests)
        
      checkForPendingRequests(filteredRequests)
      

      rData.startDate = "";
      rData.endDate = "";
      rData.courseId = "";
      rData.notes = "";
      rData.amount = "";

      rData.requestValue = request.userCodeId;
      // console.log(rData)
      setRequestData(rData);

      // if (value === 4) {
      //   console.log(request.userCodeId)
      //   rData.requestValue = request.userCodeId;
      //   setRequestData(rData);
      //   // setBtnHidden("hidden");
      //   // setNotesBox("hidden");
      //   // setAmountBox("hidden");
      //   // setDateBoxes("hidden");
      //   // setNewPHidden("hidden");
      // } else if (value === 2) {
      //   rData.requestValue = request.userCodeId;

      //   setRequestData(rData);
      //   // setNotesBox("hidden");
      //   // setBtnHidden("hidden");
      //   // setAmountBox("hidden");
      //   // setDateBoxes("hidden");
      //   // setNewPHidden("hidden");
      // } else if (value === 3) {
      //   rData.requestValue = value;

      //   setRequestData(rData);
      //   // setNotesBox("hidden");
      //   // setBtnHidden("hidden");
      //   // setDateBoxes("hidden");
      //   // setAmountBox("hidden");
      //   // setNewPHidden("hidden");
      // } else if (value === 1) {
      //   rData.requestValue = value;

      //   setRequestData(rData);
      //   // setNotesBox("hidden");
      //   // setBtnHidden("hidden");
      //   // setAmountBox("hidden");
      //   // setDateBoxes("hidden");
      //   // setNewPHidden("hidden");
      // } else if (value === 5) {
      //   rData.requestValue = value;

      //   setRequestData(rData);
      //   // setNotesBox("hidden");
      //   // setBtnHidden("hidden");
      //   // setAmountBox("hidden");
      //   // setDateBoxes("hidden");
      //   // setNewPHidden("hidden");
      // } else {
      //   // rData.requestValue = value;

      //   // setRequestData(rData);
      //   // setNotesBox("hidden");
      //   // setBtnHidden("hidden");
      //   // setAmountBox("hidden");
      //   // setDateBoxes("hidden");
      //   // setNewPHidden("hidden");
      // }
    }
    // else if (controlName === "NewProgram") {
    //   rData.startDate = "";
    //   rData.endDate = "";
    //   rData.courseId = value;
    //   setDateBoxes(setState({ setRequestData( rData });
    // }
    // else if (controlName === "StartDate") {
    //   rData.startDate = value;
    //   rData.endDate = "";
    //   rData.courseID = "";
    //   errorD.startDate = "";
    //   setRequestData(rData);
    //   setErrors(errorD);
    // } else if (controlName === "EndDate") {
    //   rData.endDate = value;
    //   rData.courseID = "";
    //   errorD.endDate = "";
    //   setRequestData(rData);
    //   setErrors(errorD);
    // } else if (controlName === "refundAmount") {
    //   let paidA = parseInt(paidAmount);
    //   let newA = parseInt(value);
    //   if (newA > paidA) {
    //     //alert("Refund Amount cannot exceed Paid Amount");
    //     playSound(errorAudio);

    //     setAlertMessage("Refund Amount cannot exceed Paid Amount");
    //     setAlertState(true);
    //     setAlertIcon(ERRORICON);
    //     setIconColor("red");
    //   } else {
    //     rData.amount = value;
    //     if (newA !== 0 && newA) {
    //       errorD.amount = "";
    //     }
    //     setRequestData(rData);
    //     setErrors(errorD);
    //   }
    // } 
    else if (controlName === "notes") {
      errorD.notes = "";
      rData.notes = value;
      setRequestData(rData);
      setErrors(errorD);
    }
  };
  // folloing function will close alert on pressing X button
  const closeAlert = () => {
    setAlertState(false);
  };
  useEffect(() => {
    if (alertState && iconColor === "green") {
      setRefresh(!refresh);
    }
  }, [alertState && iconColor]);
  // following function will perform post https request
  const requestsDataSavePost = async (pData) => {

    let isFileValid = false;

    isFileValid = uploadedDocs.some((file) => file.type !== "application/pdf");

    if(isFileValid){
      playSound(errorAudio)
      return toast.error('Only pdf format files are acceptable');       
    }

    // Checking for duplicate files
    let docNameArr = [];

    // pushing the name of the files in docNameArr
    uploadedDocs.forEach((doc) => docNameArr.push(doc.name));

    // creating unique set of values for name
    let uniqueSet = new Set(docNameArr);

    // comparing the length of both
    if(docNameArr.length !== uniqueSet.size){
      playSound(errorAudio)
      return toast.error('You cannot upload duplicate documents');
    }

    setLoading(true);
    // const hide = toast.loading('Wait submitting request...', 0)

    
    // setRefresh(true);

    const toastId = "submitrequesttoast";

    try {

      toast("Submitting Request...", {
        toastId,
        type: "info",
        autoClose: false
      });

      const submitRequest = await Axios.post(constants.BASEURL + "StudentRequests", pData);
      
      if(submitRequest.data.toString().startsWith("An error")){
        throw "Unbale to submit request"
      }

      // console.log(uploadedDocs)

      let formData = new FormData();
      uploadedDocs.forEach((doc) => {
        // changed file.originFileObj to just file because now we not using ant-design upload, ant design provide us the file in .originFileObj but now we are directly getting it from the file obj
        formData.append("file", doc)
      });

      formData.append("objectId", pData.enrolmentId);
      formData.append("studentRequestId", submitRequest.data);

      // console.log({
      //   objectID: pData.enrolmentId,
      //   requestID: submitRequest.data,
      //   files: formData
      // })
      // debugger
      const submitDocs = await Axios.post(constants.BASEURL + "StudentRequests/SaveRequestForms", formData);     

      toast.update(toastId, {
        render: 'Request Sent Successfully',
        type: "success",
        autoClose: 3000
      });

      playSound(submitAudio)

    } catch (error) {
      playSound(errorAudio)
      toast.update(toastId, {
        render: 'An error occured whilie submitting request',
        type: "error",
        autoClose: 3000
      });
      console.log(error)
    } finally {
      // hide()
      setRefresh(!refresh)
      setUploadDocs([])
      setLoading(false);
    }

    // Axios.post(constants.BASEURL + "StudentRequests", pData)
    //   .then(async (result) => {
    //     //alert("Your Request is successfully saved");
    //     playSound(submitAudio);
    //     setAlertMessage("Your Request is successfully saved");
    //     setAlertState(true);
    //     setAlertIcon(SUCCESSICON);
    //     setIconColor("green");
    //   })
    //   .catch((ex) => {
    //     //alert("An Error occured while saving webform");
    //     playSound(errorAudio);

    //     setAlertMessage("An Error occured while saving webform");
    //     setAlertState(true);
    //     setAlertIcon(ERRORICON);
    //     setIconColor("red");
    //   });
  };
  // Following function will be called on submit button press
  const submitClicked = () => {

    console.log(requestData)

    let requestD = requestData;
    // debugger;
    let errorD = errors;
    let postData = {
      enrolmentId:
        requestD.enrolmentId == "" ? null : parseInt(requestD.enrolmentId),
      amount: requestD.amount == "" ? null : parseInt(requestD.amount),
      startDate: requestD.startDate == "" ? null : requestD.startDate,
      endDate: requestD.endDate == "" ? null : requestD.endDate,
      courseId: requestD.courseId == "" ? null : parseInt(requestD.courseId),
      notes: requestD.notes == "" ? null : requestD.notes,

      // need to send usercodeID in the request Type
      requestType:
        requestD.requestValue == "" ? null : parseInt(requestD.requestValue),
    };

    // console.log("POSTDATA",postData)

    // if (requestD.requestValue === "" || postData.EnrolmentId === "") {
    //   //alert("Please Select both fields");
    //   playSound(errorAudio);

    //   setAlertMessage("Please Select both fields");
    //   setAlertState(true);
    //   setAlertIcon(ERRORICON);
    //   setIconColor("red");
    // } else if (requestD.requestValue === 5 && requestD.courseId === "") {
    //   // alert("Please Select a New Program");

    //   errorD.newCourse = "error";
    //   playSound(errorAudio);

    //   setAlertMessage("Please Select a New Program");
    //   setAlertState(true);
    //   setAlertIcon(ERRORICON);
    //   setIconColor("red");
    //   setErrors(errorD);
    // } else if (
    //   requestD.requestValue === 2 &&
    //   (parseInt(requestD.amount) === 0 || requestD.amount === "")
    // ) {
    //   //alert("Refund amount cannot be 0 or empty");
    //   errorD.amount = "error";
    //   playSound(errorAudio);

    //   setAlertMessage("Refund amount cannot be 0, empty or invalid");
    //   setAlertState(true);
    //   setAlertIcon(ERRORICON);
    //   setIconColor("red");
    //   setErrors(errorD);
    // } else if (
    //   requestD.requestValue === 3 &&
    //   (requestD.startDate === "" || requestD.endDate === "")
    // ) {
    //   //alert("Please select Start and End Date");

    //   if (requestD.startDate === "") {
    //     errorD.startDate = "error";
    //   }
    //   if (requestD.endDate === "") {
    //     errorD.endDate = "error";
    //   }
    //   playSound(errorAudio);

    //   setAlertMessage("Please select Start and End Date");
    //   setAlertState(true);
    //   setAlertIcon(ERRORICON);
    //   setIconColor("red");
    //   setErrors(errorD);
    // } else if (requestD.requestValue !== 4 && requestD.notes === "") {
    //   //alert("Please add some Notes");
    //   errorD.notes = "error";
    //   playSound(errorAudio);

    //   setErrors(errorD);
    //   setAlertMessage(
    //     `Please add some ${
    //       dateBoxes && !notesBox && newPHidden && !amountBox
    //         ? "Notes"
    //         : "Reason"
    //     }`
    //   );
    //   setAlertState(true);
    //   setAlertIcon(ERRORICON);
    //   setIconColor("red");
    // } else {
    // }

    // console.log("postdata", postData)
    requestsDataSavePost(postData);
  };
  // Following section will render the view
  const resetData = () => {
    setProgramSelected(false);
    setRequestSelected("");

    setStudentCourses([]);
    setStudentRequests([]);
    setCurrentRequests([]);
    setPastRequests([]);
    setChangeCoursesData([]);
    setCampus("");

    setShowDocs(false);
    setHistory([]);    
    setTempHistory([]);
    

    setNotesBox("hidden");
    setBtnHidden("hidden");
    setAmountBox("hidden");
    setDateBoxes("hidden");
    setNewPHidden("hidden");
    setPaidAmount(0);
    setRequestData({
      enrolmentId: "",
      requestValue: "",
      notes: "",
      courseId: "",
      amount: "",
      startDate: "",
      endDate: "",
    });
    setErrors({
      amount: "",
      startDate: "",
      endDate: "",
      newCourse: "",
      notes: "",
    });
  };
  useEffect(() => {
    resetData();
    effect();
    async function effect() {
      studentProgramsGet();
      allRequestsGet();
    }
  }, [refresh]);

  return (
    <>
    
    {/* {contextHolder} */}

    <div className="px-[2vw] mt-10">
      {/* <div style={{ position: "relative", zIndex: 40 }}>
        <Alert
          alertStatus={alertState}
          closeFunction={closeAlert}
          alertMessage={alertMessage}
          alertIcon={alertIcon}
          iconColor={iconColor}
        />
      </div> */}
      <div>
        <form>
          <h3 className="text-lg font-semibold">Make a Request</h3>
          <div className="mt-10">
            <SelectBox
              dependentOn={true}
              key={"Program"}
              id={"Program"}
              name={"Program"}
              disabled={programDisable}
              label="Select a Program"
              data={studentCourses}
              onChange={handleChange}
              multiple={0}
              idField={"enrolmentId"}
              valueField="courseName"
              value={requestData?.enrolmentId || "Please choose one"}
              fullwidth={true}
            />
          </div>
          <div className="mt-6">
            {programSelected && (
              <div>
                <FormLabel text={"Select a Request"} color={"black"}>
                  <div
                    className={"flex items-center gap-4 justify-between flex-wrap"}
                  >
                    {studentRequests &&
                      studentRequests.map((request, idx) => (
                        <div
                          className={`px-4 py-2 rounded-full cursor-pointer ${
                            requestSelected === request.codeName
                              ? "bg-blue-600 text-white"
                              : "bg-gray-300"
                          }`}
                          key={idx}
                          onClick={() =>
                            handleChange(request.value, "Request", request)
                          }
                        >
                          {request.codeName}
                        </div>
                      ))}
                  </div>
                </FormLabel>
              </div>
            )}
          </div>
          <div className="mt-3">
            <div
              style={{
                border: "none",
                marginTop: "1.5rem",
                display: newPHidden === "hidden" ? "none" : "inherit",
              }}
            >
              <div
                style={{
                  border: "none",
                }}
              >
                <AutoComplete
                  name={"NewProgram"}
                  error={errors?.newCourse}
                  endPoint={"Campus/Programs?campusId=" + campus}
                  dependentOn={"no"}
                  label={"Enter a New Program"}
                  // value={this.state.requestData.courseId}
                  onChange={handleAutoControl}
                />
              </div>
            </div>

            <div
              className={
                "border-hidden mt-6 sm:mt-5 space-y-6 sm:space-y-5 " + amountBox
              }
            >
              {paidAmount > 0 && (
                <div className="mt-4">
                  <div>
                    <TextBox
                      key={"rAmount"}
                      id={"rAmount"}
                      label="Enter Refund Amount in $"
                      readOnly={false}
                      error={errors?.amount}
                      value={requestData?.amount}
                      name="refundAmount"
                      type="number"
                      dependentOn={"no"}
                      onChange={handleChange}
                      fullwidth={false}
                    />
                    <p className="mt-2 text-sm text-gray-800">
                      {"You have paid $" + paidAmount + " in total."}
                    </p>
                  </div>
                </div>
              )}
              {paidAmount === 0 && (
                <p>{"You have paid $" + paidAmount + " in total."}</p>
              )}
            </div>

            <div className={`${dateBoxes}`}>
              <div>
                <DateBox
                  dependentOn={true}
                  key={"StartDate"}
                  id={"StartDate"}
                  label="Start Date"
                  error={errors?.startDate}
                  value={requestData?.startDate}
                  name={"StartDate"}
                  onChange={handleChange}
                  minDate={moment().add(0, "years").toDate()}
                />

                <DateBox
                  dependentOn={true}
                  key={"EndDate"}
                  id={"EndDate"}
                  label="End Date"
                  error={errors?.endDate}
                  value={requestData?.endDate}
                  name={"EndDate"}
                  onChange={handleChange}
                  minDate={moment().add(0, "years").toDate()}
                />
              </div>
            </div>

            

            {/* For Docs */}
            <div className={showDocs ? "" : "hidden"}>
              <section>
                <p className="font-semibold mb-2">Available Request Forms</p>
                <div className="border rounded-lg px-4 py-2 mb-4">
                  {docs?.length > 0 ? (
                    docs.map((doc) => (
                      <div key={doc.documentID} className="flex items-center justify-between text-sm mb-1.5">
                        <p>{doc.docName}</p>
                        <a href={doc.downloadLink} target="_blank"><LuDownload className="text-blue-600 w-4 h-4" /></a>
                      </div>
                    ))
                  ) : (
                    <p>No Documents Found</p>
                  )}
                </div>

                <div className={docs.length === 0 || pendingRequestFound === true ? "hidden" : ""}>
                  <p className="text-sm -mt-2 text-orange-600">*Please upload files in pdf format</p>
                  <Uploader
                    label={isUploadedFileValidError ? "test" : null}
                    error={isUploadedFileValidError}
                    onUpload={(files) => setUploadDocs(files)}
                    files={uploadedDocs}
                  />
                </div>

                <div className={`mt-2 ${docs.length === 0 || pendingRequestFound === true ? "hidden" : ""}`}
                  // style={{
                  //   marginTop: "1.5rem",
                  //   border: "none", // Replace with appropriate border styles
                  //   display: "flex",
                  //   flexDirection: "column",
                  //   rowGap: "1.5rem",
                  //   display: docs.length === 0 || pendingRequestFound === true ? "none" : "block"
                  //   // ...(notesBox && {
                  //   //   display: "none",
                  //   //   // Apply additional styles based on the `notesBox` prop
                  //   // }),
                  // }}
                >
                  <div>
                    {/* <Form.Item label="Notes" style={{ display: "block", fontSize: "0.875rem", fontWeight: 500, lineHeight: "1.25rem", color: "#4a5568", marginTop: "1.5rem" }}
                      htmlFor="notes" /> */}
                    <p className="my-2 font-semibold">
                      {dateBoxes && !notesBox && newPHidden && !amountBox
                        ? "Notes"
                        : "Reason"}
                    </p>

                    <textarea className="w-full font-normal px-3 py-2.5 rounded-lg focus:outline-none text-black resize-none border focus:border-blue-400 text-sm" label="Notes" id="notes" name="notes" rows={3} onChange={handleChange} value={requestData?.notes || ""}/>
                    
                    <p className="text-sm text-gray-500">
                      {`Write a ${
                        dateBoxes && !notesBox && newPHidden && !amountBox
                          ? "notes"
                          : "reason"
                      } for your Request.`}
                    </p>
                  </div>
                </div>
              </section>
            </div>

            <div className={`mt-4 w-fit ${uploadedDocs.length === 0 ? "hidden" : ""}`}>
                <button
                  disabled={loading}
                  id="submit"
                  type="button"
                  onClick={() => {
                    submitClicked();
                  }}
                  className="px-4 py-1.5 font-medium text-white bg-blue-600 cursor-pointer shadow hover:bg-blue-500 rounded-md"
                >
                  Submit
                </button>
            </div> 

            <div className={history.length === 0 ? "hidden" : ""}>
              <p className="font-semibold my-4">Request History and Status</p>
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Request Type</th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Request Date</th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Request Status</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {tempHistory.map((item) => (
                      <tr key={item.studentRequestId} className="even:bg-gray-50">
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{item.requestTypeName}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm">{new Date(item.requestDate)?.toLocaleDateString("en-gb")}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm">
                          {item.requestStatus.toLowerCase() === "processed" ? (
                            <p className="bg-green-100 text-green-600 w-fit text-xs border-green-600 border px-2 py-0.5 rounded">Approved</p>
                          ) : (
                            item.requestStatus.toLowerCase() === "pending" ? (
                              <p className="bg-orange-50 text-orange-600 w-fit text-xs border-orange-600 border px-2 py-0.5 rounded">Pending</p>
                            ) : (
                              <p className="bg-red-50 text-red-500 w-fit text-xs border-red-500 border px-2 py-0.5 rounded">Rejected</p>
                            )
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </form>
      </div>
    </div>
    </>
  );
};

export default MakeARequest;
