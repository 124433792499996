
import React, { useEffect, useState } from 'react'

import axios from 'axios';
import Avatar from './common/Avatar';
import { BASEURL } from "../constants";


const UserProfile = () => {

    const studentName=localStorage.getItem("studentName");
    const [loading,setLoading] = useState(false)
    const [data, setData] = useState({});
    
    const getdata = async () => {
        setLoading(true);        
        try {
            const res = await axios.get(BASEURL + "Student/Address/" + localStorage.getItem("studentId"));
            setData(res.data[0])
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getdata();
    }, [])
    

    if(loading) {
        return <div style={{marginTop: "10rem", display: "grid", placeContent: "center"}}><p>Loading...</p></div>
    }


  return (
     <div className='grid grid-cols-1 gap-x-6 gap-y-3 md:grid-cols-4 sm:grid-cols-2 p-4 bg-gray-200'>
      
        
            
                <div className='flex justify-center h-[120px]'>
                    <Avatar className='rounded-full' url={`https://ui-avatars.com/api/name=${studentName}/?background=random`}/>
                </div>
            

            <div >
                <p><span className="font-bold">Name:</span> {data.firstName} {data.lastName}</p>
                <p><span className="font-bold">DOB:</span> {data.dob}</p>
                <p><span className="font-bold" >Email:</span> {data.email}</p>
                <p><span className="font-bold" >Mobile:</span> {data.mobile}</p>
            </div>

            <div >
                <p><span className="font-bold">Local Address:</span> {data.localAddressLine1}</p>
                <p><span className="font-bold">Local Suburb:</span> {data.localSuburb}</p>
                <p><span className="font-bold">Local Country:</span> {data.localCountry}</p>
                <p><span className="font-bold">Local State:</span> {data.localState}</p>
                <p> <span className="font-bold">Local Postal Code:</span> {data.localPostCode}</p>
            </div>

            <div >
                <p><span className="font-bold">Mailing Address:</span> {data.mailingAddressLine1}</p>
                <p><span className="font-bold">Mailing Suburb:</span> {data.mailingSuburb}</p>
                <p><span className="font-bold">Mailing Country:</span> {data.mailingCountry}</p>
                <p><span className="font-bold">Mailing State:</span> {data.mailingState}</p>
                <p> <span className="font-bold">Mailing Postal Code:</span> {data.mailingPostCode}</p>
            </div>
            
        
        </div>
        

  )
}

export default UserProfile
